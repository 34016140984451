import { onBeforeUnmount } from 'vue';

import { c2c } from '@/generated/proto/c2cagent';
import { apiClient } from '@/openapi-client';
import { useStore } from '@/store';

import { type ISrpcClient, SrpcProvider } from '../helpers/di.helpers';
import { logger } from './logger';
import { OpenReplay } from './openreplay';
import { SrpcClient } from './srpc';

export function setupSrpcClient(options?: { isManager?: boolean }): ISrpcClient {
    const store = useStore();

    const srpcClient = new SrpcClient({
        uri: import.meta.env.VITE_APP_SRPC_URI!,
        clientOutput: c2c.agentServer.CMessageStream,
        serverInput: c2c.agentServer.SMessageStream,
        debug: true
    });
    srpcClient.metadata = async () => {
        const { token } = await apiClient.identity.getIdentityGetSrpcToken({
            override: store.srpcOverride,
            manager: options?.isManager || undefined
        });

        if (token === null) {
            logger.info('sRPC token is null');
            store.srpcConflict = true;
            return null;
        }

        store.srpcConflict = false;
        store.srpcOverride = false;

        return {
            appVersion: import.meta.env.VITE_APP_VERSION,
            clientId: options?.isManager ? `manager${store.user!.id}` : `agent${store.user!.id}`,
            openReplaySid: String(OpenReplay.sessionId ?? ''),
            token
        };
    };
    srpcClient.addEventListener('connecting', () => (store.srpcStatus = 'connecting'));
    srpcClient.addEventListener('connected', () => (store.srpcStatus = 'connected'));
    srpcClient.addEventListener('disconnected', () => (store.srpcStatus = 'disconnected'));
    srpcClient.connect();

    srpcClient.registerMessageHandler('sUpdateCallDisplayRequest', 'sUpdateCallDisplayResponse', async data => {
        if (store.activeCall) {
            store.activeCall.rpc = data.callInfo ?? undefined;
            store.activeCall.hangupCause = data.hangupCause ?? undefined;
        } else {
            store.activeCall = {
                rpc: data.callInfo ?? undefined,
                hangupCause: data.hangupCause ?? undefined
            };
        }
        return {};
    });

    srpcClient.registerMessageHandler('sUpdateCampaignRequest', 'sUpdateCampaignResponse', async data => {
        store.activeCampaignId = data.campaignId ?? null;
        return {};
    });

    OpenReplay.tracker?.use(app => {
        app?.attachStartCallback(startInfo => {
            if (srpcClient.isConnected) {
                srpcClient.invoke('cUpdateOpenReplaySidRequest', 'cUpdateOpenReplaySidResponse', {
                    openReplaySid: startInfo.sessionID
                });
            }
        });
    });

    SrpcProvider.provide(srpcClient);
    onBeforeUnmount(() => srpcClient?.disconnect());

    return srpcClient;
}
