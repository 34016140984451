<template>
    <div id="manager">
        <div class="inner">
            <table>
                <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Connected</th>
                        <th>Campaign</th>
                        <th>Call</th>
                        <th v-if="enableAssistLinks"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="agent in agentsWithStatuses" :key="agent.id">
                        <td class="agent">{{ agent.name }}</td>
                        <td><i v-if="agent.status" class="fa fa-check" /> <template v-else>-</template></td>
                        <td>{{ agent.status?.campaignId ? getCampaignName(agent.status.campaignId) : '-' }}</td>
                        <td class="call">
                            <template v-if="agent.status?.callInfo">
                                {{ getCallDescription(agent.status.callInfo) }}
                            </template>
                            <template v-else-if="agent.status?.inQueue">In Queue</template>
                            <template v-else>-</template>
                        </td>
                        <td v-if="enableAssistLinks">
                            <a v-if="agent.status?.openReplaySid" :href="getOpenReplayLink(agent)" target="_blank"><i class="fa fa-binoculars" /></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { compact } from 'lodash';
import { computed, ref } from 'vue';

import { c2c } from '@/generated/proto/c2cagent';
import { MetaCache } from '@/shared/services/meta';
import { setupSrpcClient } from '@/shared/services/srpc.setup';
import { filters } from '@/vf.setup';

const srpc = setupSrpcClient({ isManager: true });

const agents = MetaCache.getRef('agents');
const campaigns = MetaCache.getRef('campaigns');
const agentStatuses = ref<c2c.agentServer.IAgentStatus[]>();
const enableAssistLinks = window.localStorage.getItem('c2c:enableOpenReplayAssistLinks') === 'true';

const agentsWithStatuses = computed(() => {
    return (
        agents.value?.map(agent => {
            const status = agentStatuses.value?.find(status => status.agentId === agent.id);
            return { ...agent, status };
        }) ?? []
    );
});

async function setupSrpcConnection() {
    const result = await srpc.invoke('cFetchAgentStatusRequest', 'cFetchAgentStatusResponse', {});
    agentStatuses.value = result.agents!;
}

function getCampaignName(campaignId: number) {
    return campaigns.value?.find(campaign => campaign.id === campaignId)?.number;
}

const CallSourceMap: Record<c2c.agentServer.CallSource, string | null> = {
    [c2c.agentServer.CallSource.unknown]: null,
    [c2c.agentServer.CallSource.outbound]: 'Manual',
    [c2c.agentServer.CallSource.inbound]: 'Inbound',
    [c2c.agentServer.CallSource.rescheduled]: 'Callback',
    [c2c.agentServer.CallSource.campaign]: 'Campaign'
};
function getCallDescription(call: c2c.agentServer.ICallInfo) {
    return compact([
        CallSourceMap[call.source!],
        filters.phone(call.remoteNumber!),
        call.fileNumber && `File #${filters.fileNumber(call.fileNumber)}`
    ]).join(' • ');
}

srpc.addEventListener('connected', setupSrpcConnection);
srpc.registerMessageHandler('sUpdateAgentStatusRequest', 'sUpdateAgentStatusResponse', async data => {
    if (!agentStatuses.value) return;
    const agentIdx = agentStatuses.value.findIndex(a => a.agentId === data.agentId);
    if (data.isConnected !== false) {
        if (agentIdx === -1) agentStatuses.value.push(data);
        else {
            agentStatuses.value[agentIdx] = {
                ...agentStatuses.value[agentIdx],
                ...(data.campaignId !== null ? (data.campaignId === 0 ? { campaignId: null, inQueue: false } : { campaignId: data.campaignId }) : {}),
                ...(data.inCall === true ? { callInfo: data.callInfo, inQueue: false } : {}),
                ...(data.inCall === false ? { callInfo: null } : {}),
                ...(data.inQueue === true ? { inQueue: true } : {}),
                ...(data.openReplaySid ? { openReplaySid: data.openReplaySid } : {})
            };
        }
    } else if (agentIdx > -1) {
        agentStatuses.value.splice(agentIdx, 1);
    }
    return {};
});

function getOpenReplayLink(agent: (typeof agentsWithStatuses.value)[number]) {
    if (!enableAssistLinks || !agent.status?.openReplaySid) return '';
    return `https://openreplay.s24.io/6/assist/${agent.status.openReplaySid}`;
}

document.title = 'C2C Manager';
</script>

<style lang="scss" scoped>
#manager {
    @apply w-full max-w-screen-lg flex h-full max-h-[95%] bg-white rounded overflow-hidden;
}

.inner {
    @apply flex-1 overflow-y-auto;
}

td.agent,
td.call {
    @apply w-[35%];
}

a {
    @apply cursor-pointer px-2 text-gray-400 hover:text-gray-800;
}
</style>
