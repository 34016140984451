<template>
    <div id="campaign-selector">
        <VfSmartSelect
            v-model="selectedCampaignId"
            :options="campaigns"
            label="Campaign"
            placeholder="Select a campaign"
            null-title="Select a campaign"
            label-field="number"
            value-field="id"
            :disabled="!!store.activeCampaignId"
        />
        <div class="button-group">
            <button v-if="store.activeCampaignId" :disabled="!store.isSrpcConnected" @click="leaveCampaign">Leave</button>
            <button v-else v-disabled="!selectedCampaignId || !store.isSrpcConnected" @click="enterCampaign(selectedCampaignId!)">Join</button>
            <button @click="showCampaignOptions"><i class="fa fa-caret-down"></i></button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { showContextMenu, VfSmartSelect } from '@signal24/vue-foundation';
import { ref, watch } from 'vue';

import { SrpcProvider } from '@/shared/helpers/di.helpers';
import { useEnterCampaign, useLeaveCampaign } from '@/shared/services/campaign';
import { MetaCache } from '@/shared/services/meta';
import { useStore } from '@/store';

const store = useStore();

const campaigns = MetaCache.getRef('campaigns');
const selectedCampaignId = ref<number | null>(store.activeCampaignId);
const srpcClient = SrpcProvider.inject();

watch(
    () => store.activeCampaignId,
    activeCampaignId => {
        selectedCampaignId.value = activeCampaignId ?? selectedCampaignId.value;
    }
);

const enterCampaign = useEnterCampaign();
const leaveCampaign = useLeaveCampaign();

srpcClient.addEventListener('connected', () => {
    if (store.activeCampaignId) {
        enterCampaign(store.activeCampaignId);
    }
});

function showCampaignOptions(e: MouseEvent) {
    showContextMenu(e, {
        items: [
            {
                title: `${store.excludeCallbacks ? 'Enable' : 'Disable'} callbacks`,
                handler: () => (store.excludeCallbacks = !store.excludeCallbacks)
            },
            {
                title: `${store.excludeInbound ? 'Enable' : 'Disable'} inbound`,
                handler: () => (store.excludeInbound = !store.excludeInbound)
            }
        ]
    });
}
</script>

<style lang="scss" scoped>
#campaign-selector {
    @apply flex gap-1;
}

.button-group {
    @apply flex;

    button {
        @apply border-0;
    }

    button:first-child {
        @apply rounded-r-none;
    }

    button:last-child {
        @apply rounded-l-none;
    }
}
</style>
