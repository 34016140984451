<template>
    <div ref="el"></div>
</template>

<script setup lang="ts">
import { throttle } from 'lodash';
import { computed } from 'vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const el = ref<HTMLElement | null>(null);

const props = defineProps<{
    onTrip: () => void;
    throttleMs?: number;
}>();

const debouncedTrip = computed(() => throttle(props.onTrip, props.throttleMs ?? 500));

const intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
    if (el.value!.parentElement?.offsetHeight && el.value!.offsetTop < el.value!.parentElement.offsetHeight) return;
    if (entries[0].isIntersecting) debouncedTrip.value();
});

onMounted(() => {
    intersectionObserver.observe(el.value!);
});

onBeforeUnmount(() => {
    intersectionObserver.unobserve(el.value!);
});
</script>
