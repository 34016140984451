import type { IDebtorPaymentInfo, IDebtorScheduledPayment } from '@/generated/openapi-client';
import type { IDebtorPaymentUpdateCc } from '@/generated/openapi-client/models/IDebtorPaymentUpdateCc';
import type { IDebtorPaymentUpdateCheck } from '@/generated/openapi-client/models/IDebtorPaymentUpdateCheck';

export const getStoredPaymentDescription = (payment: IDebtorPaymentInfo | IDebtorScheduledPayment) => {
    if (payment.type === 'check') return `Checking *${payment.last4}`;
    if (payment.first1 === '4') return `Visa *${payment.last4}`;
    if (payment.first1 === '5') return `MasterCard *${payment.last4}`;
    if (payment.first1 === '3') return `Amex *${payment.last4}`;
    if (payment.first1 === '6') return `Discover *${payment.last4}`;
    return 'Unknown';
};

export const getPaymentUpdateDescription = (payment: IDebtorPaymentUpdateCc | IDebtorPaymentUpdateCheck) => {
    if (payment.type === 'check') return `Checking *${payment.accountNumber.slice(-4)}`;
    if (payment.accountNumber.startsWith('4')) return `Visa *${payment.accountNumber.slice(-4)}`;
    if (payment.accountNumber.startsWith('5')) return `MasterCard *${payment.accountNumber.slice(-4)}`;
    if (payment.accountNumber.startsWith('3')) return `Amex *${payment.accountNumber.slice(-4)}`;
    if (payment.accountNumber.startsWith('6')) return `Discover *${payment.accountNumber.slice(-4)}`;
    return 'Unknown';
};
