<template>
    <div id="phone-recent">
        <div v-for="call in calls" :key="call.id" class="call" @click="dial(call)">
            <div class="icon">
                <i class="fa" :class="[call.direction === 'inbound' ? 'fa-phone-arrow-down-left' : 'fa-phone-arrow-up-right']" />
            </div>
            <div class="info">
                <div v-if="call.remoteName" class="name">{{ call.remoteName }}</div>
                <div class="number">{{ $filters.phone(call.remoteNumber) }}</div>
                <div v-if="call.autoFileNumber">
                    <span class="fileNumber" @click.stop="openFile(call.autoFileNumber)">{{ $filters.fileNumber(call.autoFileNumber) }}</span>
                </div>
            </div>
            <div class="time">{{ formatTime(call.connectedAt ?? call.createdAt) }}</div>
        </div>

        <Loader v-if="isLoading" class="loader" />
        <div v-else-if="calls.length === 0 && !canLoadMore" class="none">No recent calls</div>

        <ScrollTrip :on-trip="loadMore" />
    </div>
</template>

<script lang="ts" setup>
import { format, startOfDay } from 'date-fns';
import { onMounted, ref } from 'vue';

import type { IRecentCallResponse } from '@/generated/openapi-client';
import { apiClient } from '@/openapi-client';
import Loader from '@/shared/components/loader.vue';
import ScrollTrip from '@/shared/components/scroll-trip.vue';
import { useStore } from '@/store';

const store = useStore();

const calls = ref<IRecentCallResponse[]>([]);
const isLoading = ref(false);
const canLoadMore = ref(true);

defineProps<{
    dial: (target: IRecentCallResponse) => void;
}>();

async function load(beforeId?: number) {
    if (isLoading.value) return;
    isLoading.value = true;
    const result = await apiClient.calls.getCallsGetRecentCalls({ beforeId });
    calls.value.push(...result);
    isLoading.value = false;
    canLoadMore.value = result.length > 0;
}

function loadMore() {
    if (isLoading.value || !canLoadMore.value) return;
    load(calls.value[calls.value.length - 1].id);
}

const startOfToday = startOfDay(new Date());
function formatTime(dtString: string) {
    const dt = new Date(dtString);
    if (dt < startOfToday) {
        return format(dt, 'M/d h:mm a');
    } else {
        return format(dt, 'h:mm a');
    }
}

function openFile(fileNumber: string) {
    store.activeDebtor = null;
    store.nextDebtorSearch = fileNumber;
}

onMounted(() => load());
</script>

<style lang="scss" scoped>
#phone-recent {
    @apply overflow-y-auto;
}

.loader {
    @apply text-center;
}

.none {
    @apply text-center text-gray-500 p-2;
}

.call {
    @apply flex p-2 gap-2 border-t cursor-pointer hover:bg-white;

    .info {
        @apply flex-1;
    }

    .fileNumber {
        @apply text-sm text-gray-400 hover:text-gray-800;
    }
}
</style>
