<template>
    <form id="debtor-overview-actions" ref="form" @submit.prevent="saveUpdate">
        <div class="cols">
            <div class="col">
                <div class="cols">
                    <div class="action-col">
                        <h6>Action:</h6>
                        <VfSmartSelect
                            v-model="update.action"
                            :options="reSortedActions"
                            label-field="label"
                            null-title="No action"
                            placeholder="Choose action"
                        />
                        <em v-if="wasInbLogged" class="inb">INB auto-logged</em>
                    </div>

                    <div>
                        <h6>Next Review:</h6>
                        <input
                            v-model="update.nextReviewDate"
                            v-tooltip="
                                calculatedNextReviewDate && update.nextReviewDate !== calculatedNextReviewDate
                                    ? `Calculated by action: ${calculatedNextReviewDate}`
                                    : false
                            "
                            type="date"
                            :disabled="!update.action"
                            :class="{
                                override: calculatedNextReviewDate && update.nextReviewDate !== calculatedNextReviewDate,
                                'blank-value': update.nextReviewDate === ''
                            }"
                            @change="isReviewManuallyChanged = true"
                        />
                    </div>

                    <div>
                        <h6>Reschedule For:</h6>
                        <input v-model="update.rescheduleFor" type="datetime-local" :class="{ 'blank-value': update.rescheduleFor === '' }" />
                    </div>
                </div>

                <h6>{{ update.rescheduleFor ? 'Rescheduling Notes' : 'Notes' }}:</h6>
                <textarea
                    v-model="update.notes"
                    v-tooltip="
                        calculatedNotes && update.notes !== calculatedNotes && !update.rescheduleFor ? `Action notes:\n${calculatedNotes}` : false
                    "
                    :disabled="!update.action && !update.rescheduleFor"
                    :class="{ override: calculatedNotes && update.notes !== calculatedNotes && !update.rescheduleFor }"
                    @change="isNotesManuallyChanged = true"
                ></textarea>
            </div>

            <div class="col right-col">
                <template v-if="update.action?.payment">
                    <template v-if="!debtor.paymentType">Payment not accepted for this client/debtor</template>
                    <template v-else>
                        <h6>Payment:</h6>
                        <div class="payment">
                            <em>Date:</em>
                            <input v-model="update.payment.date" type="date" />

                            <em>Method:</em>
                            <div class="method">
                                <VfSmartSelect v-model="selectedPaymentMethod" :options="paymentMethodOptions" :formatter="pm => pm.description" />
                                <i
                                    v-if="selectedPaymentMethod && !selectedPaymentMethod.update"
                                    v-tooltip="'Show name & address'"
                                    class="fa fa-info-circle"
                                    @click="showCardInfo"
                                ></i>
                            </div>

                            <template v-if="effectivePaymentMethod?.type === 'check'">
                                <em>Check #:</em>
                                <input v-model="update.payment.checkNumber" type="number" />
                            </template>

                            <em>Amount:</em>
                            <input v-model="update.payment.amount" type="number" step="0.01" min="0.00" :max="detail.totalDueAmount" />

                            <em>Cnvc Fee:</em>
                            <label>
                                <input v-model="update.payment.fee" type="checkbox" :disabled="effectiveConvenienceFee === 0" />
                                {{ $filters.usCurrency(effectiveConvenienceFee) }}
                            </label>
                        </div>
                    </template>
                </template>

                <div v-for="supplementalCode in supplementalCodes" :key="supplementalCode" class="supplemental-code">
                    <em>Apply {{ supplementalCode }}:</em>
                    <label>
                        <input v-model="update.additionalActions[supplementalCode]" type="checkbox" />
                    </label>
                </div>
            </div>
        </div>

        <div><button v-hotkey="'F11'" type="submit" :disabled="!canSaveUpdate">Save</button></div>
    </form>
</template>

<script lang="ts" setup>
import { handleErrorAndAlert, maskForm, presentOverlay, showAlert, VfSmartSelect } from '@signal24/vue-foundation';
import currency from 'currency.js';
import { addDays, format } from 'date-fns';
import { compact } from 'lodash';
import { computed, ref, watch } from 'vue';

import { type IActionCode, type IDebtorActionPayment, type IDebtorDetailExtended, type IDebtorInfo } from '@/generated/openapi-client';
import { c2c } from '@/generated/proto/c2cagent';
import { apiClient } from '@/openapi-client';
import { getPaymentUpdateDescription, getStoredPaymentDescription } from '@/shared/helpers/payment.helpers';
import { MetaCache } from '@/shared/services/meta';
import { useStore } from '@/store';

import MEditPayment from './m-edit-payment.vue';

const form = ref<HTMLFormElement>();

const store = useStore();
const props = defineProps<{
    debtor: IDebtorInfo;
    detail: IDebtorDetailExtended;
}>();

const emit = defineEmits<{
    reload: [];
}>();

const actions = MetaCache.getRef('actionCodes');
const wasInbLogged = computed(
    () => store.activeCall?.rpc?.source === c2c.agentServer.CallSource.inbound && store.activeCall?.rpc?.fileNumber === props.debtor.fileNumber
);

const reSortedActions = computed(() => {
    if (!actions.value) return actions.value;
    const actionDescriptors = actions.value
        .filter(a => !a.disabled)
        .map(a => ({
            ...a,
            label: `${a.code}: ${a.description}`
        }));
    const priorityActions = actionDescriptors.filter(a => a[props.debtor.isPhase1 ? 'ph1Priority' : 'ph2Priority']);
    const priortyActionCodes = priorityActions.map(a => a.code);
    return [...priorityActions, { code: '', label: '--------' }, ...actionDescriptors.filter(a => !priortyActionCodes.includes(a.code))];
});

interface IUpdate {
    action: IActionCode | null;
    nextReviewDate: string;
    rescheduleFor: string;
    notes: string;
    payment: Omit<IDebtorActionPayment, 'type'>;
    additionalActions: Record<string, boolean>;
}
const update = ref<IUpdate>(getInitialUpdate());

function getInitialUpdate(): IUpdate {
    return {
        action: null,
        nextReviewDate: props.detail.nextReviewDate ?? '',
        rescheduleFor: '',
        notes: '',
        payment: {
            date: format(new Date(), 'yyyy-MM-dd'),
            amount: props.debtor.balance,
            checkNumber: '',
            fee: true
        },
        additionalActions: {}
    };
}

const isReviewManuallyChanged = ref(false);
const calculatedNextReviewDate = computed(() => {
    if (!update.value.action) return null;
    if (!update.value.action.nextReviewDays) return null;
    if (update.value.action.nextReviewDays === 999) return null;
    if (update.value.action.nextReviewDays === 998) return props.detail.nextReviewDate;
    return format(addDays(new Date(), update.value.action.nextReviewDays), 'yyyy-MM-dd');
});
watch(calculatedNextReviewDate, value => {
    if (!isReviewManuallyChanged.value) {
        update.value.nextReviewDate = value ?? '';
    }
});

const isNotesManuallyChanged = ref(false);
const calculatedNotes = computed(() => {
    if (!update.value.action) return null;
    if (!update.value.action.note) return null;
    return update.value.action.note;
});
watch(calculatedNotes, value => {
    if (value && !isNotesManuallyChanged.value) {
        update.value.notes = value;
    }
});

interface IPaymentMethod {
    description: string;
    update?: NonNullable<IDebtorActionPayment['updatedMethod']>;
}
const storedPaymentMethodItem = computed(() => {
    if (!props.detail.paymentInfo) return null;
    return { description: getStoredPaymentDescription(props.detail.paymentInfo) };
});
const updatedPaymentMethodItem = computed(() => {
    if (!update.value.payment.updatedMethod) return null;
    return { description: getPaymentUpdateDescription(update.value.payment.updatedMethod), update: update.value.payment.updatedMethod };
});
const selectedPaymentMethod = ref<IPaymentMethod | null>(storedPaymentMethodItem.value);
const paymentMethodOptions = computed<IPaymentMethod[]>(() => {
    const options = compact([storedPaymentMethodItem.value, updatedPaymentMethodItem.value, { description: 'New payment method...' }]);
    return options;
});

watch(selectedPaymentMethod, (_, oldValue) => {
    if (oldValue?.description === 'New payment method...') {
        return;
    }
    if (selectedPaymentMethod.value?.description === 'New payment method...') {
        return showPaymentMethodSetupModal();
    }
    if (selectedPaymentMethod.value?.update) {
        update.value.payment.updatedMethod = updatedPaymentMethodItem.value?.update;
    } else {
        update.value.payment.updatedMethod = undefined;
    }
});

async function showPaymentMethodSetupModal() {
    const result = await presentOverlay(MEditPayment, {
        debtorInfo: props.debtor,
        debtorDetail: props.detail
    });
    if (result) {
        update.value.payment.updatedMethod = result;
    }
    selectedPaymentMethod.value = updatedPaymentMethodItem.value ?? storedPaymentMethodItem.value;
}

const effectivePaymentMethod = computed(() => update.value.payment.updatedMethod ?? props.detail.paymentInfo);
const effectiveConvenienceFee = computed(() =>
    effectivePaymentMethod.value?.type === 'check' ? props.debtor.checkConvenienceFee : props.debtor.cardConvenienceFee
);

const supplementalCodes = computed(() => Object.keys(update.value.action?.supplementalCodes ?? {}));
watch(
    () => update.value.action,
    () => {
        const updateCodes: { [code: string]: boolean } = {};
        for (const supplementalCode of supplementalCodes.value) {
            updateCodes[supplementalCode] =
                update.value.action?.supplementalCodes?.[supplementalCode] ?? update.value.additionalActions[supplementalCode] ?? false;
        }
        update.value.additionalActions = updateCodes;
    }
);

const canSaveUpdate = computed(() => {
    if (!update.value.action && !update.value.rescheduleFor) return false;
    if (update.value.action?.payment) {
        if (!update.value.payment.date) return false;
        if (!selectedPaymentMethod.value) return false;
        if (effectivePaymentMethod.value?.type === 'check' && !update.value.payment.checkNumber) return false;
    }
    return true;
});

function showCardInfo() {
    if (!selectedPaymentMethod.value) return;
    const cardInfo = selectedPaymentMethod.value.update ?? props.detail.paymentInfo;
    if (!cardInfo) return;
    const info = compact([cardInfo.name, cardInfo.address, `${cardInfo.city}, ${cardInfo.state} ${cardInfo.zip}`]);
    showAlert('Payment Contact Info', info.join('\n'));
}

async function saveUpdate() {
    const unmask = maskForm(form.value!);
    try {
        const result = await apiClient.debtors.postDebtorsPostAction({
            fileNumber: props.debtor.fileNumber,
            requestBody: {
                onPhone: !!store.activeCall,
                accessLogId: props.detail.accessLogId,
                actionCode: update.value.action?.code,
                nextReviewDate: update.value.nextReviewDate || null,
                rescheduleFor: update.value.rescheduleFor ? new Date(update.value.rescheduleFor).toISOString() : '',
                notes: update.value.notes,
                payment:
                    update.value.action?.payment && props.debtor.paymentType
                        ? {
                              ...update.value.payment,
                              type: props.debtor.paymentType,
                              fee: update.value.payment.fee && effectiveConvenienceFee.value > 0,
                              amount: currency(update.value.payment.amount).add(update.value.payment.fee ? effectiveConvenienceFee.value : 0).value
                          }
                        : undefined,
                additionalActions: Object.entries(update.value.additionalActions)
                    .filter(([, value]) => value)
                    .map(([key]) => key)
            }
        });
        store.debtorUpdatesMade = true;
        emit('reload');

        if (result.confirmationNumber) {
            showAlert('Payment Successful', `The payment confirmation number is:\n\n${result.confirmationNumber}`);
        }
    } catch (err) {
        handleErrorAndAlert(err);
    } finally {
        unmask();
    }
}
</script>

<style lang="scss" scoped>
#debtor-overview-actions {
    @apply mt-6 border-t pt-6 flex flex-col gap-2;
}

.cols {
    @apply flex gap-1;
}

h6 {
    @apply font-bold mb-1;

    &:not(:first-child) {
        @apply mt-4;
    }
}

textarea {
    @apply w-full resize-none;
}

@media screen and (max-width: 1024px) {
    .action-col {
        @apply basis-full;
    }
}

.right-col {
    @apply ml-6;
}

.blank-value {
    @apply text-gray-400;
}

.override {
    @apply border-yellow-500;
}

.payment {
    @apply grid grid-cols-4 items-center gap-1;
    grid-template-columns: min-content 1fr;

    label {
        @apply flex items-center gap-1;
    }

    input,
    div {
        @apply max-w-48;
    }

    .method {
        @apply flex items-center;

        i {
            @apply p-2 text-gray-400 cursor-pointer;
        }
    }
}

.right-col {
    em {
        @apply whitespace-nowrap pr-4 not-italic w-20;
    }
}

.supplemental-code {
    @apply flex items-center gap-1;
}

em {
    @apply ml-1 text-sm not-italic text-gray-400;
}

em.inb {
    @apply mt-1;
}
</style>
