import { handleErrorAndAlert, showAlert } from '@signal24/vue-foundation';
import { onBeforeUnmount, watch } from 'vue';

import { type ICall, useStore } from '@/store';

import { type ISrpcClient, SipProvider, SrpcProvider } from '../helpers/di.helpers';
import { SipClient } from './sip';

export function setupSipClient(srpcClient: ISrpcClient) {
    const store = useStore();

    const sipClient = new SipClient({
        debug: true,
        tokenGenerator: async () => {
            if (!srpcClient) throw new Error('sRPC client not initialized');
            const { token } = await srpcClient.invoke('cGenerateSipTokenRequest', 'cGenerateSipTokenResponse', {});
            return token!;
        },
        speakerDeviceId: store.sipSpeakerDeviceId,
        microphoneDeviceId: store.sipMicrophoneDeviceId
    });
    sipClient.addEventListener('connecting', () => (store.sipStatus = 'connecting'));
    sipClient.addEventListener('connected', () => (store.sipStatus = 'connected'));
    sipClient.addEventListener('disconnected', () => (store.sipStatus = 'disconnected'));

    watch(
        () => store.sipSpeakerDeviceId,
        deviceId => sipClient.setSpeakerDeviceId(deviceId)
    );
    watch(
        () => store.sipMicrophoneDeviceId,
        deviceId => sipClient.setMicrophoneDeviceId(deviceId)
    );

    SipProvider.provide(sipClient);
    onBeforeUnmount(() => sipClient.disconnect());
}

export function usePlaceCall() {
    const store = useStore();
    const srpc = SrpcProvider.inject();
    const sipClient = SipProvider.inject();

    return async (provisional: NonNullable<ICall['provisional']>) => {
        if (store.activeCall?.provisional || store.activeCall?.rpc?.callId) {
            if (!store.activeCall?.hangupCause) {
                return showAlert('You are already on a call.');
            }
        }

        if (!store.isSipConnected) {
            await sipClient.connect();
        }

        store.activeCall = { provisional };

        try {
            await srpc.invoke('cPlaceOutboundCallRequest', 'cPlaceOutboundCallResponse', {
                remoteNumber: provisional.remoteNumber,
                remoteName: provisional.remoteName,
                fileNumber: provisional.fileNumber,
                isRescheduled: provisional.isRescheduled === true
            });
        } catch (err) {
            handleErrorAndAlert(err);
            store.activeCall = null;
        }
    };
}
