<template>
    <div id="debtor-history">
        <Switcher
            v-model="filter"
            :options="{ all: 'All', trustEntry: 'Trust History', scheduledPayment: 'Scheduled Payments', journalEntry: 'Actions/Notes' }"
        />

        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>User</th>
                    <th>Description</th>
                    <th>Next Review</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, idx) in filteredItems" :key="idx" :class="item.type">
                    <template v-if="item.type === 'scheduledPayment'">
                        <td>{{ $filters.date(item.dt) }}</td>
                        <td></td>
                        <td>
                            <span v-tooltip="getAgentName(item.payment.userCode)">{{ item.payment.userCode }}</span>
                        </td>
                        <td>Scheduled Payment from {{ getStoredPaymentDescription(item.payment) }}</td>
                        <td></td>
                        <td></td>
                        <td>{{ $filters.usCurrency(item.payment.amount) }}</td>
                        <td></td>
                    </template>
                    <template v-else-if="item.type === 'trustEntry'">
                        <td>{{ $filters.date(item.dt) }}</td>
                        <td></td>
                        <td></td>
                        <td>{{ getTrustCodeDescription(item.entry.trustCode) }}</td>
                        <td></td>
                        <td></td>
                        <td>{{ $filters.usCurrency(item.entry.amount) }}</td>
                        <td>{{ $filters.usCurrency(item.entry.balance) }}</td>
                    </template>
                    <template v-else-if="item.type === 'journalEntry'">
                        <td>{{ $filters.date(item.dt) }}</td>
                        <td>{{ $filters.time(item.dt) }}</td>
                        <td>
                            <span v-tooltip="getAgentName(item.entry.userCode)">{{ item.entry.userCode }}</span>
                        </td>
                        <td class="wrap">
                            {{ getJournalEntryDescription(item.entry) ?? item.entry.note ?? '' }}
                            <p v-if="item.entry.note" class="note">{{ item.entry.note }}</p>
                        </td>
                        <td>{{ item.entry.nextReviewDate }}</td>
                        <td v-tooltip="item.entry.status ? getStatusCodeDescription(item.entry.status) : false">
                            {{ item.entry.status ?? '' }}
                        </td>
                        <td></td>
                        <td></td>
                    </template>
                </tr>
                <tr v-if="filteredItems.length === 0" class="none">
                    <td colspan="7">No items</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts" setup>
import { keyBy } from 'lodash';
import { computed, ref } from 'vue';

import type { IDebtorDetailExtended, IDebtorInfo, IDebtorJournalEntry } from '@/generated/openapi-client';
import Switcher from '@/shared/components/switcher.vue';
import { getStoredPaymentDescription } from '@/shared/helpers/payment.helpers';
import { MetaCache } from '@/shared/services/meta';

const props = defineProps<{
    debtor: IDebtorInfo;
    detail: IDebtorDetailExtended;
}>();

const filter = ref<'all' | 'trustEntry' | 'scheduledPayment' | 'journalEntry'>('all');

const agents = MetaCache.getRef('agents');
const agentsByCode = computed(() => agents.value && keyBy(agents.value, 'userCode'));

const actionCodes = MetaCache.getRef('actionCodes');
const actionCodesByCode = computed(() => actionCodes.value && keyBy(actionCodes.value, 'code'));

const statusCodes = MetaCache.getRef('statusCodes');
const statusCodesByCode = computed(() => statusCodes.value && keyBy(statusCodes.value, 'code'));

const trustCodes = MetaCache.getRef('trustCodes');
const trustCodesByCode = computed(() => trustCodes.value && keyBy(trustCodes.value, 'code'));

const items = computed(() =>
    [
        ...props.detail.scheduledPayment.map((p, i) => ({ type: 'scheduledPayment' as const, seq: i, dt: p.date, payment: p })),
        ...props.detail.trustEntries.map((t, i) => ({ type: 'trustEntry' as const, seq: i, dt: t.date, entry: t })),
        ...props.detail.journalEntries.map((e, i) => ({ type: 'journalEntry' as const, seq: i, dt: e.createdAt, entry: e }))
    ].sort((a, b) => {
        const timeCmp = b.dt.localeCompare(a.dt);
        if (timeCmp !== 0) return timeCmp;
        const typeCmp = a.type.localeCompare(b.type);
        if (typeCmp !== 0) return typeCmp;
        return b.seq - a.seq;
    })
);
const filteredItems = computed(() => (filter.value === 'all' ? items.value : items.value.filter(i => i.type === filter.value)));

const getAgentName = (userCode: string) => {
    return agentsByCode.value?.[userCode]?.name;
};

const getStatusCodeDescription = (statusCode: string) => {
    return statusCodesByCode.value?.[statusCode]?.description ?? null;
};

const getTrustCodeDescription = (trustCode: string) => {
    return trustCodesByCode.value?.[trustCode]?.description ?? trustCode;
};

const JournalEntryTypeDescriptions: Record<string, string> = {
    A: 'New Assignment',
    H: 'Desk Change',
    R: 'Desk Reassignment',
    B: 'Letter Sent',
    S: 'Status Change',
    D: 'Ltr/Ser Completed',
    I: 'Check/EFT/CC Requested',
    W: 'Letter',
    F: 'Demographic Change'
};
const getJournalEntryDescription = (entry: IDebtorJournalEntry) => {
    return entry.actionCode
        ? (actionCodesByCode.value?.[entry.actionCode]?.description ?? entry.actionCode)
        : JournalEntryTypeDescriptions[entry.type] || `Unknown of Type ${entry.type}`;
};
</script>

<style lang="scss" scoped>
#debtor-history {
    @apply p-2;
}

table {
    @apply mt-2;
}

th,
td {
    @apply whitespace-nowrap align-top text-sm;

    span {
        @apply text-sm;
    }
}

.none td {
    @apply text-center text-gray-400;
}

.wrap {
    // @apply whitespace-normal;
}

.note {
    @apply text-gray-500 whitespace-pre-wrap text-sm;
    word-break: break-word;
}

.journalEntry {
    @apply hover:bg-gray-50;
}

.scheduledPayment {
    @apply bg-blue-100 hover:bg-blue-50;
}

.trustEntry {
    @apply bg-green-100 hover:bg-green-50;
}
</style>
