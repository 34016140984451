<template>
    <div id="debtor-overview-info">
        <div class="col">
            <div class="row">
                <div class="short">
                    <div>
                        <label>Status:</label>
                        <span v-tooltip="getStatusDescription(props.detail.status)">{{ props.detail.status }}</span>
                    </div>
                    <div>
                        <label>Desk:</label>
                        {{ props.detail.desk }}
                    </div>
                </div>
                <div class="short">
                    <div>
                        <label>Phase:</label>
                        {{ debtor.isPhase1 ? '1' : '2' }}
                    </div>
                    <div>
                        <label>TZ:</label>
                        {{ timeZoneNames[props.detail.timeZone] ?? '-' }}
                    </div>
                </div>
            </div>

            <div class="row">
                <label>Dates:</label>
                <dl>
                    <dt>Assigned:</dt>
                    <dd>{{ detail.assignedDate }}</dd>
                    <dt>Last Charge:</dt>
                    <dd>{{ detail.lastChargeDate || '-' }}</dd>
                    <dt>Last Payment:</dt>
                    <dd>{{ detail.lastPaymentDate || '-' }}</dd>
                    <dt>Last Letter:</dt>
                    <dd v-tooltip="`Next Letter: ${detail.nextLetterDate ?? '-'}`">{{ detail.lastLetterDate || '-' }}</dd>
                    <dt>Last Worked:</dt>
                    <dd>
                        {{ detail.lastWorkedDate || '-' }} <span v-if="detail.numberOfDaysWorked"> ({{ detail.numberOfDaysWorked }}) </span>
                    </dd>
                    <dt>Next Review:</dt>
                    <dd>{{ detail.nextReviewDate || '-' }}</dd>
                    <dt>Credit Flag:</dt>
                    <dd v-tooltip="creditReportTip">
                        {{ creditReportFlag || '-' }}
                        <span v-if="!detail.lastCreditReportDate" class="not-reported">(NR)</span>
                    </dd>
                </dl>
            </div>
        </div>

        <div class="col">
            <div class="row">
                <div class="short">
                    <label>Client Reference:</label>
                    {{ detail.clientReference || '-' }}
                </div>

                <div class="short">
                    <label>Assigned Agent:</label>
                    -
                </div>
            </div>

            <div class="row">
                <label>Financials:</label>
                <dl>
                    <dt>Assigned:</dt>
                    <dd>{{ $filters.usCurrency(detail.assignedAmount) }}</dd>
                    <dt>Received:</dt>
                    <dd>
                        {{ $filters.usCurrency(detail.receivedAmount) }}
                        <template v-if="pendingPayment"> ({{ $filters.usCurrency(pendingPayment.amount) }} pending) </template>
                    </dd>
                    <dt>Principal:</dt>
                    <dd>{{ $filters.usCurrency(detail.principalDueAmount) }}</dd>
                    <template v-if="debtor.balance">
                        <dt>Interest:</dt>
                        <dd>{{ $filters.usCurrency(detail.interestDueAmount) }} ({{ detail.interestPercent }}%)</dd>
                    </template>
                    <dt>Total Due:</dt>
                    <dd>{{ $filters.usCurrency(debtor.balance) /* not totalDue, as balance takes recall/PIF into consideration */ }}</dd>
                    <dt>Pending:</dt>
                    <dd>
                        <template v-if="pendingPayment && scheduledPaymentAmount">
                            {{ $filters.usCurrency(pendingPayment.amount) }} paid, {{ $filters.usCurrency(scheduledPaymentAmount) }} scheduled
                        </template>
                        <template v-else-if="pendingPayment"> {{ $filters.usCurrency(pendingPayment.amount) }} paid </template>
                        <template v-else-if="scheduledPaymentAmount"> {{ $filters.usCurrency(scheduledPaymentAmount) }} scheduled </template>
                        <template v-else>-</template>
                    </dd>
                </dl>
            </div>
        </div>

        <div class="col">
            <div v-if="nextRSEvent" class="row rescheduled" :class="{ past: isNextRSPast }">
                <label>Rescheduled:</label>
                <p>{{ $filters.dateTime(nextRSEvent.for) }}<br />by {{ nextRSEvent.userCode }}</p>
                <p v-if="nextRSEvent.note" class="note">{{ nextRSEvent.note }}</p>
                <p v-if="detail.rescheduleEvents.length > 1" class="more">+ {{ detail.rescheduleEvents.length - 1 }} more</p>

                <button @click="clearRS(nextRSEvent)">Clear RS</button>
            </div>

            <div v-if="detail.debtorMemo" class="row debtor-memo">
                <label>Debtor Memo:</label>
                {{ detail.debtorMemo }}
            </div>

            <div class="address">
                <label
                    >Address<span v-if="detail.addresses.length > 1"> (1 of {{ detail.addresses.length }})</span>:</label
                >
                <p>{{ firstAddress.street1 }}</p>
                <p v-if="firstAddress.street2">{{ firstAddress.street2 }}</p>
                <p>{{ firstAddress.city }}, {{ firstAddress.state }} {{ firstAddress.zip }}</p>
                <div class="phones">
                    <p v-if="firstAddress.phone1 && firstAddress.phone1 !== '0000000000'">
                        Ph1
                        <a href="#" @click.prevent.stop="dial(firstAddress.phone1)"
                            >{{ $filters.phone(firstAddress.phone1) }} {{ firstAddress.phone1Ext ? `ext ${firstAddress.phone1Ext}` : '' }}</a
                        >
                    </p>
                    <p v-if="firstAddress.phone2 && firstAddress.phone2 !== '0000000000'">
                        Ph2
                        <a href="#" @click.prevent.stop="dial(firstAddress.phone2)"
                            >{{ $filters.phone(firstAddress.phone2) }} {{ firstAddress.phone2Ext ? `ext ${firstAddress.phone2Ext}` : '' }}</a
                        >
                    </p>
                </div>
            </div>

            <div v-if="detail.misc1 || detail.miscCode1 || detail.miscDate1 || detail.misc2 || detail.misc3" class="row">
                <label>Miscellaneous:</label>
                <table class="misc">
                    <tbody>
                        <tr>
                            <td><label>Misc1:</label> {{ detail.misc1 || '-' }}</td>
                            <td><label>Code1:</label> {{ detail.miscCode1 || '-' }}</td>
                            <td><label>Date1:</label> {{ detail.miscDate1 || '-' }}</td>
                        </tr>
                        <tr>
                            <td><label>Misc2:</label> {{ detail.misc2 || '-' }}</td>
                            <td><label>Code2:</label> {{ detail.miscCode2 || '-' }}</td>
                            <td><label>Date2:</label> {{ detail.miscDate2 || '-' }}</td>
                        </tr>
                        <tr>
                            <td><label>Misc3:</label> {{ detail.misc3 || '-' }}</td>
                            <td><label>Code3:</label> {{ detail.miscCode3 || '-' }}</td>
                            <td><label>Date3:</label> {{ detail.miscDate3 || '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { type IDebtorDetailExtended, type IDebtorInfo, type IDebtorRescheduleEvent } from '@/generated/openapi-client';
import { apiClient } from '@/openapi-client';
import { useGetStatusDescription } from '@/shared/helpers/meta.helpers';
import { withWait } from '@/shared/helpers/request.helpers';
import { usePlaceCall } from '@/shared/services/sip.setup';
import { useStore } from '@/store';

const props = defineProps<{
    debtor: IDebtorInfo;
    detail: IDebtorDetailExtended;
}>();

const store = useStore();
const getStatusDescription = useGetStatusDescription();

const timeZoneNames: Record<string, string> = {
    1: 'Eastern',
    2: 'Central',
    3: 'Mountain',
    4: 'Pacific',
    5: 'Alaska',
    6: 'Hawaii'
};

const creditReportStatusCodes: Record<string, string> = {
    N: 'Do not report',
    Y: 'Standard report',
    C: 'Cancel',
    D: 'Dispute',
    B: 'Bankruptcy 7',
    E: 'Bankruptcy 11',
    F: 'Bankruptcy 12',
    T: 'Bankruptcy 13',
    S: 'Report as skip',
    R: 'No longer skip (Remove)'
};
const creditReportFlag = computed(
    () => (props.detail.creditReportStatus && creditReportStatusCodes[props.detail.creditReportStatus]) ?? props.detail.creditReportStatus
);
const creditReportTip = computed(() =>
    props.detail.lastCreditReportDate
        ? `First Reported: ${props.detail.firstCreditReportDate}\nLast Reported: ${props.detail.lastCreditReportDate}`
        : 'Not reported'
);

const pendingPayment = computed(() => props.detail.scheduledPayment.find(p => p.isToday));
const upcomingPayments = computed(() => props.detail.scheduledPayment.filter(p => !p.isToday));
const scheduledPaymentAmount = computed(() => upcomingPayments.value.reduce((acc, p) => acc + p.amount, 0));

const nextRSEvent = computed(() => props.detail.rescheduleEvents[0]);
const isNextRSPast = computed(() => nextRSEvent.value?.for < new Date().toISOString());

const firstAddress = computed(() => props.detail.addresses[0]);

function clearRS(event: IDebtorRescheduleEvent) {
    withWait('Clearing rescheduled event...', async () => {
        await apiClient.debtors.deleteDebtorsDeleteRescheduledEvent({
            agentCode: event.userCode,
            fileNumber: props.debtor.fileNumber,
            isoTime: event.for
        });
        // eslint-disable-next-line vue/no-mutating-props
        props.detail.rescheduleEvents.splice(props.detail.rescheduleEvents.indexOf(event), 1);
        store.debtorUpdatesMade = true;
    });
}

const placeCall = usePlaceCall();
function dial(number: string) {
    if (/^0+$/.test(number)) return;
    placeCall({
        remoteNumber: number,
        fileNumber: props.debtor.fileNumber,
        remoteName: props.debtor.debtorName,
        isRescheduled: !!store.activeCall?.rpc?.rescheduledTs && props.debtor.fileNumber === store.activeCall.rpc.fileNumber
    });
}
</script>

<style lang="scss" scoped>
#debtor-overview-info {
    @apply flex;
}

.col {
    @apply flex-1 flex flex-col gap-8;
}

label {
    @apply font-bold block;
}

.short {
    @apply flex gap-1;

    > div {
        @apply flex gap-1 min-w-24;

        &:not(:first-child) {
            @apply ml-4;
        }
    }

    &:not(:first-child) {
        @apply mt-1;
    }
}

dl {
    @apply grid grid-cols-2 gap-2;
    grid-template-columns: min-content auto;
}

dt {
    @apply whitespace-nowrap text-gray-500;
}

.rescheduled {
    @apply relative;

    .note {
        @apply text-sm;
    }

    .more {
        @apply text-sm opacity-60;
    }

    button {
        @apply absolute top-0 right-0 text-sm;
    }

    &.past {
        @apply text-orange-600;

        button {
            @apply bg-orange-600 hover:opacity-60;
        }
    }
}

.debtor-memo {
    @apply whitespace-pre-wrap;
}

.address {
    p,
    a {
        @apply text-sm;
    }

    a {
        @apply text-blue-700 hover:text-blue-400;
    }

    .phones {
        @apply flex gap-2;
    }
}

table.misc {
    td {
        @apply text-xs p-0 pb-2;
        vertical-align: top;
    }

    label {
        @apply text-gray-500 text-xs font-normal;
    }
}
</style>
