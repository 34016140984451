<template>
    <div id="popover-keypad">
        <Keypad @press="handleDigit" />
    </div>
</template>

<script lang="ts" setup>
import Keypad from '../shared/keypad.vue';

const props = defineProps<{
    onDigit: (digit: string) => void;
}>();

function handleDigit(digit: string) {
    props.onDigit(digit);
}
</script>

<style lang="scss" scoped>
#popover-keypad {
    @apply bg-gray-50 rounded-md p-2;
}
</style>
