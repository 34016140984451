/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const c2c = $root.c2c = (() => {

    /**
     * Namespace c2c.
     * @exports c2c
     * @namespace
     */
    const c2c = {};

    c2c.agentServer = (function() {

        /**
         * Namespace agentServer.
         * @memberof c2c
         * @namespace
         */
        const agentServer = {};

        agentServer.PingPong = (function() {

            /**
             * Properties of a PingPong.
             * @memberof c2c.agentServer
             * @interface IPingPong
             */

            /**
             * Constructs a new PingPong.
             * @memberof c2c.agentServer
             * @classdesc Represents a PingPong.
             * @implements IPingPong
             * @constructor
             * @param {c2c.agentServer.IPingPong=} [properties] Properties to set
             */
            function PingPong(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified PingPong message. Does not implicitly {@link c2c.agentServer.PingPong.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.PingPong
             * @static
             * @param {c2c.agentServer.IPingPong} message PingPong message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PingPong.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a PingPong message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.PingPong
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.PingPong} PingPong
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PingPong.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.PingPong();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a PingPong message.
             * @function verify
             * @memberof c2c.agentServer.PingPong
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PingPong.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for PingPong
             * @function getTypeUrl
             * @memberof c2c.agentServer.PingPong
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PingPong.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.PingPong";
            };

            return PingPong;
        })();

        agentServer.WriteByteStream = (function() {

            /**
             * Properties of a WriteByteStream.
             * @memberof c2c.agentServer
             * @interface IWriteByteStream
             * @property {Uint8Array|null} [chunk] WriteByteStream chunk
             */

            /**
             * Constructs a new WriteByteStream.
             * @memberof c2c.agentServer
             * @classdesc Represents a WriteByteStream.
             * @implements IWriteByteStream
             * @constructor
             * @param {c2c.agentServer.IWriteByteStream=} [properties] Properties to set
             */
            function WriteByteStream(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WriteByteStream chunk.
             * @member {Uint8Array} chunk
             * @memberof c2c.agentServer.WriteByteStream
             * @instance
             */
            WriteByteStream.prototype.chunk = $util.newBuffer([]);

            /**
             * Encodes the specified WriteByteStream message. Does not implicitly {@link c2c.agentServer.WriteByteStream.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.WriteByteStream
             * @static
             * @param {c2c.agentServer.IWriteByteStream} message WriteByteStream message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WriteByteStream.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chunk != null && Object.hasOwnProperty.call(message, "chunk"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.chunk);
                return writer;
            };

            /**
             * Decodes a WriteByteStream message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.WriteByteStream
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.WriteByteStream} WriteByteStream
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WriteByteStream.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.WriteByteStream();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chunk = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a WriteByteStream message.
             * @function verify
             * @memberof c2c.agentServer.WriteByteStream
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WriteByteStream.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chunk != null && message.hasOwnProperty("chunk"))
                    if (!(message.chunk && typeof message.chunk.length === "number" || $util.isString(message.chunk)))
                        return "chunk: buffer expected";
                return null;
            };

            /**
             * Gets the default type url for WriteByteStream
             * @function getTypeUrl
             * @memberof c2c.agentServer.WriteByteStream
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WriteByteStream.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.WriteByteStream";
            };

            return WriteByteStream;
        })();

        agentServer.FinishByteStream = (function() {

            /**
             * Properties of a FinishByteStream.
             * @memberof c2c.agentServer
             * @interface IFinishByteStream
             */

            /**
             * Constructs a new FinishByteStream.
             * @memberof c2c.agentServer
             * @classdesc Represents a FinishByteStream.
             * @implements IFinishByteStream
             * @constructor
             * @param {c2c.agentServer.IFinishByteStream=} [properties] Properties to set
             */
            function FinishByteStream(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified FinishByteStream message. Does not implicitly {@link c2c.agentServer.FinishByteStream.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.FinishByteStream
             * @static
             * @param {c2c.agentServer.IFinishByteStream} message FinishByteStream message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FinishByteStream.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a FinishByteStream message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.FinishByteStream
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.FinishByteStream} FinishByteStream
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FinishByteStream.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.FinishByteStream();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FinishByteStream message.
             * @function verify
             * @memberof c2c.agentServer.FinishByteStream
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FinishByteStream.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for FinishByteStream
             * @function getTypeUrl
             * @memberof c2c.agentServer.FinishByteStream
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FinishByteStream.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.FinishByteStream";
            };

            return FinishByteStream;
        })();

        agentServer.DestroyByteStream = (function() {

            /**
             * Properties of a DestroyByteStream.
             * @memberof c2c.agentServer
             * @interface IDestroyByteStream
             * @property {string|null} [error] DestroyByteStream error
             */

            /**
             * Constructs a new DestroyByteStream.
             * @memberof c2c.agentServer
             * @classdesc Represents a DestroyByteStream.
             * @implements IDestroyByteStream
             * @constructor
             * @param {c2c.agentServer.IDestroyByteStream=} [properties] Properties to set
             */
            function DestroyByteStream(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DestroyByteStream error.
             * @member {string|null|undefined} error
             * @memberof c2c.agentServer.DestroyByteStream
             * @instance
             */
            DestroyByteStream.prototype.error = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(DestroyByteStream.prototype, "_error", {
                get: $util.oneOfGetter($oneOfFields = ["error"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified DestroyByteStream message. Does not implicitly {@link c2c.agentServer.DestroyByteStream.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.DestroyByteStream
             * @static
             * @param {c2c.agentServer.IDestroyByteStream} message DestroyByteStream message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DestroyByteStream.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.error);
                return writer;
            };

            /**
             * Decodes a DestroyByteStream message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.DestroyByteStream
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.DestroyByteStream} DestroyByteStream
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DestroyByteStream.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.DestroyByteStream();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.error = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DestroyByteStream message.
             * @function verify
             * @memberof c2c.agentServer.DestroyByteStream
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DestroyByteStream.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.error != null && message.hasOwnProperty("error")) {
                    properties._error = 1;
                    if (!$util.isString(message.error))
                        return "error: string expected";
                }
                return null;
            };

            /**
             * Gets the default type url for DestroyByteStream
             * @function getTypeUrl
             * @memberof c2c.agentServer.DestroyByteStream
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DestroyByteStream.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.DestroyByteStream";
            };

            return DestroyByteStream;
        })();

        agentServer.ByteStreamOperation = (function() {

            /**
             * Properties of a ByteStreamOperation.
             * @memberof c2c.agentServer
             * @interface IByteStreamOperation
             * @property {number|null} [streamId] ByteStreamOperation streamId
             * @property {c2c.agentServer.IWriteByteStream|null} [write] ByteStreamOperation write
             * @property {c2c.agentServer.IFinishByteStream|null} [finish] ByteStreamOperation finish
             * @property {c2c.agentServer.IDestroyByteStream|null} [destroy] ByteStreamOperation destroy
             */

            /**
             * Constructs a new ByteStreamOperation.
             * @memberof c2c.agentServer
             * @classdesc Represents a ByteStreamOperation.
             * @implements IByteStreamOperation
             * @constructor
             * @param {c2c.agentServer.IByteStreamOperation=} [properties] Properties to set
             */
            function ByteStreamOperation(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ByteStreamOperation streamId.
             * @member {number} streamId
             * @memberof c2c.agentServer.ByteStreamOperation
             * @instance
             */
            ByteStreamOperation.prototype.streamId = 0;

            /**
             * ByteStreamOperation write.
             * @member {c2c.agentServer.IWriteByteStream|null|undefined} write
             * @memberof c2c.agentServer.ByteStreamOperation
             * @instance
             */
            ByteStreamOperation.prototype.write = null;

            /**
             * ByteStreamOperation finish.
             * @member {c2c.agentServer.IFinishByteStream|null|undefined} finish
             * @memberof c2c.agentServer.ByteStreamOperation
             * @instance
             */
            ByteStreamOperation.prototype.finish = null;

            /**
             * ByteStreamOperation destroy.
             * @member {c2c.agentServer.IDestroyByteStream|null|undefined} destroy
             * @memberof c2c.agentServer.ByteStreamOperation
             * @instance
             */
            ByteStreamOperation.prototype.destroy = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * ByteStreamOperation operation.
             * @member {"write"|"finish"|"destroy"|undefined} operation
             * @memberof c2c.agentServer.ByteStreamOperation
             * @instance
             */
            Object.defineProperty(ByteStreamOperation.prototype, "operation", {
                get: $util.oneOfGetter($oneOfFields = ["write", "finish", "destroy"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified ByteStreamOperation message. Does not implicitly {@link c2c.agentServer.ByteStreamOperation.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.ByteStreamOperation
             * @static
             * @param {c2c.agentServer.IByteStreamOperation} message ByteStreamOperation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ByteStreamOperation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.streamId != null && Object.hasOwnProperty.call(message, "streamId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.streamId);
                if (message.write != null && Object.hasOwnProperty.call(message, "write"))
                    $root.c2c.agentServer.WriteByteStream.encode(message.write, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.finish != null && Object.hasOwnProperty.call(message, "finish"))
                    $root.c2c.agentServer.FinishByteStream.encode(message.finish, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.destroy != null && Object.hasOwnProperty.call(message, "destroy"))
                    $root.c2c.agentServer.DestroyByteStream.encode(message.destroy, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ByteStreamOperation message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.ByteStreamOperation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.ByteStreamOperation} ByteStreamOperation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ByteStreamOperation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.ByteStreamOperation();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.streamId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.write = $root.c2c.agentServer.WriteByteStream.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.finish = $root.c2c.agentServer.FinishByteStream.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.destroy = $root.c2c.agentServer.DestroyByteStream.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ByteStreamOperation message.
             * @function verify
             * @memberof c2c.agentServer.ByteStreamOperation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ByteStreamOperation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.streamId != null && message.hasOwnProperty("streamId"))
                    if (!$util.isInteger(message.streamId))
                        return "streamId: integer expected";
                if (message.write != null && message.hasOwnProperty("write")) {
                    properties.operation = 1;
                    {
                        let error = $root.c2c.agentServer.WriteByteStream.verify(message.write);
                        if (error)
                            return "write." + error;
                    }
                }
                if (message.finish != null && message.hasOwnProperty("finish")) {
                    if (properties.operation === 1)
                        return "operation: multiple values";
                    properties.operation = 1;
                    {
                        let error = $root.c2c.agentServer.FinishByteStream.verify(message.finish);
                        if (error)
                            return "finish." + error;
                    }
                }
                if (message.destroy != null && message.hasOwnProperty("destroy")) {
                    if (properties.operation === 1)
                        return "operation: multiple values";
                    properties.operation = 1;
                    {
                        let error = $root.c2c.agentServer.DestroyByteStream.verify(message.destroy);
                        if (error)
                            return "destroy." + error;
                    }
                }
                return null;
            };

            /**
             * Gets the default type url for ByteStreamOperation
             * @function getTypeUrl
             * @memberof c2c.agentServer.ByteStreamOperation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ByteStreamOperation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.ByteStreamOperation";
            };

            return ByteStreamOperation;
        })();

        agentServer.TraceContext = (function() {

            /**
             * Properties of a TraceContext.
             * @memberof c2c.agentServer
             * @interface ITraceContext
             * @property {string|null} [traceId] TraceContext traceId
             * @property {string|null} [spanId] TraceContext spanId
             * @property {number|null} [traceFlags] TraceContext traceFlags
             */

            /**
             * Constructs a new TraceContext.
             * @memberof c2c.agentServer
             * @classdesc Represents a TraceContext.
             * @implements ITraceContext
             * @constructor
             * @param {c2c.agentServer.ITraceContext=} [properties] Properties to set
             */
            function TraceContext(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TraceContext traceId.
             * @member {string} traceId
             * @memberof c2c.agentServer.TraceContext
             * @instance
             */
            TraceContext.prototype.traceId = "";

            /**
             * TraceContext spanId.
             * @member {string} spanId
             * @memberof c2c.agentServer.TraceContext
             * @instance
             */
            TraceContext.prototype.spanId = "";

            /**
             * TraceContext traceFlags.
             * @member {number} traceFlags
             * @memberof c2c.agentServer.TraceContext
             * @instance
             */
            TraceContext.prototype.traceFlags = 0;

            /**
             * Encodes the specified TraceContext message. Does not implicitly {@link c2c.agentServer.TraceContext.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.TraceContext
             * @static
             * @param {c2c.agentServer.ITraceContext} message TraceContext message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TraceContext.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.traceId);
                if (message.spanId != null && Object.hasOwnProperty.call(message, "spanId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.spanId);
                if (message.traceFlags != null && Object.hasOwnProperty.call(message, "traceFlags"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.traceFlags);
                return writer;
            };

            /**
             * Decodes a TraceContext message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.TraceContext
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.TraceContext} TraceContext
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TraceContext.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.TraceContext();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.traceId = reader.string();
                            break;
                        }
                    case 2: {
                            message.spanId = reader.string();
                            break;
                        }
                    case 3: {
                            message.traceFlags = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a TraceContext message.
             * @function verify
             * @memberof c2c.agentServer.TraceContext
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TraceContext.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.traceId != null && message.hasOwnProperty("traceId"))
                    if (!$util.isString(message.traceId))
                        return "traceId: string expected";
                if (message.spanId != null && message.hasOwnProperty("spanId"))
                    if (!$util.isString(message.spanId))
                        return "spanId: string expected";
                if (message.traceFlags != null && message.hasOwnProperty("traceFlags"))
                    if (!$util.isInteger(message.traceFlags))
                        return "traceFlags: integer expected";
                return null;
            };

            /**
             * Gets the default type url for TraceContext
             * @function getTypeUrl
             * @memberof c2c.agentServer.TraceContext
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TraceContext.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.TraceContext";
            };

            return TraceContext;
        })();

        /**
         * CallSource enum.
         * @name c2c.agentServer.CallSource
         * @enum {number}
         * @property {number} unknown=0 unknown value
         * @property {number} outbound=1 outbound value
         * @property {number} inbound=2 inbound value
         * @property {number} rescheduled=3 rescheduled value
         * @property {number} campaign=4 campaign value
         */
        agentServer.CallSource = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknown"] = 0;
            values[valuesById[1] = "outbound"] = 1;
            values[valuesById[2] = "inbound"] = 2;
            values[valuesById[3] = "rescheduled"] = 3;
            values[valuesById[4] = "campaign"] = 4;
            return values;
        })();

        agentServer.CallInfo = (function() {

            /**
             * Properties of a CallInfo.
             * @memberof c2c.agentServer
             * @interface ICallInfo
             * @property {number|null} [callId] CallInfo callId
             * @property {number|null} [startTime] CallInfo startTime
             * @property {string|null} [remoteNumber] CallInfo remoteNumber
             * @property {string|null} [remoteName] CallInfo remoteName
             * @property {c2c.agentServer.CallSource|null} [source] CallInfo source
             * @property {string|null} [fileNumber] CallInfo fileNumber
             * @property {number|null} [rescheduledTs] CallInfo rescheduledTs
             * @property {number|null} [transferById] CallInfo transferById
             * @property {string|null} [transferNote] CallInfo transferNote
             * @property {boolean|null} [canLeaveMessage] CallInfo canLeaveMessage
             */

            /**
             * Constructs a new CallInfo.
             * @memberof c2c.agentServer
             * @classdesc Represents a CallInfo.
             * @implements ICallInfo
             * @constructor
             * @param {c2c.agentServer.ICallInfo=} [properties] Properties to set
             */
            function CallInfo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CallInfo callId.
             * @member {number} callId
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.callId = 0;

            /**
             * CallInfo startTime.
             * @member {number} startTime
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.startTime = 0;

            /**
             * CallInfo remoteNumber.
             * @member {string} remoteNumber
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.remoteNumber = "";

            /**
             * CallInfo remoteName.
             * @member {string|null|undefined} remoteName
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.remoteName = null;

            /**
             * CallInfo source.
             * @member {c2c.agentServer.CallSource} source
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.source = 0;

            /**
             * CallInfo fileNumber.
             * @member {string|null|undefined} fileNumber
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.fileNumber = null;

            /**
             * CallInfo rescheduledTs.
             * @member {number|null|undefined} rescheduledTs
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.rescheduledTs = null;

            /**
             * CallInfo transferById.
             * @member {number|null|undefined} transferById
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.transferById = null;

            /**
             * CallInfo transferNote.
             * @member {string|null|undefined} transferNote
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.transferNote = null;

            /**
             * CallInfo canLeaveMessage.
             * @member {boolean|null|undefined} canLeaveMessage
             * @memberof c2c.agentServer.CallInfo
             * @instance
             */
            CallInfo.prototype.canLeaveMessage = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CallInfo.prototype, "_remoteName", {
                get: $util.oneOfGetter($oneOfFields = ["remoteName"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CallInfo.prototype, "_fileNumber", {
                get: $util.oneOfGetter($oneOfFields = ["fileNumber"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CallInfo.prototype, "_rescheduledTs", {
                get: $util.oneOfGetter($oneOfFields = ["rescheduledTs"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CallInfo.prototype, "_transferById", {
                get: $util.oneOfGetter($oneOfFields = ["transferById"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CallInfo.prototype, "_transferNote", {
                get: $util.oneOfGetter($oneOfFields = ["transferNote"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CallInfo.prototype, "_canLeaveMessage", {
                get: $util.oneOfGetter($oneOfFields = ["canLeaveMessage"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified CallInfo message. Does not implicitly {@link c2c.agentServer.CallInfo.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CallInfo
             * @static
             * @param {c2c.agentServer.ICallInfo} message CallInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CallInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.callId);
                if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.startTime);
                if (message.remoteNumber != null && Object.hasOwnProperty.call(message, "remoteNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.remoteNumber);
                if (message.remoteName != null && Object.hasOwnProperty.call(message, "remoteName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.remoteName);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.source);
                if (message.fileNumber != null && Object.hasOwnProperty.call(message, "fileNumber"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.fileNumber);
                if (message.rescheduledTs != null && Object.hasOwnProperty.call(message, "rescheduledTs"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.rescheduledTs);
                if (message.transferById != null && Object.hasOwnProperty.call(message, "transferById"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.transferById);
                if (message.transferNote != null && Object.hasOwnProperty.call(message, "transferNote"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.transferNote);
                if (message.canLeaveMessage != null && Object.hasOwnProperty.call(message, "canLeaveMessage"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.canLeaveMessage);
                return writer;
            };

            /**
             * Decodes a CallInfo message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CallInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CallInfo} CallInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CallInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CallInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.callId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.startTime = reader.int32();
                            break;
                        }
                    case 3: {
                            message.remoteNumber = reader.string();
                            break;
                        }
                    case 4: {
                            message.remoteName = reader.string();
                            break;
                        }
                    case 5: {
                            message.source = reader.int32();
                            break;
                        }
                    case 6: {
                            message.fileNumber = reader.string();
                            break;
                        }
                    case 7: {
                            message.rescheduledTs = reader.int32();
                            break;
                        }
                    case 8: {
                            message.transferById = reader.int32();
                            break;
                        }
                    case 9: {
                            message.transferNote = reader.string();
                            break;
                        }
                    case 10: {
                            message.canLeaveMessage = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CallInfo message.
             * @function verify
             * @memberof c2c.agentServer.CallInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CallInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.callId != null && message.hasOwnProperty("callId"))
                    if (!$util.isInteger(message.callId))
                        return "callId: integer expected";
                if (message.startTime != null && message.hasOwnProperty("startTime"))
                    if (!$util.isInteger(message.startTime))
                        return "startTime: integer expected";
                if (message.remoteNumber != null && message.hasOwnProperty("remoteNumber"))
                    if (!$util.isString(message.remoteNumber))
                        return "remoteNumber: string expected";
                if (message.remoteName != null && message.hasOwnProperty("remoteName")) {
                    properties._remoteName = 1;
                    if (!$util.isString(message.remoteName))
                        return "remoteName: string expected";
                }
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.fileNumber != null && message.hasOwnProperty("fileNumber")) {
                    properties._fileNumber = 1;
                    if (!$util.isString(message.fileNumber))
                        return "fileNumber: string expected";
                }
                if (message.rescheduledTs != null && message.hasOwnProperty("rescheduledTs")) {
                    properties._rescheduledTs = 1;
                    if (!$util.isInteger(message.rescheduledTs))
                        return "rescheduledTs: integer expected";
                }
                if (message.transferById != null && message.hasOwnProperty("transferById")) {
                    properties._transferById = 1;
                    if (!$util.isInteger(message.transferById))
                        return "transferById: integer expected";
                }
                if (message.transferNote != null && message.hasOwnProperty("transferNote")) {
                    properties._transferNote = 1;
                    if (!$util.isString(message.transferNote))
                        return "transferNote: string expected";
                }
                if (message.canLeaveMessage != null && message.hasOwnProperty("canLeaveMessage")) {
                    properties._canLeaveMessage = 1;
                    if (typeof message.canLeaveMessage !== "boolean")
                        return "canLeaveMessage: boolean expected";
                }
                return null;
            };

            /**
             * Gets the default type url for CallInfo
             * @function getTypeUrl
             * @memberof c2c.agentServer.CallInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CallInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CallInfo";
            };

            return CallInfo;
        })();

        agentServer.AgentStatus = (function() {

            /**
             * Properties of an AgentStatus.
             * @memberof c2c.agentServer
             * @interface IAgentStatus
             * @property {number|null} [agentId] AgentStatus agentId
             * @property {number|null} [campaignId] AgentStatus campaignId
             * @property {c2c.agentServer.ICallInfo|null} [callInfo] AgentStatus callInfo
             * @property {boolean|null} [inQueue] AgentStatus inQueue
             * @property {string|null} [openReplaySid] AgentStatus openReplaySid
             */

            /**
             * Constructs a new AgentStatus.
             * @memberof c2c.agentServer
             * @classdesc Represents an AgentStatus.
             * @implements IAgentStatus
             * @constructor
             * @param {c2c.agentServer.IAgentStatus=} [properties] Properties to set
             */
            function AgentStatus(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AgentStatus agentId.
             * @member {number} agentId
             * @memberof c2c.agentServer.AgentStatus
             * @instance
             */
            AgentStatus.prototype.agentId = 0;

            /**
             * AgentStatus campaignId.
             * @member {number} campaignId
             * @memberof c2c.agentServer.AgentStatus
             * @instance
             */
            AgentStatus.prototype.campaignId = 0;

            /**
             * AgentStatus callInfo.
             * @member {c2c.agentServer.ICallInfo|null|undefined} callInfo
             * @memberof c2c.agentServer.AgentStatus
             * @instance
             */
            AgentStatus.prototype.callInfo = null;

            /**
             * AgentStatus inQueue.
             * @member {boolean|null|undefined} inQueue
             * @memberof c2c.agentServer.AgentStatus
             * @instance
             */
            AgentStatus.prototype.inQueue = null;

            /**
             * AgentStatus openReplaySid.
             * @member {string|null|undefined} openReplaySid
             * @memberof c2c.agentServer.AgentStatus
             * @instance
             */
            AgentStatus.prototype.openReplaySid = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(AgentStatus.prototype, "_callInfo", {
                get: $util.oneOfGetter($oneOfFields = ["callInfo"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(AgentStatus.prototype, "_inQueue", {
                get: $util.oneOfGetter($oneOfFields = ["inQueue"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(AgentStatus.prototype, "_openReplaySid", {
                get: $util.oneOfGetter($oneOfFields = ["openReplaySid"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified AgentStatus message. Does not implicitly {@link c2c.agentServer.AgentStatus.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.AgentStatus
             * @static
             * @param {c2c.agentServer.IAgentStatus} message AgentStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AgentStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.agentId != null && Object.hasOwnProperty.call(message, "agentId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.agentId);
                if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.campaignId);
                if (message.callInfo != null && Object.hasOwnProperty.call(message, "callInfo"))
                    $root.c2c.agentServer.CallInfo.encode(message.callInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.inQueue != null && Object.hasOwnProperty.call(message, "inQueue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.inQueue);
                if (message.openReplaySid != null && Object.hasOwnProperty.call(message, "openReplaySid"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.openReplaySid);
                return writer;
            };

            /**
             * Decodes an AgentStatus message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.AgentStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.AgentStatus} AgentStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AgentStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.AgentStatus();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.agentId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.campaignId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.callInfo = $root.c2c.agentServer.CallInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.inQueue = reader.bool();
                            break;
                        }
                    case 5: {
                            message.openReplaySid = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AgentStatus message.
             * @function verify
             * @memberof c2c.agentServer.AgentStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AgentStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.agentId != null && message.hasOwnProperty("agentId"))
                    if (!$util.isInteger(message.agentId))
                        return "agentId: integer expected";
                if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                    if (!$util.isInteger(message.campaignId))
                        return "campaignId: integer expected";
                if (message.callInfo != null && message.hasOwnProperty("callInfo")) {
                    properties._callInfo = 1;
                    {
                        let error = $root.c2c.agentServer.CallInfo.verify(message.callInfo);
                        if (error)
                            return "callInfo." + error;
                    }
                }
                if (message.inQueue != null && message.hasOwnProperty("inQueue")) {
                    properties._inQueue = 1;
                    if (typeof message.inQueue !== "boolean")
                        return "inQueue: boolean expected";
                }
                if (message.openReplaySid != null && message.hasOwnProperty("openReplaySid")) {
                    properties._openReplaySid = 1;
                    if (!$util.isString(message.openReplaySid))
                        return "openReplaySid: string expected";
                }
                return null;
            };

            /**
             * Gets the default type url for AgentStatus
             * @function getTypeUrl
             * @memberof c2c.agentServer.AgentStatus
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AgentStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.AgentStatus";
            };

            return AgentStatus;
        })();

        agentServer.CGenerateSipTokenRequest = (function() {

            /**
             * Properties of a CGenerateSipTokenRequest.
             * @memberof c2c.agentServer
             * @interface ICGenerateSipTokenRequest
             */

            /**
             * Constructs a new CGenerateSipTokenRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CGenerateSipTokenRequest.
             * @implements ICGenerateSipTokenRequest
             * @constructor
             * @param {c2c.agentServer.ICGenerateSipTokenRequest=} [properties] Properties to set
             */
            function CGenerateSipTokenRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CGenerateSipTokenRequest message. Does not implicitly {@link c2c.agentServer.CGenerateSipTokenRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CGenerateSipTokenRequest
             * @static
             * @param {c2c.agentServer.ICGenerateSipTokenRequest} message CGenerateSipTokenRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CGenerateSipTokenRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CGenerateSipTokenRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CGenerateSipTokenRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CGenerateSipTokenRequest} CGenerateSipTokenRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CGenerateSipTokenRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CGenerateSipTokenRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CGenerateSipTokenRequest message.
             * @function verify
             * @memberof c2c.agentServer.CGenerateSipTokenRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CGenerateSipTokenRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CGenerateSipTokenRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CGenerateSipTokenRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CGenerateSipTokenRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CGenerateSipTokenRequest";
            };

            return CGenerateSipTokenRequest;
        })();

        agentServer.CGenerateSipTokenResponse = (function() {

            /**
             * Properties of a CGenerateSipTokenResponse.
             * @memberof c2c.agentServer
             * @interface ICGenerateSipTokenResponse
             * @property {string|null} [token] CGenerateSipTokenResponse token
             */

            /**
             * Constructs a new CGenerateSipTokenResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CGenerateSipTokenResponse.
             * @implements ICGenerateSipTokenResponse
             * @constructor
             * @param {c2c.agentServer.ICGenerateSipTokenResponse=} [properties] Properties to set
             */
            function CGenerateSipTokenResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CGenerateSipTokenResponse token.
             * @member {string} token
             * @memberof c2c.agentServer.CGenerateSipTokenResponse
             * @instance
             */
            CGenerateSipTokenResponse.prototype.token = "";

            /**
             * Encodes the specified CGenerateSipTokenResponse message. Does not implicitly {@link c2c.agentServer.CGenerateSipTokenResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CGenerateSipTokenResponse
             * @static
             * @param {c2c.agentServer.ICGenerateSipTokenResponse} message CGenerateSipTokenResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CGenerateSipTokenResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                return writer;
            };

            /**
             * Decodes a CGenerateSipTokenResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CGenerateSipTokenResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CGenerateSipTokenResponse} CGenerateSipTokenResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CGenerateSipTokenResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CGenerateSipTokenResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.token = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CGenerateSipTokenResponse message.
             * @function verify
             * @memberof c2c.agentServer.CGenerateSipTokenResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CGenerateSipTokenResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };

            /**
             * Gets the default type url for CGenerateSipTokenResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CGenerateSipTokenResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CGenerateSipTokenResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CGenerateSipTokenResponse";
            };

            return CGenerateSipTokenResponse;
        })();

        agentServer.CPlaceOutboundCallRequest = (function() {

            /**
             * Properties of a CPlaceOutboundCallRequest.
             * @memberof c2c.agentServer
             * @interface ICPlaceOutboundCallRequest
             * @property {string|null} [remoteNumber] CPlaceOutboundCallRequest remoteNumber
             * @property {string|null} [remoteName] CPlaceOutboundCallRequest remoteName
             * @property {string|null} [fileNumber] CPlaceOutboundCallRequest fileNumber
             * @property {boolean|null} [isRescheduled] CPlaceOutboundCallRequest isRescheduled
             */

            /**
             * Constructs a new CPlaceOutboundCallRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CPlaceOutboundCallRequest.
             * @implements ICPlaceOutboundCallRequest
             * @constructor
             * @param {c2c.agentServer.ICPlaceOutboundCallRequest=} [properties] Properties to set
             */
            function CPlaceOutboundCallRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CPlaceOutboundCallRequest remoteNumber.
             * @member {string} remoteNumber
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @instance
             */
            CPlaceOutboundCallRequest.prototype.remoteNumber = "";

            /**
             * CPlaceOutboundCallRequest remoteName.
             * @member {string|null|undefined} remoteName
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @instance
             */
            CPlaceOutboundCallRequest.prototype.remoteName = null;

            /**
             * CPlaceOutboundCallRequest fileNumber.
             * @member {string|null|undefined} fileNumber
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @instance
             */
            CPlaceOutboundCallRequest.prototype.fileNumber = null;

            /**
             * CPlaceOutboundCallRequest isRescheduled.
             * @member {boolean|null|undefined} isRescheduled
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @instance
             */
            CPlaceOutboundCallRequest.prototype.isRescheduled = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CPlaceOutboundCallRequest.prototype, "_remoteName", {
                get: $util.oneOfGetter($oneOfFields = ["remoteName"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CPlaceOutboundCallRequest.prototype, "_fileNumber", {
                get: $util.oneOfGetter($oneOfFields = ["fileNumber"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CPlaceOutboundCallRequest.prototype, "_isRescheduled", {
                get: $util.oneOfGetter($oneOfFields = ["isRescheduled"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified CPlaceOutboundCallRequest message. Does not implicitly {@link c2c.agentServer.CPlaceOutboundCallRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @static
             * @param {c2c.agentServer.ICPlaceOutboundCallRequest} message CPlaceOutboundCallRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPlaceOutboundCallRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.remoteNumber != null && Object.hasOwnProperty.call(message, "remoteNumber"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.remoteNumber);
                if (message.remoteName != null && Object.hasOwnProperty.call(message, "remoteName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.remoteName);
                if (message.fileNumber != null && Object.hasOwnProperty.call(message, "fileNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.fileNumber);
                if (message.isRescheduled != null && Object.hasOwnProperty.call(message, "isRescheduled"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isRescheduled);
                return writer;
            };

            /**
             * Decodes a CPlaceOutboundCallRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CPlaceOutboundCallRequest} CPlaceOutboundCallRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPlaceOutboundCallRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CPlaceOutboundCallRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.remoteNumber = reader.string();
                            break;
                        }
                    case 2: {
                            message.remoteName = reader.string();
                            break;
                        }
                    case 3: {
                            message.fileNumber = reader.string();
                            break;
                        }
                    case 4: {
                            message.isRescheduled = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CPlaceOutboundCallRequest message.
             * @function verify
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CPlaceOutboundCallRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.remoteNumber != null && message.hasOwnProperty("remoteNumber"))
                    if (!$util.isString(message.remoteNumber))
                        return "remoteNumber: string expected";
                if (message.remoteName != null && message.hasOwnProperty("remoteName")) {
                    properties._remoteName = 1;
                    if (!$util.isString(message.remoteName))
                        return "remoteName: string expected";
                }
                if (message.fileNumber != null && message.hasOwnProperty("fileNumber")) {
                    properties._fileNumber = 1;
                    if (!$util.isString(message.fileNumber))
                        return "fileNumber: string expected";
                }
                if (message.isRescheduled != null && message.hasOwnProperty("isRescheduled")) {
                    properties._isRescheduled = 1;
                    if (typeof message.isRescheduled !== "boolean")
                        return "isRescheduled: boolean expected";
                }
                return null;
            };

            /**
             * Gets the default type url for CPlaceOutboundCallRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CPlaceOutboundCallRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CPlaceOutboundCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CPlaceOutboundCallRequest";
            };

            return CPlaceOutboundCallRequest;
        })();

        agentServer.CPlaceOutboundCallResponse = (function() {

            /**
             * Properties of a CPlaceOutboundCallResponse.
             * @memberof c2c.agentServer
             * @interface ICPlaceOutboundCallResponse
             */

            /**
             * Constructs a new CPlaceOutboundCallResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CPlaceOutboundCallResponse.
             * @implements ICPlaceOutboundCallResponse
             * @constructor
             * @param {c2c.agentServer.ICPlaceOutboundCallResponse=} [properties] Properties to set
             */
            function CPlaceOutboundCallResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CPlaceOutboundCallResponse message. Does not implicitly {@link c2c.agentServer.CPlaceOutboundCallResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CPlaceOutboundCallResponse
             * @static
             * @param {c2c.agentServer.ICPlaceOutboundCallResponse} message CPlaceOutboundCallResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CPlaceOutboundCallResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CPlaceOutboundCallResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CPlaceOutboundCallResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CPlaceOutboundCallResponse} CPlaceOutboundCallResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CPlaceOutboundCallResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CPlaceOutboundCallResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CPlaceOutboundCallResponse message.
             * @function verify
             * @memberof c2c.agentServer.CPlaceOutboundCallResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CPlaceOutboundCallResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CPlaceOutboundCallResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CPlaceOutboundCallResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CPlaceOutboundCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CPlaceOutboundCallResponse";
            };

            return CPlaceOutboundCallResponse;
        })();

        agentServer.CEndCallRequest = (function() {

            /**
             * Properties of a CEndCallRequest.
             * @memberof c2c.agentServer
             * @interface ICEndCallRequest
             * @property {number|null} [callId] CEndCallRequest callId
             */

            /**
             * Constructs a new CEndCallRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CEndCallRequest.
             * @implements ICEndCallRequest
             * @constructor
             * @param {c2c.agentServer.ICEndCallRequest=} [properties] Properties to set
             */
            function CEndCallRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CEndCallRequest callId.
             * @member {number} callId
             * @memberof c2c.agentServer.CEndCallRequest
             * @instance
             */
            CEndCallRequest.prototype.callId = 0;

            /**
             * Encodes the specified CEndCallRequest message. Does not implicitly {@link c2c.agentServer.CEndCallRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CEndCallRequest
             * @static
             * @param {c2c.agentServer.ICEndCallRequest} message CEndCallRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CEndCallRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.callId);
                return writer;
            };

            /**
             * Decodes a CEndCallRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CEndCallRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CEndCallRequest} CEndCallRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CEndCallRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CEndCallRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.callId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CEndCallRequest message.
             * @function verify
             * @memberof c2c.agentServer.CEndCallRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CEndCallRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.callId != null && message.hasOwnProperty("callId"))
                    if (!$util.isInteger(message.callId))
                        return "callId: integer expected";
                return null;
            };

            /**
             * Gets the default type url for CEndCallRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CEndCallRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CEndCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CEndCallRequest";
            };

            return CEndCallRequest;
        })();

        agentServer.CEndCallResponse = (function() {

            /**
             * Properties of a CEndCallResponse.
             * @memberof c2c.agentServer
             * @interface ICEndCallResponse
             */

            /**
             * Constructs a new CEndCallResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CEndCallResponse.
             * @implements ICEndCallResponse
             * @constructor
             * @param {c2c.agentServer.ICEndCallResponse=} [properties] Properties to set
             */
            function CEndCallResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CEndCallResponse message. Does not implicitly {@link c2c.agentServer.CEndCallResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CEndCallResponse
             * @static
             * @param {c2c.agentServer.ICEndCallResponse} message CEndCallResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CEndCallResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CEndCallResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CEndCallResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CEndCallResponse} CEndCallResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CEndCallResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CEndCallResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CEndCallResponse message.
             * @function verify
             * @memberof c2c.agentServer.CEndCallResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CEndCallResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CEndCallResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CEndCallResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CEndCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CEndCallResponse";
            };

            return CEndCallResponse;
        })();

        agentServer.CJoinCampaignRequest = (function() {

            /**
             * Properties of a CJoinCampaignRequest.
             * @memberof c2c.agentServer
             * @interface ICJoinCampaignRequest
             * @property {number|null} [campaignId] CJoinCampaignRequest campaignId
             */

            /**
             * Constructs a new CJoinCampaignRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CJoinCampaignRequest.
             * @implements ICJoinCampaignRequest
             * @constructor
             * @param {c2c.agentServer.ICJoinCampaignRequest=} [properties] Properties to set
             */
            function CJoinCampaignRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CJoinCampaignRequest campaignId.
             * @member {number} campaignId
             * @memberof c2c.agentServer.CJoinCampaignRequest
             * @instance
             */
            CJoinCampaignRequest.prototype.campaignId = 0;

            /**
             * Encodes the specified CJoinCampaignRequest message. Does not implicitly {@link c2c.agentServer.CJoinCampaignRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CJoinCampaignRequest
             * @static
             * @param {c2c.agentServer.ICJoinCampaignRequest} message CJoinCampaignRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CJoinCampaignRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.campaignId);
                return writer;
            };

            /**
             * Decodes a CJoinCampaignRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CJoinCampaignRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CJoinCampaignRequest} CJoinCampaignRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CJoinCampaignRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CJoinCampaignRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.campaignId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CJoinCampaignRequest message.
             * @function verify
             * @memberof c2c.agentServer.CJoinCampaignRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CJoinCampaignRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                    if (!$util.isInteger(message.campaignId))
                        return "campaignId: integer expected";
                return null;
            };

            /**
             * Gets the default type url for CJoinCampaignRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CJoinCampaignRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CJoinCampaignRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CJoinCampaignRequest";
            };

            return CJoinCampaignRequest;
        })();

        agentServer.CJoinCampaignResponse = (function() {

            /**
             * Properties of a CJoinCampaignResponse.
             * @memberof c2c.agentServer
             * @interface ICJoinCampaignResponse
             */

            /**
             * Constructs a new CJoinCampaignResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CJoinCampaignResponse.
             * @implements ICJoinCampaignResponse
             * @constructor
             * @param {c2c.agentServer.ICJoinCampaignResponse=} [properties] Properties to set
             */
            function CJoinCampaignResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CJoinCampaignResponse message. Does not implicitly {@link c2c.agentServer.CJoinCampaignResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CJoinCampaignResponse
             * @static
             * @param {c2c.agentServer.ICJoinCampaignResponse} message CJoinCampaignResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CJoinCampaignResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CJoinCampaignResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CJoinCampaignResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CJoinCampaignResponse} CJoinCampaignResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CJoinCampaignResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CJoinCampaignResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CJoinCampaignResponse message.
             * @function verify
             * @memberof c2c.agentServer.CJoinCampaignResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CJoinCampaignResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CJoinCampaignResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CJoinCampaignResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CJoinCampaignResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CJoinCampaignResponse";
            };

            return CJoinCampaignResponse;
        })();

        agentServer.CEnterCallQueueRequest = (function() {

            /**
             * Properties of a CEnterCallQueueRequest.
             * @memberof c2c.agentServer
             * @interface ICEnterCallQueueRequest
             * @property {boolean|null} [excludeCallbacks] CEnterCallQueueRequest excludeCallbacks
             * @property {boolean|null} [excludeInbound] CEnterCallQueueRequest excludeInbound
             */

            /**
             * Constructs a new CEnterCallQueueRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CEnterCallQueueRequest.
             * @implements ICEnterCallQueueRequest
             * @constructor
             * @param {c2c.agentServer.ICEnterCallQueueRequest=} [properties] Properties to set
             */
            function CEnterCallQueueRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CEnterCallQueueRequest excludeCallbacks.
             * @member {boolean|null|undefined} excludeCallbacks
             * @memberof c2c.agentServer.CEnterCallQueueRequest
             * @instance
             */
            CEnterCallQueueRequest.prototype.excludeCallbacks = null;

            /**
             * CEnterCallQueueRequest excludeInbound.
             * @member {boolean|null|undefined} excludeInbound
             * @memberof c2c.agentServer.CEnterCallQueueRequest
             * @instance
             */
            CEnterCallQueueRequest.prototype.excludeInbound = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CEnterCallQueueRequest.prototype, "_excludeCallbacks", {
                get: $util.oneOfGetter($oneOfFields = ["excludeCallbacks"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CEnterCallQueueRequest.prototype, "_excludeInbound", {
                get: $util.oneOfGetter($oneOfFields = ["excludeInbound"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified CEnterCallQueueRequest message. Does not implicitly {@link c2c.agentServer.CEnterCallQueueRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CEnterCallQueueRequest
             * @static
             * @param {c2c.agentServer.ICEnterCallQueueRequest} message CEnterCallQueueRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CEnterCallQueueRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.excludeCallbacks != null && Object.hasOwnProperty.call(message, "excludeCallbacks"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.excludeCallbacks);
                if (message.excludeInbound != null && Object.hasOwnProperty.call(message, "excludeInbound"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.excludeInbound);
                return writer;
            };

            /**
             * Decodes a CEnterCallQueueRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CEnterCallQueueRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CEnterCallQueueRequest} CEnterCallQueueRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CEnterCallQueueRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CEnterCallQueueRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.excludeCallbacks = reader.bool();
                            break;
                        }
                    case 2: {
                            message.excludeInbound = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CEnterCallQueueRequest message.
             * @function verify
             * @memberof c2c.agentServer.CEnterCallQueueRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CEnterCallQueueRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.excludeCallbacks != null && message.hasOwnProperty("excludeCallbacks")) {
                    properties._excludeCallbacks = 1;
                    if (typeof message.excludeCallbacks !== "boolean")
                        return "excludeCallbacks: boolean expected";
                }
                if (message.excludeInbound != null && message.hasOwnProperty("excludeInbound")) {
                    properties._excludeInbound = 1;
                    if (typeof message.excludeInbound !== "boolean")
                        return "excludeInbound: boolean expected";
                }
                return null;
            };

            /**
             * Gets the default type url for CEnterCallQueueRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CEnterCallQueueRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CEnterCallQueueRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CEnterCallQueueRequest";
            };

            return CEnterCallQueueRequest;
        })();

        agentServer.CEnterCallQueueResponse = (function() {

            /**
             * Properties of a CEnterCallQueueResponse.
             * @memberof c2c.agentServer
             * @interface ICEnterCallQueueResponse
             * @property {boolean|null} [inQueue] CEnterCallQueueResponse inQueue
             */

            /**
             * Constructs a new CEnterCallQueueResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CEnterCallQueueResponse.
             * @implements ICEnterCallQueueResponse
             * @constructor
             * @param {c2c.agentServer.ICEnterCallQueueResponse=} [properties] Properties to set
             */
            function CEnterCallQueueResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CEnterCallQueueResponse inQueue.
             * @member {boolean|null|undefined} inQueue
             * @memberof c2c.agentServer.CEnterCallQueueResponse
             * @instance
             */
            CEnterCallQueueResponse.prototype.inQueue = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CEnterCallQueueResponse.prototype, "_inQueue", {
                get: $util.oneOfGetter($oneOfFields = ["inQueue"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified CEnterCallQueueResponse message. Does not implicitly {@link c2c.agentServer.CEnterCallQueueResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CEnterCallQueueResponse
             * @static
             * @param {c2c.agentServer.ICEnterCallQueueResponse} message CEnterCallQueueResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CEnterCallQueueResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.inQueue != null && Object.hasOwnProperty.call(message, "inQueue"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.inQueue);
                return writer;
            };

            /**
             * Decodes a CEnterCallQueueResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CEnterCallQueueResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CEnterCallQueueResponse} CEnterCallQueueResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CEnterCallQueueResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CEnterCallQueueResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.inQueue = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CEnterCallQueueResponse message.
             * @function verify
             * @memberof c2c.agentServer.CEnterCallQueueResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CEnterCallQueueResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.inQueue != null && message.hasOwnProperty("inQueue")) {
                    properties._inQueue = 1;
                    if (typeof message.inQueue !== "boolean")
                        return "inQueue: boolean expected";
                }
                return null;
            };

            /**
             * Gets the default type url for CEnterCallQueueResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CEnterCallQueueResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CEnterCallQueueResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CEnterCallQueueResponse";
            };

            return CEnterCallQueueResponse;
        })();

        agentServer.CTransferCallRequest = (function() {

            /**
             * Properties of a CTransferCallRequest.
             * @memberof c2c.agentServer
             * @interface ICTransferCallRequest
             * @property {number|null} [callId] CTransferCallRequest callId
             * @property {string|null} [extension] CTransferCallRequest extension
             * @property {number|null} [agentId] CTransferCallRequest agentId
             * @property {string|null} [message] CTransferCallRequest message
             */

            /**
             * Constructs a new CTransferCallRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CTransferCallRequest.
             * @implements ICTransferCallRequest
             * @constructor
             * @param {c2c.agentServer.ICTransferCallRequest=} [properties] Properties to set
             */
            function CTransferCallRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CTransferCallRequest callId.
             * @member {number} callId
             * @memberof c2c.agentServer.CTransferCallRequest
             * @instance
             */
            CTransferCallRequest.prototype.callId = 0;

            /**
             * CTransferCallRequest extension.
             * @member {string|null|undefined} extension
             * @memberof c2c.agentServer.CTransferCallRequest
             * @instance
             */
            CTransferCallRequest.prototype.extension = null;

            /**
             * CTransferCallRequest agentId.
             * @member {number|null|undefined} agentId
             * @memberof c2c.agentServer.CTransferCallRequest
             * @instance
             */
            CTransferCallRequest.prototype.agentId = null;

            /**
             * CTransferCallRequest message.
             * @member {string|null|undefined} message
             * @memberof c2c.agentServer.CTransferCallRequest
             * @instance
             */
            CTransferCallRequest.prototype.message = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CTransferCallRequest.prototype, "_extension", {
                get: $util.oneOfGetter($oneOfFields = ["extension"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CTransferCallRequest.prototype, "_agentId", {
                get: $util.oneOfGetter($oneOfFields = ["agentId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CTransferCallRequest.prototype, "_message", {
                get: $util.oneOfGetter($oneOfFields = ["message"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified CTransferCallRequest message. Does not implicitly {@link c2c.agentServer.CTransferCallRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CTransferCallRequest
             * @static
             * @param {c2c.agentServer.ICTransferCallRequest} message CTransferCallRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CTransferCallRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.callId);
                if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extension);
                if (message.agentId != null && Object.hasOwnProperty.call(message, "agentId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.agentId);
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
                return writer;
            };

            /**
             * Decodes a CTransferCallRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CTransferCallRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CTransferCallRequest} CTransferCallRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CTransferCallRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CTransferCallRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.callId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.extension = reader.string();
                            break;
                        }
                    case 3: {
                            message.agentId = reader.int32();
                            break;
                        }
                    case 4: {
                            message.message = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CTransferCallRequest message.
             * @function verify
             * @memberof c2c.agentServer.CTransferCallRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CTransferCallRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.callId != null && message.hasOwnProperty("callId"))
                    if (!$util.isInteger(message.callId))
                        return "callId: integer expected";
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    properties._extension = 1;
                    if (!$util.isString(message.extension))
                        return "extension: string expected";
                }
                if (message.agentId != null && message.hasOwnProperty("agentId")) {
                    properties._agentId = 1;
                    if (!$util.isInteger(message.agentId))
                        return "agentId: integer expected";
                }
                if (message.message != null && message.hasOwnProperty("message")) {
                    properties._message = 1;
                    if (!$util.isString(message.message))
                        return "message: string expected";
                }
                return null;
            };

            /**
             * Gets the default type url for CTransferCallRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CTransferCallRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CTransferCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CTransferCallRequest";
            };

            return CTransferCallRequest;
        })();

        agentServer.CTransferCallResponse = (function() {

            /**
             * Properties of a CTransferCallResponse.
             * @memberof c2c.agentServer
             * @interface ICTransferCallResponse
             */

            /**
             * Constructs a new CTransferCallResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CTransferCallResponse.
             * @implements ICTransferCallResponse
             * @constructor
             * @param {c2c.agentServer.ICTransferCallResponse=} [properties] Properties to set
             */
            function CTransferCallResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CTransferCallResponse message. Does not implicitly {@link c2c.agentServer.CTransferCallResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CTransferCallResponse
             * @static
             * @param {c2c.agentServer.ICTransferCallResponse} message CTransferCallResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CTransferCallResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CTransferCallResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CTransferCallResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CTransferCallResponse} CTransferCallResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CTransferCallResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CTransferCallResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CTransferCallResponse message.
             * @function verify
             * @memberof c2c.agentServer.CTransferCallResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CTransferCallResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CTransferCallResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CTransferCallResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CTransferCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CTransferCallResponse";
            };

            return CTransferCallResponse;
        })();

        agentServer.CHoldCallRequest = (function() {

            /**
             * Properties of a CHoldCallRequest.
             * @memberof c2c.agentServer
             * @interface ICHoldCallRequest
             * @property {number|null} [callId] CHoldCallRequest callId
             * @property {boolean|null} [hold] CHoldCallRequest hold
             */

            /**
             * Constructs a new CHoldCallRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CHoldCallRequest.
             * @implements ICHoldCallRequest
             * @constructor
             * @param {c2c.agentServer.ICHoldCallRequest=} [properties] Properties to set
             */
            function CHoldCallRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CHoldCallRequest callId.
             * @member {number} callId
             * @memberof c2c.agentServer.CHoldCallRequest
             * @instance
             */
            CHoldCallRequest.prototype.callId = 0;

            /**
             * CHoldCallRequest hold.
             * @member {boolean} hold
             * @memberof c2c.agentServer.CHoldCallRequest
             * @instance
             */
            CHoldCallRequest.prototype.hold = false;

            /**
             * Encodes the specified CHoldCallRequest message. Does not implicitly {@link c2c.agentServer.CHoldCallRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CHoldCallRequest
             * @static
             * @param {c2c.agentServer.ICHoldCallRequest} message CHoldCallRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CHoldCallRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.callId != null && Object.hasOwnProperty.call(message, "callId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.callId);
                if (message.hold != null && Object.hasOwnProperty.call(message, "hold"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hold);
                return writer;
            };

            /**
             * Decodes a CHoldCallRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CHoldCallRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CHoldCallRequest} CHoldCallRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CHoldCallRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CHoldCallRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.callId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.hold = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CHoldCallRequest message.
             * @function verify
             * @memberof c2c.agentServer.CHoldCallRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CHoldCallRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.callId != null && message.hasOwnProperty("callId"))
                    if (!$util.isInteger(message.callId))
                        return "callId: integer expected";
                if (message.hold != null && message.hasOwnProperty("hold"))
                    if (typeof message.hold !== "boolean")
                        return "hold: boolean expected";
                return null;
            };

            /**
             * Gets the default type url for CHoldCallRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CHoldCallRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CHoldCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CHoldCallRequest";
            };

            return CHoldCallRequest;
        })();

        agentServer.CHoldCallResponse = (function() {

            /**
             * Properties of a CHoldCallResponse.
             * @memberof c2c.agentServer
             * @interface ICHoldCallResponse
             */

            /**
             * Constructs a new CHoldCallResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CHoldCallResponse.
             * @implements ICHoldCallResponse
             * @constructor
             * @param {c2c.agentServer.ICHoldCallResponse=} [properties] Properties to set
             */
            function CHoldCallResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CHoldCallResponse message. Does not implicitly {@link c2c.agentServer.CHoldCallResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CHoldCallResponse
             * @static
             * @param {c2c.agentServer.ICHoldCallResponse} message CHoldCallResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CHoldCallResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CHoldCallResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CHoldCallResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CHoldCallResponse} CHoldCallResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CHoldCallResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CHoldCallResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CHoldCallResponse message.
             * @function verify
             * @memberof c2c.agentServer.CHoldCallResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CHoldCallResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CHoldCallResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CHoldCallResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CHoldCallResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CHoldCallResponse";
            };

            return CHoldCallResponse;
        })();

        agentServer.CSubcribeAgentStatusRequest = (function() {

            /**
             * Properties of a CSubcribeAgentStatusRequest.
             * @memberof c2c.agentServer
             * @interface ICSubcribeAgentStatusRequest
             * @property {boolean|null} [subscribe] CSubcribeAgentStatusRequest subscribe
             */

            /**
             * Constructs a new CSubcribeAgentStatusRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CSubcribeAgentStatusRequest.
             * @implements ICSubcribeAgentStatusRequest
             * @constructor
             * @param {c2c.agentServer.ICSubcribeAgentStatusRequest=} [properties] Properties to set
             */
            function CSubcribeAgentStatusRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CSubcribeAgentStatusRequest subscribe.
             * @member {boolean} subscribe
             * @memberof c2c.agentServer.CSubcribeAgentStatusRequest
             * @instance
             */
            CSubcribeAgentStatusRequest.prototype.subscribe = false;

            /**
             * Encodes the specified CSubcribeAgentStatusRequest message. Does not implicitly {@link c2c.agentServer.CSubcribeAgentStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CSubcribeAgentStatusRequest
             * @static
             * @param {c2c.agentServer.ICSubcribeAgentStatusRequest} message CSubcribeAgentStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSubcribeAgentStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subscribe != null && Object.hasOwnProperty.call(message, "subscribe"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.subscribe);
                return writer;
            };

            /**
             * Decodes a CSubcribeAgentStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CSubcribeAgentStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CSubcribeAgentStatusRequest} CSubcribeAgentStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSubcribeAgentStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CSubcribeAgentStatusRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.subscribe = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CSubcribeAgentStatusRequest message.
             * @function verify
             * @memberof c2c.agentServer.CSubcribeAgentStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSubcribeAgentStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subscribe != null && message.hasOwnProperty("subscribe"))
                    if (typeof message.subscribe !== "boolean")
                        return "subscribe: boolean expected";
                return null;
            };

            /**
             * Gets the default type url for CSubcribeAgentStatusRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CSubcribeAgentStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSubcribeAgentStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CSubcribeAgentStatusRequest";
            };

            return CSubcribeAgentStatusRequest;
        })();

        agentServer.CSubcribeAgentStatusResponse = (function() {

            /**
             * Properties of a CSubcribeAgentStatusResponse.
             * @memberof c2c.agentServer
             * @interface ICSubcribeAgentStatusResponse
             */

            /**
             * Constructs a new CSubcribeAgentStatusResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CSubcribeAgentStatusResponse.
             * @implements ICSubcribeAgentStatusResponse
             * @constructor
             * @param {c2c.agentServer.ICSubcribeAgentStatusResponse=} [properties] Properties to set
             */
            function CSubcribeAgentStatusResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CSubcribeAgentStatusResponse message. Does not implicitly {@link c2c.agentServer.CSubcribeAgentStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CSubcribeAgentStatusResponse
             * @static
             * @param {c2c.agentServer.ICSubcribeAgentStatusResponse} message CSubcribeAgentStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CSubcribeAgentStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CSubcribeAgentStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CSubcribeAgentStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CSubcribeAgentStatusResponse} CSubcribeAgentStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CSubcribeAgentStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CSubcribeAgentStatusResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CSubcribeAgentStatusResponse message.
             * @function verify
             * @memberof c2c.agentServer.CSubcribeAgentStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CSubcribeAgentStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CSubcribeAgentStatusResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CSubcribeAgentStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CSubcribeAgentStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CSubcribeAgentStatusResponse";
            };

            return CSubcribeAgentStatusResponse;
        })();

        agentServer.CFetchAgentStatusRequest = (function() {

            /**
             * Properties of a CFetchAgentStatusRequest.
             * @memberof c2c.agentServer
             * @interface ICFetchAgentStatusRequest
             */

            /**
             * Constructs a new CFetchAgentStatusRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CFetchAgentStatusRequest.
             * @implements ICFetchAgentStatusRequest
             * @constructor
             * @param {c2c.agentServer.ICFetchAgentStatusRequest=} [properties] Properties to set
             */
            function CFetchAgentStatusRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CFetchAgentStatusRequest message. Does not implicitly {@link c2c.agentServer.CFetchAgentStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CFetchAgentStatusRequest
             * @static
             * @param {c2c.agentServer.ICFetchAgentStatusRequest} message CFetchAgentStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CFetchAgentStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CFetchAgentStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CFetchAgentStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CFetchAgentStatusRequest} CFetchAgentStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CFetchAgentStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CFetchAgentStatusRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CFetchAgentStatusRequest message.
             * @function verify
             * @memberof c2c.agentServer.CFetchAgentStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CFetchAgentStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CFetchAgentStatusRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CFetchAgentStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CFetchAgentStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CFetchAgentStatusRequest";
            };

            return CFetchAgentStatusRequest;
        })();

        agentServer.CFetchAgentStatusResponse = (function() {

            /**
             * Properties of a CFetchAgentStatusResponse.
             * @memberof c2c.agentServer
             * @interface ICFetchAgentStatusResponse
             * @property {Array.<c2c.agentServer.IAgentStatus>|null} [agents] CFetchAgentStatusResponse agents
             */

            /**
             * Constructs a new CFetchAgentStatusResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CFetchAgentStatusResponse.
             * @implements ICFetchAgentStatusResponse
             * @constructor
             * @param {c2c.agentServer.ICFetchAgentStatusResponse=} [properties] Properties to set
             */
            function CFetchAgentStatusResponse(properties) {
                this.agents = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CFetchAgentStatusResponse agents.
             * @member {Array.<c2c.agentServer.IAgentStatus>} agents
             * @memberof c2c.agentServer.CFetchAgentStatusResponse
             * @instance
             */
            CFetchAgentStatusResponse.prototype.agents = $util.emptyArray;

            /**
             * Encodes the specified CFetchAgentStatusResponse message. Does not implicitly {@link c2c.agentServer.CFetchAgentStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CFetchAgentStatusResponse
             * @static
             * @param {c2c.agentServer.ICFetchAgentStatusResponse} message CFetchAgentStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CFetchAgentStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.agents != null && message.agents.length)
                    for (let i = 0; i < message.agents.length; ++i)
                        $root.c2c.agentServer.AgentStatus.encode(message.agents[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a CFetchAgentStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CFetchAgentStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CFetchAgentStatusResponse} CFetchAgentStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CFetchAgentStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CFetchAgentStatusResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.agents && message.agents.length))
                                message.agents = [];
                            message.agents.push($root.c2c.agentServer.AgentStatus.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CFetchAgentStatusResponse message.
             * @function verify
             * @memberof c2c.agentServer.CFetchAgentStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CFetchAgentStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.agents != null && message.hasOwnProperty("agents")) {
                    if (!Array.isArray(message.agents))
                        return "agents: array expected";
                    for (let i = 0; i < message.agents.length; ++i) {
                        let error = $root.c2c.agentServer.AgentStatus.verify(message.agents[i]);
                        if (error)
                            return "agents." + error;
                    }
                }
                return null;
            };

            /**
             * Gets the default type url for CFetchAgentStatusResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CFetchAgentStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CFetchAgentStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CFetchAgentStatusResponse";
            };

            return CFetchAgentStatusResponse;
        })();

        agentServer.CUpdateOpenReplaySidRequest = (function() {

            /**
             * Properties of a CUpdateOpenReplaySidRequest.
             * @memberof c2c.agentServer
             * @interface ICUpdateOpenReplaySidRequest
             * @property {string|null} [openReplaySid] CUpdateOpenReplaySidRequest openReplaySid
             */

            /**
             * Constructs a new CUpdateOpenReplaySidRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a CUpdateOpenReplaySidRequest.
             * @implements ICUpdateOpenReplaySidRequest
             * @constructor
             * @param {c2c.agentServer.ICUpdateOpenReplaySidRequest=} [properties] Properties to set
             */
            function CUpdateOpenReplaySidRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CUpdateOpenReplaySidRequest openReplaySid.
             * @member {string} openReplaySid
             * @memberof c2c.agentServer.CUpdateOpenReplaySidRequest
             * @instance
             */
            CUpdateOpenReplaySidRequest.prototype.openReplaySid = "";

            /**
             * Encodes the specified CUpdateOpenReplaySidRequest message. Does not implicitly {@link c2c.agentServer.CUpdateOpenReplaySidRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CUpdateOpenReplaySidRequest
             * @static
             * @param {c2c.agentServer.ICUpdateOpenReplaySidRequest} message CUpdateOpenReplaySidRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CUpdateOpenReplaySidRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.openReplaySid != null && Object.hasOwnProperty.call(message, "openReplaySid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.openReplaySid);
                return writer;
            };

            /**
             * Decodes a CUpdateOpenReplaySidRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CUpdateOpenReplaySidRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CUpdateOpenReplaySidRequest} CUpdateOpenReplaySidRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CUpdateOpenReplaySidRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CUpdateOpenReplaySidRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.openReplaySid = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CUpdateOpenReplaySidRequest message.
             * @function verify
             * @memberof c2c.agentServer.CUpdateOpenReplaySidRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CUpdateOpenReplaySidRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.openReplaySid != null && message.hasOwnProperty("openReplaySid"))
                    if (!$util.isString(message.openReplaySid))
                        return "openReplaySid: string expected";
                return null;
            };

            /**
             * Gets the default type url for CUpdateOpenReplaySidRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.CUpdateOpenReplaySidRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CUpdateOpenReplaySidRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CUpdateOpenReplaySidRequest";
            };

            return CUpdateOpenReplaySidRequest;
        })();

        agentServer.CUpdateOpenReplaySidResponse = (function() {

            /**
             * Properties of a CUpdateOpenReplaySidResponse.
             * @memberof c2c.agentServer
             * @interface ICUpdateOpenReplaySidResponse
             */

            /**
             * Constructs a new CUpdateOpenReplaySidResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a CUpdateOpenReplaySidResponse.
             * @implements ICUpdateOpenReplaySidResponse
             * @constructor
             * @param {c2c.agentServer.ICUpdateOpenReplaySidResponse=} [properties] Properties to set
             */
            function CUpdateOpenReplaySidResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified CUpdateOpenReplaySidResponse message. Does not implicitly {@link c2c.agentServer.CUpdateOpenReplaySidResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CUpdateOpenReplaySidResponse
             * @static
             * @param {c2c.agentServer.ICUpdateOpenReplaySidResponse} message CUpdateOpenReplaySidResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CUpdateOpenReplaySidResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a CUpdateOpenReplaySidResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CUpdateOpenReplaySidResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CUpdateOpenReplaySidResponse} CUpdateOpenReplaySidResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CUpdateOpenReplaySidResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CUpdateOpenReplaySidResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CUpdateOpenReplaySidResponse message.
             * @function verify
             * @memberof c2c.agentServer.CUpdateOpenReplaySidResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CUpdateOpenReplaySidResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for CUpdateOpenReplaySidResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.CUpdateOpenReplaySidResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CUpdateOpenReplaySidResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CUpdateOpenReplaySidResponse";
            };

            return CUpdateOpenReplaySidResponse;
        })();

        agentServer.SUpdateCallDisplayRequest = (function() {

            /**
             * Properties of a SUpdateCallDisplayRequest.
             * @memberof c2c.agentServer
             * @interface ISUpdateCallDisplayRequest
             * @property {c2c.agentServer.ICallInfo|null} [callInfo] SUpdateCallDisplayRequest callInfo
             * @property {string|null} [hangupCause] SUpdateCallDisplayRequest hangupCause
             */

            /**
             * Constructs a new SUpdateCallDisplayRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a SUpdateCallDisplayRequest.
             * @implements ISUpdateCallDisplayRequest
             * @constructor
             * @param {c2c.agentServer.ISUpdateCallDisplayRequest=} [properties] Properties to set
             */
            function SUpdateCallDisplayRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SUpdateCallDisplayRequest callInfo.
             * @member {c2c.agentServer.ICallInfo|null|undefined} callInfo
             * @memberof c2c.agentServer.SUpdateCallDisplayRequest
             * @instance
             */
            SUpdateCallDisplayRequest.prototype.callInfo = null;

            /**
             * SUpdateCallDisplayRequest hangupCause.
             * @member {string|null|undefined} hangupCause
             * @memberof c2c.agentServer.SUpdateCallDisplayRequest
             * @instance
             */
            SUpdateCallDisplayRequest.prototype.hangupCause = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateCallDisplayRequest.prototype, "_callInfo", {
                get: $util.oneOfGetter($oneOfFields = ["callInfo"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateCallDisplayRequest.prototype, "_hangupCause", {
                get: $util.oneOfGetter($oneOfFields = ["hangupCause"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified SUpdateCallDisplayRequest message. Does not implicitly {@link c2c.agentServer.SUpdateCallDisplayRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.SUpdateCallDisplayRequest
             * @static
             * @param {c2c.agentServer.ISUpdateCallDisplayRequest} message SUpdateCallDisplayRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SUpdateCallDisplayRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.callInfo != null && Object.hasOwnProperty.call(message, "callInfo"))
                    $root.c2c.agentServer.CallInfo.encode(message.callInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.hangupCause != null && Object.hasOwnProperty.call(message, "hangupCause"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.hangupCause);
                return writer;
            };

            /**
             * Decodes a SUpdateCallDisplayRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.SUpdateCallDisplayRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.SUpdateCallDisplayRequest} SUpdateCallDisplayRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SUpdateCallDisplayRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.SUpdateCallDisplayRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.callInfo = $root.c2c.agentServer.CallInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.hangupCause = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SUpdateCallDisplayRequest message.
             * @function verify
             * @memberof c2c.agentServer.SUpdateCallDisplayRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SUpdateCallDisplayRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.callInfo != null && message.hasOwnProperty("callInfo")) {
                    properties._callInfo = 1;
                    {
                        let error = $root.c2c.agentServer.CallInfo.verify(message.callInfo);
                        if (error)
                            return "callInfo." + error;
                    }
                }
                if (message.hangupCause != null && message.hasOwnProperty("hangupCause")) {
                    properties._hangupCause = 1;
                    if (!$util.isString(message.hangupCause))
                        return "hangupCause: string expected";
                }
                return null;
            };

            /**
             * Gets the default type url for SUpdateCallDisplayRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.SUpdateCallDisplayRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SUpdateCallDisplayRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.SUpdateCallDisplayRequest";
            };

            return SUpdateCallDisplayRequest;
        })();

        agentServer.SUpdateCallDisplayResponse = (function() {

            /**
             * Properties of a SUpdateCallDisplayResponse.
             * @memberof c2c.agentServer
             * @interface ISUpdateCallDisplayResponse
             */

            /**
             * Constructs a new SUpdateCallDisplayResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a SUpdateCallDisplayResponse.
             * @implements ISUpdateCallDisplayResponse
             * @constructor
             * @param {c2c.agentServer.ISUpdateCallDisplayResponse=} [properties] Properties to set
             */
            function SUpdateCallDisplayResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified SUpdateCallDisplayResponse message. Does not implicitly {@link c2c.agentServer.SUpdateCallDisplayResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.SUpdateCallDisplayResponse
             * @static
             * @param {c2c.agentServer.ISUpdateCallDisplayResponse} message SUpdateCallDisplayResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SUpdateCallDisplayResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a SUpdateCallDisplayResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.SUpdateCallDisplayResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.SUpdateCallDisplayResponse} SUpdateCallDisplayResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SUpdateCallDisplayResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.SUpdateCallDisplayResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SUpdateCallDisplayResponse message.
             * @function verify
             * @memberof c2c.agentServer.SUpdateCallDisplayResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SUpdateCallDisplayResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for SUpdateCallDisplayResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.SUpdateCallDisplayResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SUpdateCallDisplayResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.SUpdateCallDisplayResponse";
            };

            return SUpdateCallDisplayResponse;
        })();

        agentServer.SUpdateCampaignRequest = (function() {

            /**
             * Properties of a SUpdateCampaignRequest.
             * @memberof c2c.agentServer
             * @interface ISUpdateCampaignRequest
             * @property {number|null} [campaignId] SUpdateCampaignRequest campaignId
             */

            /**
             * Constructs a new SUpdateCampaignRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a SUpdateCampaignRequest.
             * @implements ISUpdateCampaignRequest
             * @constructor
             * @param {c2c.agentServer.ISUpdateCampaignRequest=} [properties] Properties to set
             */
            function SUpdateCampaignRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SUpdateCampaignRequest campaignId.
             * @member {number} campaignId
             * @memberof c2c.agentServer.SUpdateCampaignRequest
             * @instance
             */
            SUpdateCampaignRequest.prototype.campaignId = 0;

            /**
             * Encodes the specified SUpdateCampaignRequest message. Does not implicitly {@link c2c.agentServer.SUpdateCampaignRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.SUpdateCampaignRequest
             * @static
             * @param {c2c.agentServer.ISUpdateCampaignRequest} message SUpdateCampaignRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SUpdateCampaignRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.campaignId);
                return writer;
            };

            /**
             * Decodes a SUpdateCampaignRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.SUpdateCampaignRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.SUpdateCampaignRequest} SUpdateCampaignRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SUpdateCampaignRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.SUpdateCampaignRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.campaignId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SUpdateCampaignRequest message.
             * @function verify
             * @memberof c2c.agentServer.SUpdateCampaignRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SUpdateCampaignRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                    if (!$util.isInteger(message.campaignId))
                        return "campaignId: integer expected";
                return null;
            };

            /**
             * Gets the default type url for SUpdateCampaignRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.SUpdateCampaignRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SUpdateCampaignRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.SUpdateCampaignRequest";
            };

            return SUpdateCampaignRequest;
        })();

        agentServer.SUpdateCampaignResponse = (function() {

            /**
             * Properties of a SUpdateCampaignResponse.
             * @memberof c2c.agentServer
             * @interface ISUpdateCampaignResponse
             */

            /**
             * Constructs a new SUpdateCampaignResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a SUpdateCampaignResponse.
             * @implements ISUpdateCampaignResponse
             * @constructor
             * @param {c2c.agentServer.ISUpdateCampaignResponse=} [properties] Properties to set
             */
            function SUpdateCampaignResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified SUpdateCampaignResponse message. Does not implicitly {@link c2c.agentServer.SUpdateCampaignResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.SUpdateCampaignResponse
             * @static
             * @param {c2c.agentServer.ISUpdateCampaignResponse} message SUpdateCampaignResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SUpdateCampaignResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a SUpdateCampaignResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.SUpdateCampaignResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.SUpdateCampaignResponse} SUpdateCampaignResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SUpdateCampaignResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.SUpdateCampaignResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SUpdateCampaignResponse message.
             * @function verify
             * @memberof c2c.agentServer.SUpdateCampaignResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SUpdateCampaignResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for SUpdateCampaignResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.SUpdateCampaignResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SUpdateCampaignResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.SUpdateCampaignResponse";
            };

            return SUpdateCampaignResponse;
        })();

        agentServer.SUpdateAgentStatusRequest = (function() {

            /**
             * Properties of a SUpdateAgentStatusRequest.
             * @memberof c2c.agentServer
             * @interface ISUpdateAgentStatusRequest
             * @property {number|null} [agentId] SUpdateAgentStatusRequest agentId
             * @property {number|null} [campaignId] SUpdateAgentStatusRequest campaignId
             * @property {boolean|null} [isConnected] SUpdateAgentStatusRequest isConnected
             * @property {boolean|null} [inCall] SUpdateAgentStatusRequest inCall
             * @property {c2c.agentServer.ICallInfo|null} [callInfo] SUpdateAgentStatusRequest callInfo
             * @property {boolean|null} [inQueue] SUpdateAgentStatusRequest inQueue
             * @property {string|null} [openReplaySid] SUpdateAgentStatusRequest openReplaySid
             */

            /**
             * Constructs a new SUpdateAgentStatusRequest.
             * @memberof c2c.agentServer
             * @classdesc Represents a SUpdateAgentStatusRequest.
             * @implements ISUpdateAgentStatusRequest
             * @constructor
             * @param {c2c.agentServer.ISUpdateAgentStatusRequest=} [properties] Properties to set
             */
            function SUpdateAgentStatusRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SUpdateAgentStatusRequest agentId.
             * @member {number} agentId
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @instance
             */
            SUpdateAgentStatusRequest.prototype.agentId = 0;

            /**
             * SUpdateAgentStatusRequest campaignId.
             * @member {number|null|undefined} campaignId
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @instance
             */
            SUpdateAgentStatusRequest.prototype.campaignId = null;

            /**
             * SUpdateAgentStatusRequest isConnected.
             * @member {boolean|null|undefined} isConnected
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @instance
             */
            SUpdateAgentStatusRequest.prototype.isConnected = null;

            /**
             * SUpdateAgentStatusRequest inCall.
             * @member {boolean|null|undefined} inCall
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @instance
             */
            SUpdateAgentStatusRequest.prototype.inCall = null;

            /**
             * SUpdateAgentStatusRequest callInfo.
             * @member {c2c.agentServer.ICallInfo|null|undefined} callInfo
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @instance
             */
            SUpdateAgentStatusRequest.prototype.callInfo = null;

            /**
             * SUpdateAgentStatusRequest inQueue.
             * @member {boolean|null|undefined} inQueue
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @instance
             */
            SUpdateAgentStatusRequest.prototype.inQueue = null;

            /**
             * SUpdateAgentStatusRequest openReplaySid.
             * @member {string|null|undefined} openReplaySid
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @instance
             */
            SUpdateAgentStatusRequest.prototype.openReplaySid = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateAgentStatusRequest.prototype, "_campaignId", {
                get: $util.oneOfGetter($oneOfFields = ["campaignId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateAgentStatusRequest.prototype, "_isConnected", {
                get: $util.oneOfGetter($oneOfFields = ["isConnected"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateAgentStatusRequest.prototype, "_inCall", {
                get: $util.oneOfGetter($oneOfFields = ["inCall"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateAgentStatusRequest.prototype, "_callInfo", {
                get: $util.oneOfGetter($oneOfFields = ["callInfo"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateAgentStatusRequest.prototype, "_inQueue", {
                get: $util.oneOfGetter($oneOfFields = ["inQueue"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SUpdateAgentStatusRequest.prototype, "_openReplaySid", {
                get: $util.oneOfGetter($oneOfFields = ["openReplaySid"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified SUpdateAgentStatusRequest message. Does not implicitly {@link c2c.agentServer.SUpdateAgentStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @static
             * @param {c2c.agentServer.ISUpdateAgentStatusRequest} message SUpdateAgentStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SUpdateAgentStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.agentId != null && Object.hasOwnProperty.call(message, "agentId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.agentId);
                if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.campaignId);
                if (message.isConnected != null && Object.hasOwnProperty.call(message, "isConnected"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isConnected);
                if (message.inCall != null && Object.hasOwnProperty.call(message, "inCall"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.inCall);
                if (message.callInfo != null && Object.hasOwnProperty.call(message, "callInfo"))
                    $root.c2c.agentServer.CallInfo.encode(message.callInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.inQueue != null && Object.hasOwnProperty.call(message, "inQueue"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.inQueue);
                if (message.openReplaySid != null && Object.hasOwnProperty.call(message, "openReplaySid"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.openReplaySid);
                return writer;
            };

            /**
             * Decodes a SUpdateAgentStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.SUpdateAgentStatusRequest} SUpdateAgentStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SUpdateAgentStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.SUpdateAgentStatusRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.agentId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.campaignId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.isConnected = reader.bool();
                            break;
                        }
                    case 4: {
                            message.inCall = reader.bool();
                            break;
                        }
                    case 5: {
                            message.callInfo = $root.c2c.agentServer.CallInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.inQueue = reader.bool();
                            break;
                        }
                    case 7: {
                            message.openReplaySid = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SUpdateAgentStatusRequest message.
             * @function verify
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SUpdateAgentStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.agentId != null && message.hasOwnProperty("agentId"))
                    if (!$util.isInteger(message.agentId))
                        return "agentId: integer expected";
                if (message.campaignId != null && message.hasOwnProperty("campaignId")) {
                    properties._campaignId = 1;
                    if (!$util.isInteger(message.campaignId))
                        return "campaignId: integer expected";
                }
                if (message.isConnected != null && message.hasOwnProperty("isConnected")) {
                    properties._isConnected = 1;
                    if (typeof message.isConnected !== "boolean")
                        return "isConnected: boolean expected";
                }
                if (message.inCall != null && message.hasOwnProperty("inCall")) {
                    properties._inCall = 1;
                    if (typeof message.inCall !== "boolean")
                        return "inCall: boolean expected";
                }
                if (message.callInfo != null && message.hasOwnProperty("callInfo")) {
                    properties._callInfo = 1;
                    {
                        let error = $root.c2c.agentServer.CallInfo.verify(message.callInfo);
                        if (error)
                            return "callInfo." + error;
                    }
                }
                if (message.inQueue != null && message.hasOwnProperty("inQueue")) {
                    properties._inQueue = 1;
                    if (typeof message.inQueue !== "boolean")
                        return "inQueue: boolean expected";
                }
                if (message.openReplaySid != null && message.hasOwnProperty("openReplaySid")) {
                    properties._openReplaySid = 1;
                    if (!$util.isString(message.openReplaySid))
                        return "openReplaySid: string expected";
                }
                return null;
            };

            /**
             * Gets the default type url for SUpdateAgentStatusRequest
             * @function getTypeUrl
             * @memberof c2c.agentServer.SUpdateAgentStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SUpdateAgentStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.SUpdateAgentStatusRequest";
            };

            return SUpdateAgentStatusRequest;
        })();

        agentServer.SUpdateAgentStatusResponse = (function() {

            /**
             * Properties of a SUpdateAgentStatusResponse.
             * @memberof c2c.agentServer
             * @interface ISUpdateAgentStatusResponse
             */

            /**
             * Constructs a new SUpdateAgentStatusResponse.
             * @memberof c2c.agentServer
             * @classdesc Represents a SUpdateAgentStatusResponse.
             * @implements ISUpdateAgentStatusResponse
             * @constructor
             * @param {c2c.agentServer.ISUpdateAgentStatusResponse=} [properties] Properties to set
             */
            function SUpdateAgentStatusResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Encodes the specified SUpdateAgentStatusResponse message. Does not implicitly {@link c2c.agentServer.SUpdateAgentStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.SUpdateAgentStatusResponse
             * @static
             * @param {c2c.agentServer.ISUpdateAgentStatusResponse} message SUpdateAgentStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SUpdateAgentStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a SUpdateAgentStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.SUpdateAgentStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.SUpdateAgentStatusResponse} SUpdateAgentStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SUpdateAgentStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.SUpdateAgentStatusResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SUpdateAgentStatusResponse message.
             * @function verify
             * @memberof c2c.agentServer.SUpdateAgentStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SUpdateAgentStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Gets the default type url for SUpdateAgentStatusResponse
             * @function getTypeUrl
             * @memberof c2c.agentServer.SUpdateAgentStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SUpdateAgentStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.SUpdateAgentStatusResponse";
            };

            return SUpdateAgentStatusResponse;
        })();

        agentServer.CMessageStream = (function() {

            /**
             * Properties of a CMessageStream.
             * @memberof c2c.agentServer
             * @interface ICMessageStream
             * @property {string|null} [requestId] CMessageStream requestId
             * @property {boolean|null} [reply] CMessageStream reply
             * @property {string|null} [error] CMessageStream error
             * @property {boolean|null} [userError] CMessageStream userError
             * @property {c2c.agentServer.ITraceContext|null} [trace] CMessageStream trace
             * @property {c2c.agentServer.IPingPong|null} [pingPong] CMessageStream pingPong
             * @property {c2c.agentServer.IByteStreamOperation|null} [byteStreamOperation] CMessageStream byteStreamOperation
             * @property {c2c.agentServer.ICGenerateSipTokenRequest|null} [cGenerateSipTokenRequest] CMessageStream cGenerateSipTokenRequest
             * @property {c2c.agentServer.ICPlaceOutboundCallRequest|null} [cPlaceOutboundCallRequest] CMessageStream cPlaceOutboundCallRequest
             * @property {c2c.agentServer.ICEndCallRequest|null} [cEndCallRequest] CMessageStream cEndCallRequest
             * @property {c2c.agentServer.ICJoinCampaignRequest|null} [cJoinCampaignRequest] CMessageStream cJoinCampaignRequest
             * @property {c2c.agentServer.ICEnterCallQueueRequest|null} [cEnterCallQueueRequest] CMessageStream cEnterCallQueueRequest
             * @property {c2c.agentServer.ICTransferCallRequest|null} [cTransferCallRequest] CMessageStream cTransferCallRequest
             * @property {c2c.agentServer.ICFetchAgentStatusRequest|null} [cFetchAgentStatusRequest] CMessageStream cFetchAgentStatusRequest
             * @property {c2c.agentServer.ICSubcribeAgentStatusRequest|null} [cSubcribeAgentStatusRequest] CMessageStream cSubcribeAgentStatusRequest
             * @property {c2c.agentServer.ICUpdateOpenReplaySidRequest|null} [cUpdateOpenReplaySidRequest] CMessageStream cUpdateOpenReplaySidRequest
             * @property {c2c.agentServer.ICHoldCallRequest|null} [cHoldCallRequest] CMessageStream cHoldCallRequest
             * @property {c2c.agentServer.ISUpdateCallDisplayResponse|null} [sUpdateCallDisplayResponse] CMessageStream sUpdateCallDisplayResponse
             * @property {c2c.agentServer.ISUpdateCampaignResponse|null} [sUpdateCampaignResponse] CMessageStream sUpdateCampaignResponse
             * @property {c2c.agentServer.ISUpdateAgentStatusResponse|null} [sUpdateAgentStatusResponse] CMessageStream sUpdateAgentStatusResponse
             */

            /**
             * Constructs a new CMessageStream.
             * @memberof c2c.agentServer
             * @classdesc Represents a CMessageStream.
             * @implements ICMessageStream
             * @constructor
             * @param {c2c.agentServer.ICMessageStream=} [properties] Properties to set
             */
            function CMessageStream(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CMessageStream requestId.
             * @member {string} requestId
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.requestId = "";

            /**
             * CMessageStream reply.
             * @member {boolean} reply
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.reply = false;

            /**
             * CMessageStream error.
             * @member {string|null|undefined} error
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.error = null;

            /**
             * CMessageStream userError.
             * @member {boolean|null|undefined} userError
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.userError = null;

            /**
             * CMessageStream trace.
             * @member {c2c.agentServer.ITraceContext|null|undefined} trace
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.trace = null;

            /**
             * CMessageStream pingPong.
             * @member {c2c.agentServer.IPingPong|null|undefined} pingPong
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.pingPong = null;

            /**
             * CMessageStream byteStreamOperation.
             * @member {c2c.agentServer.IByteStreamOperation|null|undefined} byteStreamOperation
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.byteStreamOperation = null;

            /**
             * CMessageStream cGenerateSipTokenRequest.
             * @member {c2c.agentServer.ICGenerateSipTokenRequest|null|undefined} cGenerateSipTokenRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cGenerateSipTokenRequest = null;

            /**
             * CMessageStream cPlaceOutboundCallRequest.
             * @member {c2c.agentServer.ICPlaceOutboundCallRequest|null|undefined} cPlaceOutboundCallRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cPlaceOutboundCallRequest = null;

            /**
             * CMessageStream cEndCallRequest.
             * @member {c2c.agentServer.ICEndCallRequest|null|undefined} cEndCallRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cEndCallRequest = null;

            /**
             * CMessageStream cJoinCampaignRequest.
             * @member {c2c.agentServer.ICJoinCampaignRequest|null|undefined} cJoinCampaignRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cJoinCampaignRequest = null;

            /**
             * CMessageStream cEnterCallQueueRequest.
             * @member {c2c.agentServer.ICEnterCallQueueRequest|null|undefined} cEnterCallQueueRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cEnterCallQueueRequest = null;

            /**
             * CMessageStream cTransferCallRequest.
             * @member {c2c.agentServer.ICTransferCallRequest|null|undefined} cTransferCallRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cTransferCallRequest = null;

            /**
             * CMessageStream cFetchAgentStatusRequest.
             * @member {c2c.agentServer.ICFetchAgentStatusRequest|null|undefined} cFetchAgentStatusRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cFetchAgentStatusRequest = null;

            /**
             * CMessageStream cSubcribeAgentStatusRequest.
             * @member {c2c.agentServer.ICSubcribeAgentStatusRequest|null|undefined} cSubcribeAgentStatusRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cSubcribeAgentStatusRequest = null;

            /**
             * CMessageStream cUpdateOpenReplaySidRequest.
             * @member {c2c.agentServer.ICUpdateOpenReplaySidRequest|null|undefined} cUpdateOpenReplaySidRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cUpdateOpenReplaySidRequest = null;

            /**
             * CMessageStream cHoldCallRequest.
             * @member {c2c.agentServer.ICHoldCallRequest|null|undefined} cHoldCallRequest
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.cHoldCallRequest = null;

            /**
             * CMessageStream sUpdateCallDisplayResponse.
             * @member {c2c.agentServer.ISUpdateCallDisplayResponse|null|undefined} sUpdateCallDisplayResponse
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.sUpdateCallDisplayResponse = null;

            /**
             * CMessageStream sUpdateCampaignResponse.
             * @member {c2c.agentServer.ISUpdateCampaignResponse|null|undefined} sUpdateCampaignResponse
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.sUpdateCampaignResponse = null;

            /**
             * CMessageStream sUpdateAgentStatusResponse.
             * @member {c2c.agentServer.ISUpdateAgentStatusResponse|null|undefined} sUpdateAgentStatusResponse
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            CMessageStream.prototype.sUpdateAgentStatusResponse = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CMessageStream.prototype, "_error", {
                get: $util.oneOfGetter($oneOfFields = ["error"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CMessageStream.prototype, "_userError", {
                get: $util.oneOfGetter($oneOfFields = ["userError"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(CMessageStream.prototype, "_trace", {
                get: $util.oneOfGetter($oneOfFields = ["trace"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CMessageStream payload.
             * @member {"pingPong"|"byteStreamOperation"|"cGenerateSipTokenRequest"|"cPlaceOutboundCallRequest"|"cEndCallRequest"|"cJoinCampaignRequest"|"cEnterCallQueueRequest"|"cTransferCallRequest"|"cFetchAgentStatusRequest"|"cSubcribeAgentStatusRequest"|"cUpdateOpenReplaySidRequest"|"cHoldCallRequest"|"sUpdateCallDisplayResponse"|"sUpdateCampaignResponse"|"sUpdateAgentStatusResponse"|undefined} payload
             * @memberof c2c.agentServer.CMessageStream
             * @instance
             */
            Object.defineProperty(CMessageStream.prototype, "payload", {
                get: $util.oneOfGetter($oneOfFields = ["pingPong", "byteStreamOperation", "cGenerateSipTokenRequest", "cPlaceOutboundCallRequest", "cEndCallRequest", "cJoinCampaignRequest", "cEnterCallQueueRequest", "cTransferCallRequest", "cFetchAgentStatusRequest", "cSubcribeAgentStatusRequest", "cUpdateOpenReplaySidRequest", "cHoldCallRequest", "sUpdateCallDisplayResponse", "sUpdateCampaignResponse", "sUpdateAgentStatusResponse"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified CMessageStream message. Does not implicitly {@link c2c.agentServer.CMessageStream.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.CMessageStream
             * @static
             * @param {c2c.agentServer.ICMessageStream} message CMessageStream message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CMessageStream.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                if (message.reply != null && Object.hasOwnProperty.call(message, "reply"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.reply);
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
                if (message.userError != null && Object.hasOwnProperty.call(message, "userError"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.userError);
                if (message.trace != null && Object.hasOwnProperty.call(message, "trace"))
                    $root.c2c.agentServer.TraceContext.encode(message.trace, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.pingPong != null && Object.hasOwnProperty.call(message, "pingPong"))
                    $root.c2c.agentServer.PingPong.encode(message.pingPong, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.byteStreamOperation != null && Object.hasOwnProperty.call(message, "byteStreamOperation"))
                    $root.c2c.agentServer.ByteStreamOperation.encode(message.byteStreamOperation, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                if (message.cGenerateSipTokenRequest != null && Object.hasOwnProperty.call(message, "cGenerateSipTokenRequest"))
                    $root.c2c.agentServer.CGenerateSipTokenRequest.encode(message.cGenerateSipTokenRequest, writer.uint32(/* id 1000, wireType 2 =*/8002).fork()).ldelim();
                if (message.cPlaceOutboundCallRequest != null && Object.hasOwnProperty.call(message, "cPlaceOutboundCallRequest"))
                    $root.c2c.agentServer.CPlaceOutboundCallRequest.encode(message.cPlaceOutboundCallRequest, writer.uint32(/* id 1001, wireType 2 =*/8010).fork()).ldelim();
                if (message.cEndCallRequest != null && Object.hasOwnProperty.call(message, "cEndCallRequest"))
                    $root.c2c.agentServer.CEndCallRequest.encode(message.cEndCallRequest, writer.uint32(/* id 1002, wireType 2 =*/8018).fork()).ldelim();
                if (message.cJoinCampaignRequest != null && Object.hasOwnProperty.call(message, "cJoinCampaignRequest"))
                    $root.c2c.agentServer.CJoinCampaignRequest.encode(message.cJoinCampaignRequest, writer.uint32(/* id 1003, wireType 2 =*/8026).fork()).ldelim();
                if (message.cEnterCallQueueRequest != null && Object.hasOwnProperty.call(message, "cEnterCallQueueRequest"))
                    $root.c2c.agentServer.CEnterCallQueueRequest.encode(message.cEnterCallQueueRequest, writer.uint32(/* id 1004, wireType 2 =*/8034).fork()).ldelim();
                if (message.cTransferCallRequest != null && Object.hasOwnProperty.call(message, "cTransferCallRequest"))
                    $root.c2c.agentServer.CTransferCallRequest.encode(message.cTransferCallRequest, writer.uint32(/* id 1005, wireType 2 =*/8042).fork()).ldelim();
                if (message.cFetchAgentStatusRequest != null && Object.hasOwnProperty.call(message, "cFetchAgentStatusRequest"))
                    $root.c2c.agentServer.CFetchAgentStatusRequest.encode(message.cFetchAgentStatusRequest, writer.uint32(/* id 1006, wireType 2 =*/8050).fork()).ldelim();
                if (message.cSubcribeAgentStatusRequest != null && Object.hasOwnProperty.call(message, "cSubcribeAgentStatusRequest"))
                    $root.c2c.agentServer.CSubcribeAgentStatusRequest.encode(message.cSubcribeAgentStatusRequest, writer.uint32(/* id 1007, wireType 2 =*/8058).fork()).ldelim();
                if (message.cUpdateOpenReplaySidRequest != null && Object.hasOwnProperty.call(message, "cUpdateOpenReplaySidRequest"))
                    $root.c2c.agentServer.CUpdateOpenReplaySidRequest.encode(message.cUpdateOpenReplaySidRequest, writer.uint32(/* id 1008, wireType 2 =*/8066).fork()).ldelim();
                if (message.cHoldCallRequest != null && Object.hasOwnProperty.call(message, "cHoldCallRequest"))
                    $root.c2c.agentServer.CHoldCallRequest.encode(message.cHoldCallRequest, writer.uint32(/* id 1009, wireType 2 =*/8074).fork()).ldelim();
                if (message.sUpdateCallDisplayResponse != null && Object.hasOwnProperty.call(message, "sUpdateCallDisplayResponse"))
                    $root.c2c.agentServer.SUpdateCallDisplayResponse.encode(message.sUpdateCallDisplayResponse, writer.uint32(/* id 2000, wireType 2 =*/16002).fork()).ldelim();
                if (message.sUpdateCampaignResponse != null && Object.hasOwnProperty.call(message, "sUpdateCampaignResponse"))
                    $root.c2c.agentServer.SUpdateCampaignResponse.encode(message.sUpdateCampaignResponse, writer.uint32(/* id 2001, wireType 2 =*/16010).fork()).ldelim();
                if (message.sUpdateAgentStatusResponse != null && Object.hasOwnProperty.call(message, "sUpdateAgentStatusResponse"))
                    $root.c2c.agentServer.SUpdateAgentStatusResponse.encode(message.sUpdateAgentStatusResponse, writer.uint32(/* id 2002, wireType 2 =*/16018).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a CMessageStream message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.CMessageStream
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.CMessageStream} CMessageStream
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CMessageStream.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.CMessageStream();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.requestId = reader.string();
                            break;
                        }
                    case 2: {
                            message.reply = reader.bool();
                            break;
                        }
                    case 3: {
                            message.error = reader.string();
                            break;
                        }
                    case 4: {
                            message.userError = reader.bool();
                            break;
                        }
                    case 5: {
                            message.trace = $root.c2c.agentServer.TraceContext.decode(reader, reader.uint32());
                            break;
                        }
                    case 50: {
                            message.pingPong = $root.c2c.agentServer.PingPong.decode(reader, reader.uint32());
                            break;
                        }
                    case 51: {
                            message.byteStreamOperation = $root.c2c.agentServer.ByteStreamOperation.decode(reader, reader.uint32());
                            break;
                        }
                    case 1000: {
                            message.cGenerateSipTokenRequest = $root.c2c.agentServer.CGenerateSipTokenRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1001: {
                            message.cPlaceOutboundCallRequest = $root.c2c.agentServer.CPlaceOutboundCallRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1002: {
                            message.cEndCallRequest = $root.c2c.agentServer.CEndCallRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1003: {
                            message.cJoinCampaignRequest = $root.c2c.agentServer.CJoinCampaignRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1004: {
                            message.cEnterCallQueueRequest = $root.c2c.agentServer.CEnterCallQueueRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1005: {
                            message.cTransferCallRequest = $root.c2c.agentServer.CTransferCallRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1006: {
                            message.cFetchAgentStatusRequest = $root.c2c.agentServer.CFetchAgentStatusRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1007: {
                            message.cSubcribeAgentStatusRequest = $root.c2c.agentServer.CSubcribeAgentStatusRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1008: {
                            message.cUpdateOpenReplaySidRequest = $root.c2c.agentServer.CUpdateOpenReplaySidRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 1009: {
                            message.cHoldCallRequest = $root.c2c.agentServer.CHoldCallRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2000: {
                            message.sUpdateCallDisplayResponse = $root.c2c.agentServer.SUpdateCallDisplayResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2001: {
                            message.sUpdateCampaignResponse = $root.c2c.agentServer.SUpdateCampaignResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2002: {
                            message.sUpdateAgentStatusResponse = $root.c2c.agentServer.SUpdateAgentStatusResponse.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CMessageStream message.
             * @function verify
             * @memberof c2c.agentServer.CMessageStream
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CMessageStream.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isString(message.requestId))
                        return "requestId: string expected";
                if (message.reply != null && message.hasOwnProperty("reply"))
                    if (typeof message.reply !== "boolean")
                        return "reply: boolean expected";
                if (message.error != null && message.hasOwnProperty("error")) {
                    properties._error = 1;
                    if (!$util.isString(message.error))
                        return "error: string expected";
                }
                if (message.userError != null && message.hasOwnProperty("userError")) {
                    properties._userError = 1;
                    if (typeof message.userError !== "boolean")
                        return "userError: boolean expected";
                }
                if (message.trace != null && message.hasOwnProperty("trace")) {
                    properties._trace = 1;
                    {
                        let error = $root.c2c.agentServer.TraceContext.verify(message.trace);
                        if (error)
                            return "trace." + error;
                    }
                }
                if (message.pingPong != null && message.hasOwnProperty("pingPong")) {
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.PingPong.verify(message.pingPong);
                        if (error)
                            return "pingPong." + error;
                    }
                }
                if (message.byteStreamOperation != null && message.hasOwnProperty("byteStreamOperation")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.ByteStreamOperation.verify(message.byteStreamOperation);
                        if (error)
                            return "byteStreamOperation." + error;
                    }
                }
                if (message.cGenerateSipTokenRequest != null && message.hasOwnProperty("cGenerateSipTokenRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CGenerateSipTokenRequest.verify(message.cGenerateSipTokenRequest);
                        if (error)
                            return "cGenerateSipTokenRequest." + error;
                    }
                }
                if (message.cPlaceOutboundCallRequest != null && message.hasOwnProperty("cPlaceOutboundCallRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CPlaceOutboundCallRequest.verify(message.cPlaceOutboundCallRequest);
                        if (error)
                            return "cPlaceOutboundCallRequest." + error;
                    }
                }
                if (message.cEndCallRequest != null && message.hasOwnProperty("cEndCallRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CEndCallRequest.verify(message.cEndCallRequest);
                        if (error)
                            return "cEndCallRequest." + error;
                    }
                }
                if (message.cJoinCampaignRequest != null && message.hasOwnProperty("cJoinCampaignRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CJoinCampaignRequest.verify(message.cJoinCampaignRequest);
                        if (error)
                            return "cJoinCampaignRequest." + error;
                    }
                }
                if (message.cEnterCallQueueRequest != null && message.hasOwnProperty("cEnterCallQueueRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CEnterCallQueueRequest.verify(message.cEnterCallQueueRequest);
                        if (error)
                            return "cEnterCallQueueRequest." + error;
                    }
                }
                if (message.cTransferCallRequest != null && message.hasOwnProperty("cTransferCallRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CTransferCallRequest.verify(message.cTransferCallRequest);
                        if (error)
                            return "cTransferCallRequest." + error;
                    }
                }
                if (message.cFetchAgentStatusRequest != null && message.hasOwnProperty("cFetchAgentStatusRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CFetchAgentStatusRequest.verify(message.cFetchAgentStatusRequest);
                        if (error)
                            return "cFetchAgentStatusRequest." + error;
                    }
                }
                if (message.cSubcribeAgentStatusRequest != null && message.hasOwnProperty("cSubcribeAgentStatusRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CSubcribeAgentStatusRequest.verify(message.cSubcribeAgentStatusRequest);
                        if (error)
                            return "cSubcribeAgentStatusRequest." + error;
                    }
                }
                if (message.cUpdateOpenReplaySidRequest != null && message.hasOwnProperty("cUpdateOpenReplaySidRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CUpdateOpenReplaySidRequest.verify(message.cUpdateOpenReplaySidRequest);
                        if (error)
                            return "cUpdateOpenReplaySidRequest." + error;
                    }
                }
                if (message.cHoldCallRequest != null && message.hasOwnProperty("cHoldCallRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CHoldCallRequest.verify(message.cHoldCallRequest);
                        if (error)
                            return "cHoldCallRequest." + error;
                    }
                }
                if (message.sUpdateCallDisplayResponse != null && message.hasOwnProperty("sUpdateCallDisplayResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.SUpdateCallDisplayResponse.verify(message.sUpdateCallDisplayResponse);
                        if (error)
                            return "sUpdateCallDisplayResponse." + error;
                    }
                }
                if (message.sUpdateCampaignResponse != null && message.hasOwnProperty("sUpdateCampaignResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.SUpdateCampaignResponse.verify(message.sUpdateCampaignResponse);
                        if (error)
                            return "sUpdateCampaignResponse." + error;
                    }
                }
                if (message.sUpdateAgentStatusResponse != null && message.hasOwnProperty("sUpdateAgentStatusResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.SUpdateAgentStatusResponse.verify(message.sUpdateAgentStatusResponse);
                        if (error)
                            return "sUpdateAgentStatusResponse." + error;
                    }
                }
                return null;
            };

            /**
             * Gets the default type url for CMessageStream
             * @function getTypeUrl
             * @memberof c2c.agentServer.CMessageStream
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CMessageStream.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.CMessageStream";
            };

            return CMessageStream;
        })();

        agentServer.SMessageStream = (function() {

            /**
             * Properties of a SMessageStream.
             * @memberof c2c.agentServer
             * @interface ISMessageStream
             * @property {string|null} [requestId] SMessageStream requestId
             * @property {boolean|null} [reply] SMessageStream reply
             * @property {string|null} [error] SMessageStream error
             * @property {boolean|null} [userError] SMessageStream userError
             * @property {c2c.agentServer.ITraceContext|null} [trace] SMessageStream trace
             * @property {c2c.agentServer.IPingPong|null} [pingPong] SMessageStream pingPong
             * @property {c2c.agentServer.IByteStreamOperation|null} [byteStreamOperation] SMessageStream byteStreamOperation
             * @property {c2c.agentServer.ICGenerateSipTokenResponse|null} [cGenerateSipTokenResponse] SMessageStream cGenerateSipTokenResponse
             * @property {c2c.agentServer.ICPlaceOutboundCallResponse|null} [cPlaceOutboundCallResponse] SMessageStream cPlaceOutboundCallResponse
             * @property {c2c.agentServer.ICEndCallResponse|null} [cEndCallResponse] SMessageStream cEndCallResponse
             * @property {c2c.agentServer.ICJoinCampaignResponse|null} [cJoinCampaignResponse] SMessageStream cJoinCampaignResponse
             * @property {c2c.agentServer.ICEnterCallQueueResponse|null} [cEnterCallQueueResponse] SMessageStream cEnterCallQueueResponse
             * @property {c2c.agentServer.ICTransferCallResponse|null} [cTransferCallResponse] SMessageStream cTransferCallResponse
             * @property {c2c.agentServer.ICFetchAgentStatusResponse|null} [cFetchAgentStatusResponse] SMessageStream cFetchAgentStatusResponse
             * @property {c2c.agentServer.ICSubcribeAgentStatusResponse|null} [cSubcribeAgentStatusResponse] SMessageStream cSubcribeAgentStatusResponse
             * @property {c2c.agentServer.ICUpdateOpenReplaySidResponse|null} [cUpdateOpenReplaySidResponse] SMessageStream cUpdateOpenReplaySidResponse
             * @property {c2c.agentServer.ICHoldCallResponse|null} [cHoldCallResponse] SMessageStream cHoldCallResponse
             * @property {c2c.agentServer.ISUpdateCallDisplayRequest|null} [sUpdateCallDisplayRequest] SMessageStream sUpdateCallDisplayRequest
             * @property {c2c.agentServer.ISUpdateCampaignRequest|null} [sUpdateCampaignRequest] SMessageStream sUpdateCampaignRequest
             * @property {c2c.agentServer.ISUpdateAgentStatusRequest|null} [sUpdateAgentStatusRequest] SMessageStream sUpdateAgentStatusRequest
             */

            /**
             * Constructs a new SMessageStream.
             * @memberof c2c.agentServer
             * @classdesc Represents a SMessageStream.
             * @implements ISMessageStream
             * @constructor
             * @param {c2c.agentServer.ISMessageStream=} [properties] Properties to set
             */
            function SMessageStream(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SMessageStream requestId.
             * @member {string} requestId
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.requestId = "";

            /**
             * SMessageStream reply.
             * @member {boolean} reply
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.reply = false;

            /**
             * SMessageStream error.
             * @member {string|null|undefined} error
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.error = null;

            /**
             * SMessageStream userError.
             * @member {boolean|null|undefined} userError
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.userError = null;

            /**
             * SMessageStream trace.
             * @member {c2c.agentServer.ITraceContext|null|undefined} trace
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.trace = null;

            /**
             * SMessageStream pingPong.
             * @member {c2c.agentServer.IPingPong|null|undefined} pingPong
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.pingPong = null;

            /**
             * SMessageStream byteStreamOperation.
             * @member {c2c.agentServer.IByteStreamOperation|null|undefined} byteStreamOperation
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.byteStreamOperation = null;

            /**
             * SMessageStream cGenerateSipTokenResponse.
             * @member {c2c.agentServer.ICGenerateSipTokenResponse|null|undefined} cGenerateSipTokenResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cGenerateSipTokenResponse = null;

            /**
             * SMessageStream cPlaceOutboundCallResponse.
             * @member {c2c.agentServer.ICPlaceOutboundCallResponse|null|undefined} cPlaceOutboundCallResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cPlaceOutboundCallResponse = null;

            /**
             * SMessageStream cEndCallResponse.
             * @member {c2c.agentServer.ICEndCallResponse|null|undefined} cEndCallResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cEndCallResponse = null;

            /**
             * SMessageStream cJoinCampaignResponse.
             * @member {c2c.agentServer.ICJoinCampaignResponse|null|undefined} cJoinCampaignResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cJoinCampaignResponse = null;

            /**
             * SMessageStream cEnterCallQueueResponse.
             * @member {c2c.agentServer.ICEnterCallQueueResponse|null|undefined} cEnterCallQueueResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cEnterCallQueueResponse = null;

            /**
             * SMessageStream cTransferCallResponse.
             * @member {c2c.agentServer.ICTransferCallResponse|null|undefined} cTransferCallResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cTransferCallResponse = null;

            /**
             * SMessageStream cFetchAgentStatusResponse.
             * @member {c2c.agentServer.ICFetchAgentStatusResponse|null|undefined} cFetchAgentStatusResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cFetchAgentStatusResponse = null;

            /**
             * SMessageStream cSubcribeAgentStatusResponse.
             * @member {c2c.agentServer.ICSubcribeAgentStatusResponse|null|undefined} cSubcribeAgentStatusResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cSubcribeAgentStatusResponse = null;

            /**
             * SMessageStream cUpdateOpenReplaySidResponse.
             * @member {c2c.agentServer.ICUpdateOpenReplaySidResponse|null|undefined} cUpdateOpenReplaySidResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cUpdateOpenReplaySidResponse = null;

            /**
             * SMessageStream cHoldCallResponse.
             * @member {c2c.agentServer.ICHoldCallResponse|null|undefined} cHoldCallResponse
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.cHoldCallResponse = null;

            /**
             * SMessageStream sUpdateCallDisplayRequest.
             * @member {c2c.agentServer.ISUpdateCallDisplayRequest|null|undefined} sUpdateCallDisplayRequest
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.sUpdateCallDisplayRequest = null;

            /**
             * SMessageStream sUpdateCampaignRequest.
             * @member {c2c.agentServer.ISUpdateCampaignRequest|null|undefined} sUpdateCampaignRequest
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.sUpdateCampaignRequest = null;

            /**
             * SMessageStream sUpdateAgentStatusRequest.
             * @member {c2c.agentServer.ISUpdateAgentStatusRequest|null|undefined} sUpdateAgentStatusRequest
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            SMessageStream.prototype.sUpdateAgentStatusRequest = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SMessageStream.prototype, "_error", {
                get: $util.oneOfGetter($oneOfFields = ["error"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SMessageStream.prototype, "_userError", {
                get: $util.oneOfGetter($oneOfFields = ["userError"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            // Virtual OneOf for proto3 optional field
            Object.defineProperty(SMessageStream.prototype, "_trace", {
                get: $util.oneOfGetter($oneOfFields = ["trace"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * SMessageStream payload.
             * @member {"pingPong"|"byteStreamOperation"|"cGenerateSipTokenResponse"|"cPlaceOutboundCallResponse"|"cEndCallResponse"|"cJoinCampaignResponse"|"cEnterCallQueueResponse"|"cTransferCallResponse"|"cFetchAgentStatusResponse"|"cSubcribeAgentStatusResponse"|"cUpdateOpenReplaySidResponse"|"cHoldCallResponse"|"sUpdateCallDisplayRequest"|"sUpdateCampaignRequest"|"sUpdateAgentStatusRequest"|undefined} payload
             * @memberof c2c.agentServer.SMessageStream
             * @instance
             */
            Object.defineProperty(SMessageStream.prototype, "payload", {
                get: $util.oneOfGetter($oneOfFields = ["pingPong", "byteStreamOperation", "cGenerateSipTokenResponse", "cPlaceOutboundCallResponse", "cEndCallResponse", "cJoinCampaignResponse", "cEnterCallQueueResponse", "cTransferCallResponse", "cFetchAgentStatusResponse", "cSubcribeAgentStatusResponse", "cUpdateOpenReplaySidResponse", "cHoldCallResponse", "sUpdateCallDisplayRequest", "sUpdateCampaignRequest", "sUpdateAgentStatusRequest"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified SMessageStream message. Does not implicitly {@link c2c.agentServer.SMessageStream.verify|verify} messages.
             * @function encode
             * @memberof c2c.agentServer.SMessageStream
             * @static
             * @param {c2c.agentServer.ISMessageStream} message SMessageStream message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SMessageStream.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.requestId);
                if (message.reply != null && Object.hasOwnProperty.call(message, "reply"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.reply);
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
                if (message.userError != null && Object.hasOwnProperty.call(message, "userError"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.userError);
                if (message.trace != null && Object.hasOwnProperty.call(message, "trace"))
                    $root.c2c.agentServer.TraceContext.encode(message.trace, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.pingPong != null && Object.hasOwnProperty.call(message, "pingPong"))
                    $root.c2c.agentServer.PingPong.encode(message.pingPong, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.byteStreamOperation != null && Object.hasOwnProperty.call(message, "byteStreamOperation"))
                    $root.c2c.agentServer.ByteStreamOperation.encode(message.byteStreamOperation, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                if (message.cGenerateSipTokenResponse != null && Object.hasOwnProperty.call(message, "cGenerateSipTokenResponse"))
                    $root.c2c.agentServer.CGenerateSipTokenResponse.encode(message.cGenerateSipTokenResponse, writer.uint32(/* id 1000, wireType 2 =*/8002).fork()).ldelim();
                if (message.cPlaceOutboundCallResponse != null && Object.hasOwnProperty.call(message, "cPlaceOutboundCallResponse"))
                    $root.c2c.agentServer.CPlaceOutboundCallResponse.encode(message.cPlaceOutboundCallResponse, writer.uint32(/* id 1001, wireType 2 =*/8010).fork()).ldelim();
                if (message.cEndCallResponse != null && Object.hasOwnProperty.call(message, "cEndCallResponse"))
                    $root.c2c.agentServer.CEndCallResponse.encode(message.cEndCallResponse, writer.uint32(/* id 1002, wireType 2 =*/8018).fork()).ldelim();
                if (message.cJoinCampaignResponse != null && Object.hasOwnProperty.call(message, "cJoinCampaignResponse"))
                    $root.c2c.agentServer.CJoinCampaignResponse.encode(message.cJoinCampaignResponse, writer.uint32(/* id 1003, wireType 2 =*/8026).fork()).ldelim();
                if (message.cEnterCallQueueResponse != null && Object.hasOwnProperty.call(message, "cEnterCallQueueResponse"))
                    $root.c2c.agentServer.CEnterCallQueueResponse.encode(message.cEnterCallQueueResponse, writer.uint32(/* id 1004, wireType 2 =*/8034).fork()).ldelim();
                if (message.cTransferCallResponse != null && Object.hasOwnProperty.call(message, "cTransferCallResponse"))
                    $root.c2c.agentServer.CTransferCallResponse.encode(message.cTransferCallResponse, writer.uint32(/* id 1005, wireType 2 =*/8042).fork()).ldelim();
                if (message.cFetchAgentStatusResponse != null && Object.hasOwnProperty.call(message, "cFetchAgentStatusResponse"))
                    $root.c2c.agentServer.CFetchAgentStatusResponse.encode(message.cFetchAgentStatusResponse, writer.uint32(/* id 1006, wireType 2 =*/8050).fork()).ldelim();
                if (message.cSubcribeAgentStatusResponse != null && Object.hasOwnProperty.call(message, "cSubcribeAgentStatusResponse"))
                    $root.c2c.agentServer.CSubcribeAgentStatusResponse.encode(message.cSubcribeAgentStatusResponse, writer.uint32(/* id 1007, wireType 2 =*/8058).fork()).ldelim();
                if (message.cUpdateOpenReplaySidResponse != null && Object.hasOwnProperty.call(message, "cUpdateOpenReplaySidResponse"))
                    $root.c2c.agentServer.CUpdateOpenReplaySidResponse.encode(message.cUpdateOpenReplaySidResponse, writer.uint32(/* id 1008, wireType 2 =*/8066).fork()).ldelim();
                if (message.cHoldCallResponse != null && Object.hasOwnProperty.call(message, "cHoldCallResponse"))
                    $root.c2c.agentServer.CHoldCallResponse.encode(message.cHoldCallResponse, writer.uint32(/* id 1009, wireType 2 =*/8074).fork()).ldelim();
                if (message.sUpdateCallDisplayRequest != null && Object.hasOwnProperty.call(message, "sUpdateCallDisplayRequest"))
                    $root.c2c.agentServer.SUpdateCallDisplayRequest.encode(message.sUpdateCallDisplayRequest, writer.uint32(/* id 2000, wireType 2 =*/16002).fork()).ldelim();
                if (message.sUpdateCampaignRequest != null && Object.hasOwnProperty.call(message, "sUpdateCampaignRequest"))
                    $root.c2c.agentServer.SUpdateCampaignRequest.encode(message.sUpdateCampaignRequest, writer.uint32(/* id 2001, wireType 2 =*/16010).fork()).ldelim();
                if (message.sUpdateAgentStatusRequest != null && Object.hasOwnProperty.call(message, "sUpdateAgentStatusRequest"))
                    $root.c2c.agentServer.SUpdateAgentStatusRequest.encode(message.sUpdateAgentStatusRequest, writer.uint32(/* id 2002, wireType 2 =*/16018).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SMessageStream message from the specified reader or buffer.
             * @function decode
             * @memberof c2c.agentServer.SMessageStream
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {c2c.agentServer.SMessageStream} SMessageStream
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SMessageStream.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.c2c.agentServer.SMessageStream();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.requestId = reader.string();
                            break;
                        }
                    case 2: {
                            message.reply = reader.bool();
                            break;
                        }
                    case 3: {
                            message.error = reader.string();
                            break;
                        }
                    case 4: {
                            message.userError = reader.bool();
                            break;
                        }
                    case 5: {
                            message.trace = $root.c2c.agentServer.TraceContext.decode(reader, reader.uint32());
                            break;
                        }
                    case 50: {
                            message.pingPong = $root.c2c.agentServer.PingPong.decode(reader, reader.uint32());
                            break;
                        }
                    case 51: {
                            message.byteStreamOperation = $root.c2c.agentServer.ByteStreamOperation.decode(reader, reader.uint32());
                            break;
                        }
                    case 1000: {
                            message.cGenerateSipTokenResponse = $root.c2c.agentServer.CGenerateSipTokenResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1001: {
                            message.cPlaceOutboundCallResponse = $root.c2c.agentServer.CPlaceOutboundCallResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1002: {
                            message.cEndCallResponse = $root.c2c.agentServer.CEndCallResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1003: {
                            message.cJoinCampaignResponse = $root.c2c.agentServer.CJoinCampaignResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1004: {
                            message.cEnterCallQueueResponse = $root.c2c.agentServer.CEnterCallQueueResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1005: {
                            message.cTransferCallResponse = $root.c2c.agentServer.CTransferCallResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1006: {
                            message.cFetchAgentStatusResponse = $root.c2c.agentServer.CFetchAgentStatusResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1007: {
                            message.cSubcribeAgentStatusResponse = $root.c2c.agentServer.CSubcribeAgentStatusResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1008: {
                            message.cUpdateOpenReplaySidResponse = $root.c2c.agentServer.CUpdateOpenReplaySidResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 1009: {
                            message.cHoldCallResponse = $root.c2c.agentServer.CHoldCallResponse.decode(reader, reader.uint32());
                            break;
                        }
                    case 2000: {
                            message.sUpdateCallDisplayRequest = $root.c2c.agentServer.SUpdateCallDisplayRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2001: {
                            message.sUpdateCampaignRequest = $root.c2c.agentServer.SUpdateCampaignRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2002: {
                            message.sUpdateAgentStatusRequest = $root.c2c.agentServer.SUpdateAgentStatusRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SMessageStream message.
             * @function verify
             * @memberof c2c.agentServer.SMessageStream
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SMessageStream.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isString(message.requestId))
                        return "requestId: string expected";
                if (message.reply != null && message.hasOwnProperty("reply"))
                    if (typeof message.reply !== "boolean")
                        return "reply: boolean expected";
                if (message.error != null && message.hasOwnProperty("error")) {
                    properties._error = 1;
                    if (!$util.isString(message.error))
                        return "error: string expected";
                }
                if (message.userError != null && message.hasOwnProperty("userError")) {
                    properties._userError = 1;
                    if (typeof message.userError !== "boolean")
                        return "userError: boolean expected";
                }
                if (message.trace != null && message.hasOwnProperty("trace")) {
                    properties._trace = 1;
                    {
                        let error = $root.c2c.agentServer.TraceContext.verify(message.trace);
                        if (error)
                            return "trace." + error;
                    }
                }
                if (message.pingPong != null && message.hasOwnProperty("pingPong")) {
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.PingPong.verify(message.pingPong);
                        if (error)
                            return "pingPong." + error;
                    }
                }
                if (message.byteStreamOperation != null && message.hasOwnProperty("byteStreamOperation")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.ByteStreamOperation.verify(message.byteStreamOperation);
                        if (error)
                            return "byteStreamOperation." + error;
                    }
                }
                if (message.cGenerateSipTokenResponse != null && message.hasOwnProperty("cGenerateSipTokenResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CGenerateSipTokenResponse.verify(message.cGenerateSipTokenResponse);
                        if (error)
                            return "cGenerateSipTokenResponse." + error;
                    }
                }
                if (message.cPlaceOutboundCallResponse != null && message.hasOwnProperty("cPlaceOutboundCallResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CPlaceOutboundCallResponse.verify(message.cPlaceOutboundCallResponse);
                        if (error)
                            return "cPlaceOutboundCallResponse." + error;
                    }
                }
                if (message.cEndCallResponse != null && message.hasOwnProperty("cEndCallResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CEndCallResponse.verify(message.cEndCallResponse);
                        if (error)
                            return "cEndCallResponse." + error;
                    }
                }
                if (message.cJoinCampaignResponse != null && message.hasOwnProperty("cJoinCampaignResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CJoinCampaignResponse.verify(message.cJoinCampaignResponse);
                        if (error)
                            return "cJoinCampaignResponse." + error;
                    }
                }
                if (message.cEnterCallQueueResponse != null && message.hasOwnProperty("cEnterCallQueueResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CEnterCallQueueResponse.verify(message.cEnterCallQueueResponse);
                        if (error)
                            return "cEnterCallQueueResponse." + error;
                    }
                }
                if (message.cTransferCallResponse != null && message.hasOwnProperty("cTransferCallResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CTransferCallResponse.verify(message.cTransferCallResponse);
                        if (error)
                            return "cTransferCallResponse." + error;
                    }
                }
                if (message.cFetchAgentStatusResponse != null && message.hasOwnProperty("cFetchAgentStatusResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CFetchAgentStatusResponse.verify(message.cFetchAgentStatusResponse);
                        if (error)
                            return "cFetchAgentStatusResponse." + error;
                    }
                }
                if (message.cSubcribeAgentStatusResponse != null && message.hasOwnProperty("cSubcribeAgentStatusResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CSubcribeAgentStatusResponse.verify(message.cSubcribeAgentStatusResponse);
                        if (error)
                            return "cSubcribeAgentStatusResponse." + error;
                    }
                }
                if (message.cUpdateOpenReplaySidResponse != null && message.hasOwnProperty("cUpdateOpenReplaySidResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CUpdateOpenReplaySidResponse.verify(message.cUpdateOpenReplaySidResponse);
                        if (error)
                            return "cUpdateOpenReplaySidResponse." + error;
                    }
                }
                if (message.cHoldCallResponse != null && message.hasOwnProperty("cHoldCallResponse")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.CHoldCallResponse.verify(message.cHoldCallResponse);
                        if (error)
                            return "cHoldCallResponse." + error;
                    }
                }
                if (message.sUpdateCallDisplayRequest != null && message.hasOwnProperty("sUpdateCallDisplayRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.SUpdateCallDisplayRequest.verify(message.sUpdateCallDisplayRequest);
                        if (error)
                            return "sUpdateCallDisplayRequest." + error;
                    }
                }
                if (message.sUpdateCampaignRequest != null && message.hasOwnProperty("sUpdateCampaignRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.SUpdateCampaignRequest.verify(message.sUpdateCampaignRequest);
                        if (error)
                            return "sUpdateCampaignRequest." + error;
                    }
                }
                if (message.sUpdateAgentStatusRequest != null && message.hasOwnProperty("sUpdateAgentStatusRequest")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.c2c.agentServer.SUpdateAgentStatusRequest.verify(message.sUpdateAgentStatusRequest);
                        if (error)
                            return "sUpdateAgentStatusRequest." + error;
                    }
                }
                return null;
            };

            /**
             * Gets the default type url for SMessageStream
             * @function getTypeUrl
             * @memberof c2c.agentServer.SMessageStream
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SMessageStream.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/c2c.agentServer.SMessageStream";
            };

            return SMessageStream;
        })();

        return agentServer;
    })();

    return c2c;
})();

export { $root as default };
