<template>
    <Debtor v-if="store.activeDebtor" :key="store.activeDebtor.fileNumber" />

    <keep-alive>
        <Search v-if="!store.activeDebtor" />
    </keep-alive>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

import { type IDebtorInfo } from '@/generated/openapi-client';
import { useStore } from '@/store';

import Debtor from './main/debtor.vue';
import Search from './main/search.vue';

const store = useStore();
const selectedDebtor = ref<IDebtorInfo>();

watch(
    () => store.activeCall,
    () => {
        if (store.activeCampaignId && store.activeCall) {
            selectedDebtor.value = undefined;
        }
    }
);
</script>

<style lang="scss" scoped></style>
