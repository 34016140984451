<template>
    <div id="phone-outbound">
        <Switcher
            v-model="mode"
            class="switcher"
            :options="{ keypad: { icon: 'fa fa-grid-round', tip: 'Keypad' }, recent: { icon: 'fa fa-phone-missed', tip: 'Recent Calls' } }"
        />
        <OutboundKeypad v-if="mode === 'keypad'" />
        <Recent v-else-if="mode === 'recent'" :dial="dialRecent" />
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import type { IRecentCallResponse } from '@/generated/openapi-client';
import Switcher from '@/shared/components/switcher.vue';
import { usePlaceCall } from '@/shared/services/sip.setup';

import OutboundKeypad from './outbound/outbound-keypad.vue';
import Recent from './outbound/recent.vue';

const mode = ref<'keypad' | 'recent'>('keypad');

const placeCall = usePlaceCall();
function dialRecent(call: IRecentCallResponse) {
    placeCall({
        remoteNumber: call.remoteNumber,
        remoteName: call.remoteName ?? undefined,
        fileNumber: call.autoFileNumber ?? undefined
    });
}
</script>

<style lang="scss" scoped>
#phone-outbound {
    @apply flex-1 flex flex-col pt-4 gap-4 min-h-0;
}

.switcher {
    @apply self-center;

    :deep(button) {
        @apply p-3;
    }
}
</style>
