<template>
    <VfModal id="m-edit-address" ref="modal" scrolls @form-submit="submit">
        <template #header>
            {{ address ? 'Edit Address' : 'Add Address' }}
        </template>

        <label>Address:</label>
        <input v-model="mutableAddress.street1" v-autofocus type="text" data-1p-ignore />
        <input v-model="mutableAddress.street2" type="text" data-1p-ignore />

        <div class="csz">
            <div>
                <label>City:</label>
                <input v-model="mutableAddress.city" type="text" data-1p-ignore maxlength="17" />
            </div>
            <div class="state">
                <label>State:</label>
                <input v-model="mutableAddress.state" type="text" data-1p-ignore />
            </div>
            <div class="zip">
                <label>ZIP:</label>
                <input v-model="mutableAddress.zip" type="text" data-1p-ignore />
            </div>
        </div>

        <label>Phone 1:</label>
        <div class="phone-wrap">
            <input v-model="mutableAddress.phone1" type="text" data-1p-ignore />
            <input v-model="mutableAddress.phone1Ext" type="text" placeholder="ext" data-1p-ignore />
        </div>

        <label>Phone 2:</label>
        <div class="phone-wrap">
            <input v-model="mutableAddress.phone2" type="text" data-1p-ignore />
            <input v-model="mutableAddress.phone2Ext" type="text" placeholder="ext" data-1p-ignore />
        </div>

        <label>Note:</label>
        <input v-model="mutableAddress.note" type="text" data-1p-ignore />

        <label>Email:</label>
        <input v-model="mutableAddress.email" type="email" data-1p-ignore />

        <template #footer>
            <button type="button" @click="callback()">Cancel</button>
            <button type="submit" class="primary">Save</button>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { cloneProp, handleErrorAndAlert, showAlert, VfModal } from '@signal24/vue-foundation';
import { ref } from 'vue';

import type { IDebtorAddress, IDebtorAddressUpdate, IDebtorInfo } from '@/generated/openapi-client';
import { apiClient } from '@/openapi-client';

const props = defineProps<{
    debtor: IDebtorInfo;
    address: IDebtorAddress | null;
    callback: (updated?: boolean) => void;
}>();

const modal = ref<InstanceType<typeof VfModal>>();
const mutableAddress = ref<IDebtorAddressUpdate>(
    cloneProp<IDebtorAddressUpdate>(props.address, {
        sequenceNo: 0,
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        phone1: '',
        phone1Ext: '',
        phone2: '',
        phone2Ext: '',
        email: '',
        note: ''
    })
);

async function submit() {
    const strippedPh1 = mutableAddress.value.phone1.replace(/\D/g, '').replace(/^1(\d{10})$/, '$1');
    const strippedPh2 = mutableAddress.value.phone2.replace(/\D/g, '').replace(/^1(\d{10})$/, '$1');

    if (strippedPh1 && strippedPh1.length !== 10) {
        return showAlert('Phone 1 must be a valid 10-digit phone number.');
    }
    if (strippedPh2 && strippedPh2.length !== 10) {
        return showAlert('Phone 1 must be a valid 10-digit phone number.');
    }

    modal.value!.mask();

    try {
        await apiClient.debtors.putDebtorsUpdateAddress({
            fileNumber: props.debtor.fileNumber,
            requestBody: {
                ...mutableAddress.value,
                phone1: strippedPh1,
                phone2: strippedPh2
            }
        });
        props.callback(true);
    } catch (err) {
        modal.value!.unmask();
        handleErrorAndAlert(err);
    }
}
</script>

<style lang="scss">
#m-edit-address {
    .vf-modal-content {
        @apply flex flex-col gap-1 w-[425px];
    }

    label {
        @apply font-semibold;

        &:not(:first-child) {
            @apply mt-2;
        }
    }

    .csz {
        @apply flex gap-2;

        > div {
            @apply flex flex-col gap-1;
        }

        .state input {
            @apply w-24;
        }

        .zip input {
            @apply w-24;
        }
    }

    .phone-wrap {
        @apply flex gap-2;

        input:last-child {
            @apply w-32;
        }
    }
}
</style>
