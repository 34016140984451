<template>
    <form id="phone-dialer-keypad" @submit.prevent="dial">
        <input
            v-model="destination"
            type="text"
            pattern="\+?1?\d{10}"
            placeholder="dial a number"
            class="no-spinner"
            title="Please enter a 10-digit phone number."
            required
        />
        <Keypad @press="d => (destination += d)" />
        <button type="submit" class="dial">Place Call</button>
    </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import { usePlaceCall } from '@/shared/services/sip.setup';

import Keypad from '../shared/keypad.vue';

const placeCall = usePlaceCall();

const destination = ref<string>('');

function dial() {
    placeCall({
        remoteNumber: destination.value
    });
}
</script>

<style lang="scss" scoped>
#phone-dialer-keypad {
    @apply flex flex-col gap-4 p-4 pt-0;
}

input {
    @apply text-center text-xl;
}

.dial {
    @apply p-3;
}
</style>
