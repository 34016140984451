/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ic1AccountSearchMode } from '../models/Ic1AccountSearchMode';
import type { IDebtorActionInput } from '../models/IDebtorActionInput';
import type { IDebtorAddressUpdate } from '../models/IDebtorAddressUpdate';
import type { IDebtorDetailExtended } from '../models/IDebtorDetailExtended';
import type { IDebtorInfo } from '../models/IDebtorInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DebtorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IDebtorInfo
     * @throws ApiError
     */
    public getDebtorsListDebtors({
        searchMode,
        query,
    }: {
        searchMode?: Ic1AccountSearchMode,
        query?: string,
    }): CancelablePromise<Array<IDebtorInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/debtors',
            query: {
                'searchMode': searchMode,
                'query': query,
            },
        });
    }

    /**
     * @returns IDebtorInfo
     * @throws ApiError
     */
    public getDebtorsListRescheduledDebtors({
        agentId,
    }: {
        agentId?: number,
    }): CancelablePromise<Array<IDebtorInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/debtors/rescheduled',
            query: {
                'agentId': agentId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteDebtorsDeleteRescheduledEvent({
        agentCode,
        fileNumber,
        isoTime,
    }: {
        agentCode: string,
        fileNumber: string,
        isoTime: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/debtors/{fileNumber}/rescheduled/{agentCode}/{isoTime}',
            path: {
                'agentCode': agentCode,
                'fileNumber': fileNumber,
                'isoTime': isoTime,
            },
        });
    }

    /**
     * @returns IDebtorDetailExtended
     * @throws ApiError
     */
    public getDebtorsGetDebtor({
        fileNumber,
        existingAccessLogId,
        dialed,
    }: {
        fileNumber: string,
        existingAccessLogId?: number,
        dialed?: boolean,
    }): CancelablePromise<IDebtorDetailExtended> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/debtors/{fileNumber}',
            path: {
                'fileNumber': fileNumber,
            },
            query: {
                'existingAccessLogId': existingAccessLogId,
                'dialed': dialed,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public putDebtorsUpdateAddress({
        fileNumber,
        requestBody,
    }: {
        fileNumber: string,
        requestBody: IDebtorAddressUpdate,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/debtors/{fileNumber}/address',
            path: {
                'fileNumber': fileNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postDebtorsPostAction({
        fileNumber,
        requestBody,
    }: {
        fileNumber: string,
        requestBody: IDebtorActionInput,
    }): CancelablePromise<{
        ok: boolean;
        confirmationNumber?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/debtors/{fileNumber}/action',
            path: {
                'fileNumber': fileNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postDebtorsMarkFileClosed({
        fileNumber,
        requestBody,
    }: {
        fileNumber: string,
        requestBody: {
            accessLogId: number;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/debtors/{fileNumber}/close',
            path: {
                'fileNumber': fileNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
