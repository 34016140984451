<template>
    <div class="keypad">
        <div>
            <div @click.stop="press('1')">1</div>
            <div @click.stop="press('2')">2 <span>ABC</span></div>
            <div @click.stop="press('3')">3 <span>DEF</span></div>
        </div>
        <div>
            <div @click.stop="press('4')">4 <span>GHI</span></div>
            <div @click.stop="press('5')">5 <span>JKL</span></div>
            <div @click.stop="press('6')">6 <span>MNO</span></div>
        </div>
        <div>
            <div @click.stop="press('7')">7 <span>PQRS</span></div>
            <div @click.stop="press('8')">8 <span>TUV</span></div>
            <div @click.stop="press('9')">9 <span>WXYZ</span></div>
        </div>
        <div>
            <div @click.stop="press('*')">*</div>
            <div @click.stop="press('0')">0 <span>&nbsp;</span></div>
            <div @click.stop="press('#')">#</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
    (e: 'press', digit: string): void;
}>();

function press(digit: string) {
    emit('press', digit);
}
</script>

<style lang="scss" scoped>
.keypad {
    @apply flex flex-col gap-2;

    & > div {
        @apply grid grid-cols-3 gap-2;

        & > div {
            @apply p-4 text-2xl text-center bg-gray-200 hover:bg-gray-300 cursor-pointer rounded-lg flex flex-col;
        }
    }
}
</style>
