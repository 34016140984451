/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { CallsService } from './services/CallsService';
import { CampaignLoaderService } from './services/CampaignLoaderService';
import { CampaignsService } from './services/CampaignsService';
import { DebtorsService } from './services/DebtorsService';
import { HealthcheckService } from './services/HealthcheckService';
import { IdentityService } from './services/IdentityService';
import { MetaService } from './services/MetaService';
import { PublicFreeSwitchEventService } from './services/PublicFreeSwitchEventService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly calls: CallsService;
    public readonly campaignLoader: CampaignLoaderService;
    public readonly campaigns: CampaignsService;
    public readonly debtors: DebtorsService;
    public readonly healthcheck: HealthcheckService;
    public readonly identity: IdentityService;
    public readonly meta: MetaService;
    public readonly publicFreeSwitchEvent: PublicFreeSwitchEventService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.calls = new CallsService(this.request);
        this.campaignLoader = new CampaignLoaderService(this.request);
        this.campaigns = new CampaignsService(this.request);
        this.debtors = new DebtorsService(this.request);
        this.healthcheck = new HealthcheckService(this.request);
        this.identity = new IdentityService(this.request);
        this.meta = new MetaService(this.request);
        this.publicFreeSwitchEvent = new PublicFreeSwitchEventService(this.request);
    }
}

