<template>
    <div id="connection" ref="cmpEl">
        <div class="message">{{ message }}</div>
        <button v-if="store.srpcConflict" @click="overrideSrpc">Disconnect Other Sessions</button>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

import { useStore } from '@/store';

const cmpEl = ref<HTMLElement>();

const store = useStore();

const message = computed(() => {
    if (store.srpcConflict) {
        return `Another session is connected.\n\nPlease close other sessions to connect to the phone system.`;
    }
    if (store.srpcStatus === 'disconnected') {
        return 'Not connected to agent server.';
    }
    if (store.srpcStatus === 'connecting') {
        return 'Connecting to agent server...';
    }
    if (store.sipStatus === 'disconnected') {
        return 'Not connected to phone system.';
    }
    if (store.sipStatus === 'connecting') {
        return 'Connecting to phone system...';
    }
    return 'Something is wrong.';
});

async function overrideSrpc() {
    store.srpcOverride = true;
    store.srpcConflict = false;
}
</script>

<style lang="scss" scoped>
#connection {
    @apply flex-1 p-4 flex flex-col justify-center;
}

.message {
    @apply flex-1 flex text-xl whitespace-pre-line items-center justify-center text-center;
}
</style>
