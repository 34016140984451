<template>
    <VfModal id="audio-selection">
        <template #header> Speaker/Microphone Selection </template>

        <label>Speakers:</label>
        <div class="row">
            <VfSmartSelect v-model="selectedSpeakerDevice" :options="speakerDevices" :formatter="d => d.label" />
            <button v-tooltip="isPlayingTestSound ? 'Stop playing test sound' : 'Play test sound'" @click="toggleTestSound">
                <i class="fa" :class="isPlayingTestSound ? 'fa-stop' : 'fa-play'" />
            </button>
        </div>

        <label>Microphone:</label>
        <VfSmartSelect v-model="selectedMicrophoneDevice" :options="microphoneDevices" :formatter="d => d.label" />
        <AVMedia class="meter" :media="inputMediaStream" type="vbar" vbar-bg-color="white" :canv-width="336" />

        <audio
            ref="audioEl"
            src="/assets/ring.mp3"
            loop="true"
            preload="true"
            @play="isPlayingTestSound = true"
            @pause="isPlayingTestSound = false"
        />

        <template #footer>
            <button @click="callback()">Close</button>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { VfModal, VfSmartSelect } from '@signal24/vue-foundation';
import { computed, onMounted, ref, watch } from 'vue';
import { AVMedia } from 'vue-audio-visual';

import { useStore } from '@/store';

defineProps<{ callback: () => void }>();

const store = useStore();
const audioEl = ref<HTMLAudioElement>();

const devices = ref<MediaDeviceInfo[]>([]);
const selectedSpeakerDevice = ref<MediaDeviceInfo | null>(null);
const selectedMicrophoneDevice = ref<MediaDeviceInfo | null>(null);
const inputMediaStream = ref<MediaStream>();
const isPlayingTestSound = ref(false);

const speakerDevices = computed(() => devices.value.filter(d => d.kind === 'audiooutput'));
const microphoneDevices = computed(() => devices.value.filter(d => d.kind === 'audioinput'));

onMounted(async () => {
    devices.value = await navigator.mediaDevices.enumerateDevices();

    if (store.sipSpeakerDeviceId) {
        selectedSpeakerDevice.value = speakerDevices.value.find(d => d.deviceId === store.sipSpeakerDeviceId) ?? null;
    } else if (selectedSpeakerDevice.value === null) {
        selectedSpeakerDevice.value = speakerDevices.value.find(d => d.deviceId === 'default') ?? null;
    }

    const configuredInputDevice: MediaDeviceInfo | undefined = store.sipMicrophoneDeviceId
        ? devices.value.find(d => d.deviceId === store.sipMicrophoneDeviceId)
        : undefined;
    inputMediaStream.value = await navigator.mediaDevices.getUserMedia({
        audio: configuredInputDevice ? { deviceId: { exact: configuredInputDevice.deviceId } } : true
    });

    const inputTrack = inputMediaStream.value.getAudioTracks()[0];
    const inputDeviceId = inputTrack.getSettings().deviceId;
    selectedMicrophoneDevice.value = devices.value.find(d => d.deviceId === inputDeviceId) ?? null;

    watch(selectedMicrophoneDevice, updateMediaStream);
});

async function updateMediaStream() {
    inputMediaStream.value = undefined;
    if (!selectedMicrophoneDevice.value) return;

    inputMediaStream.value = await navigator.mediaDevices.getUserMedia({
        audio: {
            deviceId: { exact: selectedMicrophoneDevice.value.deviceId }
        }
    });
}

async function toggleTestSound() {
    if (!selectedSpeakerDevice.value) return;
    if (isPlayingTestSound.value) {
        audioEl.value!.pause();
    } else {
        await audioEl.value!.setSinkId(selectedSpeakerDevice.value.deviceId);
        audioEl.value!.play();
    }
}

watch(selectedSpeakerDevice, () => {
    if (selectedSpeakerDevice.value) {
        if (isPlayingTestSound.value) {
            audioEl.value!.setSinkId(selectedSpeakerDevice.value.deviceId);
        }
        store.sipSpeakerDeviceId = selectedSpeakerDevice.value.deviceId;
    }
});
watch(selectedMicrophoneDevice, () => {
    if (selectedMicrophoneDevice.value) {
        store.sipMicrophoneDeviceId = selectedMicrophoneDevice.value.deviceId;
    }
});
</script>

<style lang="scss" scoped>
label {
    @apply block mb-1 font-semibold w-96;

    &:not(:first-child) {
        @apply mt-4;
    }
}

.row {
    @apply flex gap-1;

    :first-child {
        @apply flex-1;
    }
}

.meter {
    @apply w-full h-3 bg-gray-200 mt-1 rounded-md;
}
</style>
