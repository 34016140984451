<template>
    <VfModal id="m-edit-payment" ref="modal" scrolls @form-submit="save">
        <template #header> Add Payment Method </template>

        <Switcher v-model="paymentInfo.type" :options="{ creditCard: 'Credit Card', check: 'Check' }" />

        <div class="account-wrap">
            <template v-if="paymentInfo.type === 'creditCard'">
                <div>
                    <label>Card #:</label>
                    <input
                        v-model="paymentInfo.accountNumber"
                        v-autofocus
                        type="text"
                        required
                        data-1p-ignore
                        pattern="\d{15,16}"
                        title="Please enter a 15 or 16-digit credit card number."
                    />
                </div>
                <div>
                    <label>Expiration:</label>
                    <div class="exp">
                        <select v-model="paymentInfo.expMonth" required>
                            <option v-for="m in 12" :key="m" :value="m">{{ m }}</option>
                        </select>
                        <select v-model="paymentInfo.expYear" required>
                            <option v-for="y in 15" :key="y" :value="y + new Date().getFullYear() - 2001">
                                {{ y + new Date().getFullYear() - 2001 }}
                            </option>
                        </select>
                    </div>
                </div>
                <div>
                    <label>CVV:</label>
                    <input
                        v-model="paymentInfo.securityCode"
                        type="text"
                        class="cvv"
                        data-1p-ignore
                        :pattern="`\\d{${paymentInfo.accountNumber.startsWith('3') ? 4 : 3}}`"
                        :title="`Please enter the ${paymentInfo.accountNumber.startsWith('3') ? 4 : 3}-digit CVV.`"
                    />
                </div>
            </template>
            <template v-else>
                <div>
                    <label>Routing #:</label>
                    <input
                        v-model="paymentInfo.routingNumber"
                        v-autofocus
                        type="text"
                        required
                        data-1p-ignore
                        pattern="\d{9}"
                        title="Please enter the 9-digit routing number."
                        maxlength="9"
                        :class="{
                            validating: routingNumberStatus === 'validating',
                            invalid: routingNumberStatus === 'invalid',
                            valid: routingNumberStatus === 'valid'
                        }"
                        @input="validateRoutingNumber"
                    />
                </div>
                <div>
                    <label>Account #:</label>
                    <input v-model="paymentInfo.accountNumber" type="text" required data-1p-ignore />
                </div>
            </template>
        </div>

        <div class="address">
            <label>Name:</label>
            <input v-model="paymentInfo.name" type="text" required data-1p-ignore maxlength="30" />
        </div>

        <div class="address">
            <label>Address:</label>
            <input v-model="paymentInfo.address" type="text" required data-1p-ignore maxlength="30" />
        </div>

        <div class="csz">
            <div>
                <label>City:</label>
                <input v-model="paymentInfo.city" type="text" required data-1p-ignore maxlength="17" />
            </div>
            <div class="state">
                <label>State:</label>
                <input
                    v-model="paymentInfo.state"
                    type="text"
                    required
                    data-1p-ignore
                    pattern="[A-Za-z]{2}"
                    title="Please enter the 2 letter state code."
                />
            </div>
            <div class="zip">
                <label>ZIP:</label>
                <input v-model="paymentInfo.zip" type="text" required data-1p-ignore pattern="\d{5}" title="Please enter the 5-digit ZIP code." />
            </div>
        </div>

        <template #footer>
            <button type="button" @click="callback()">Cancel</button>
            <button type="submit" class="primary">Save</button>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { handleErrorAndAlert, showAlert, VfModal } from '@signal24/vue-foundation';
import { format } from 'date-fns';
import luhn from 'fast-luhn';
import { ref } from 'vue';

import type { IDebtorDetailExtended, IDebtorInfo } from '@/generated/openapi-client';
import type { IDebtorPaymentUpdateCc } from '@/generated/openapi-client/models/IDebtorPaymentUpdateCc';
import type { IDebtorPaymentUpdateCheck } from '@/generated/openapi-client/models/IDebtorPaymentUpdateCheck';
import { apiClient } from '@/openapi-client';
import Switcher from '@/shared/components/switcher.vue';
import { BrowserCrypto } from '@/shared/helpers/crypto.helpers';

const modal = ref<InstanceType<typeof VfModal>>();

const props = defineProps<{
    debtorInfo: IDebtorInfo;
    debtorDetail: IDebtorDetailExtended;
    callback: (paymentInfo?: IDebtorPaymentUpdateCc | IDebtorPaymentUpdateCheck) => void;
}>();

const paymentInfo = ref<IDebtorPaymentUpdateCc | IDebtorPaymentUpdateCheck>({
    type: 'creditCard',
    accountNumber: '',
    name: props.debtorInfo.debtorName.substring(0, 30),
    address: props.debtorDetail.addresses[0].street1.substring(0, 30),
    city: props.debtorDetail.addresses[0].city.substring(0, 17),
    state: props.debtorDetail.addresses[0].state,
    zip: props.debtorDetail.addresses[0].zip.substring(0, 5)
} as IDebtorPaymentUpdateCc);

const routingNumberStatus = ref<'valid' | 'invalid' | 'validating'>();

async function validateRoutingNumber() {
    routingNumberStatus.value = undefined;
    if (paymentInfo.value.type != 'check') return;
    if (paymentInfo.value.routingNumber.length !== 9) return;
    if (!/^\d{9}$/.test(paymentInfo.value.routingNumber)) {
        routingNumberStatus.value = 'invalid';
        return;
    }

    routingNumberStatus.value = 'validating';
    const { valid } = await apiClient.meta.getMetaValidateRoutingNumber({ routingNumber: paymentInfo.value.routingNumber });
    routingNumberStatus.value = valid ? 'valid' : 'invalid';
}

async function save() {
    if (paymentInfo.value.type === 'creditCard') {
        if (!luhn(paymentInfo.value.accountNumber)) {
            return showAlert('The card number entered is not valid. Please check the card number.');
        }
        if (
            `${String(paymentInfo.value.expYear).padStart(2, '0')}${String(paymentInfo.value.expMonth).padStart(2, '0')}` < format(new Date(), 'yyMM')
        ) {
            return showAlert('The card entered has expired. Please check the expiration date.');
        }
    } else if (routingNumberStatus.value !== 'valid') {
        return showAlert('The routing number entered is not valid. Please check the routing number.');
    }

    modal.value!.mask();

    try {
        const encryptedAccountNumber = await BrowserCrypto.encrypt(paymentInfo.value.accountNumber);
        const wrappedAccountNumber = `${paymentInfo.value.accountNumber.substring(0, 1)}*${encryptedAccountNumber}*${paymentInfo.value.accountNumber.substring(
            paymentInfo.value.accountNumber.length - 4
        )}`;

        props.callback({
            ...paymentInfo.value,
            accountNumber: wrappedAccountNumber
        });
    } catch (err) {
        handleErrorAndAlert(err);
        modal.value!.unmask();
    }
}
</script>

<style lang="scss">
#m-edit-payment {
    .vf-modal-content {
        @apply flex flex-col gap-1 w-[425px];
    }

    label {
        @apply block mb-1;
    }

    .vf-modal-content > div:not(:first-child) {
        @apply mt-2;
    }

    .account-wrap {
        @apply flex gap-2;

        .exp {
            @apply flex gap-1;

            select {
                @apply border p-2 h-[37px] rounded;
            }
        }

        .cvv {
            @apply w-16;
        }
    }

    .address {
        input {
            @apply w-full;
        }
    }

    .csz {
        @apply flex gap-2;

        > div {
            @apply flex flex-col gap-1;
        }

        .state input {
            @apply w-24;
        }

        .zip input {
            @apply w-24;
        }
    }

    .validating {
        @apply border-yellow-500 outline-yellow-500;
    }

    .invalid {
        @apply border-red-500 outline-red-500;
    }

    input.valid:focus-visible {
        @apply outline-green-500;
    }
}
</style>
