import { useStore } from '@/store';

export function useGenerateTone() {
    const store = useStore();

    return async () => {
        const audioContext = new AudioContext();

        const oscillator = audioContext.createOscillator();
        oscillator.type = 'sine';
        oscillator.frequency.setValueAtTime(440, audioContext.currentTime);

        const gainNode = audioContext.createGain();
        gainNode.gain.setValueAtTime(0.25, audioContext.currentTime);

        const destination = audioContext.createMediaStreamDestination();
        oscillator.connect(gainNode);
        gainNode.connect(destination);

        const audio = new Audio();
        if (store.sipSpeakerDeviceId) await audio.setSinkId(store.sipSpeakerDeviceId);
        audio.srcObject = destination.stream;
        audio.play();

        oscillator.addEventListener('ended', () => {
            oscillator.disconnect();
            gainNode.disconnect();
            audioContext.close();
        });

        oscillator.start();
        oscillator.stop(audioContext.currentTime + 0.3);
    };
}
