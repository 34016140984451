<template>
    <div id="debtor-search">
        <div class="filters">
            <Switcher
                v-model="mode"
                :options="{
                    recent: { icon: 'fa fa-clock-rotate-left', tip: 'My Recently Worked' },
                    rescheduled: { icon: 'fa fa-calendar', tip: 'My Rescheduled' },
                    search: { icon: 'fa fa-search', tip: 'Search' },
                    call: { icon: 'fa fa-phone', tip: 'Active Call', hidden: !(store.activeCall || mode === 'call') }
                }"
            />

            <template v-if="mode === 'search'">
                <VfEzSmartSelect
                    v-model="searchMode"
                    :options="{
                        smart: 'Smart Search',
                        name: 'Name',
                        address: 'Address',
                        taxId: 'Tax ID',
                        phone: 'Phone #',
                        fileNumber: 'File #',
                        clientReference: 'Client Reference #'
                    }"
                />

                <input v-model="search" v-autofocus type="text" placeholder="Search..." @keydown.enter="doSearch" />
            </template>
        </div>
        <div class="results">
            <table>
                <thead>
                    <tr>
                        <th>File #</th>
                        <th class="phase">Phase</th>
                        <th>Name</th>
                        <th>Client</th>
                        <th>Amount Owed</th>
                        <th>Status</th>
                        <th>Review Date</th>
                        <th v-if="mode === 'rescheduled'">Rescheduled For</th>
                    </tr>
                </thead>
                <tbody v-if="data">
                    <tr
                        v-for="debtor in data"
                        :key="debtor.fileNumber"
                        class="clickable"
                        :class="{ past: debtor.rescheduled && debtor.rescheduled.for < now }"
                        @click="selectDebtor(debtor)"
                    >
                        <td>{{ $filters.fileNumber(debtor.fileNumber) }}</td>
                        <td class="phase">{{ debtor.isPhase1 ? '1' : '2' }}</td>
                        <td class="name">{{ debtor.debtorName }}</td>
                        <td class="name">
                            <span v-tooltip="debtor.clientName">{{ debtor.clientCode }}</span>
                        </td>
                        <td>{{ $filters.usCurrency(debtor.balance) }}</td>
                        <td>
                            <span v-tooltip="getStatusDescription(debtor.status)">{{ debtor.status }}</span>
                        </td>
                        <td>{{ debtor.reviewDate ?? '-' }}</td>
                        <td v-if="mode === 'rescheduled'" v-datetime="debtor.rescheduled?.for" v-tooltip="debtor.rescheduled?.note"></td>
                    </tr>
                </tbody>
            </table>

            <Loader v-if="!data" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { VfEzSmartSelect } from '@signal24/vue-foundation';
import { computed, onActivated, onMounted, ref, watch } from 'vue';

import { type Ic1AccountSearchMode, type IDebtorInfo } from '@/generated/openapi-client';
import { apiClient } from '@/openapi-client';
import Loader from '@/shared/components/loader.vue';
import Switcher from '@/shared/components/switcher.vue';
import { useGetStatusDescription } from '@/shared/helpers/meta.helpers';
import { useStore } from '@/store';

const store = useStore();
const getStatusDescription = useGetStatusDescription();

const mode = ref<'recent' | 'search' | 'rescheduled' | 'call'>(store.activeCall ? 'call' : 'recent');
const searchMode = ref<string>('smart');
const search = ref<string>('');
const activeSearch = ref<string>('');
const data = ref<IDebtorInfo[]>();

const now = new Date().toISOString();

watch(mode, value => {
    if (value === 'call') {
        load();
    }
});

const apiParams = computed<{ searchMode?: Ic1AccountSearchMode; query?: string }>(() =>
    mode.value === 'recent' ? {} : { searchMode: searchMode.value as Ic1AccountSearchMode, query: activeSearch.value }
);
watch(apiParams, () => {
    data.value = undefined;
    if (mode.value === 'call') {
        return;
    }
    if (mode.value === 'search' && !activeSearch.value) {
        data.value = [];
        return;
    }
    load();
});

watch(
    () => store.activeCall,
    () => {
        if (store.activeCall) {
            if (mode.value === 'call') {
                loadAccountsForCall();
            } else {
                mode.value = 'call';
            }
        }
    }
);

function doSearch() {
    activeSearch.value = search.value
        .replace(/[^0-9a-z ,]/gi, '')
        .replace(/\s+/g, ' ')
        .trim();
}

async function load() {
    data.value = undefined;
    if (mode.value === 'call') {
        return loadAccountsForCall();
    } else if (mode.value === 'rescheduled') {
        data.value = await apiClient.debtors.getDebtorsListRescheduledDebtors({});
    } else {
        data.value = await apiClient.debtors.getDebtorsListDebtors(apiParams.value);
    }
}

async function loadAccountsForCall() {
    data.value = undefined;

    if (!store.activeCall) {
        data.value = [];
        return;
    }

    const phoneNumber = store.activeCall.rpc?.remoteNumber ?? store.activeCall.provisional?.remoteNumber;
    const fileNumber = store.activeCall.rpc?.fileNumber ?? store.activeCall.provisional?.fileNumber;
    const fileNumberPromise = fileNumber
        ? apiClient.debtors.getDebtorsListDebtors({ searchMode: 'fileNumber', query: fileNumber })
        : Promise.resolve([]);
    const phoneNumberPromise = phoneNumber
        ? apiClient.debtors.getDebtorsListDebtors({ searchMode: 'phone', query: phoneNumber })
        : Promise.resolve([]);
    const [fileNumberResults, phoneNumberResults] = await Promise.all([fileNumberPromise, phoneNumberPromise]);

    data.value = [...fileNumberResults, ...(fileNumber ? phoneNumberResults.filter(p => p.fileNumber !== fileNumber) : phoneNumberResults)];
}

function selectDebtor(debtor: IDebtorInfo) {
    store.activeDebtor = debtor;
}

watch(
    () => store.activeDebtor,
    (newValue, oldValue) => {
        if (mode.value !== 'recent') return;
        if (oldValue && !newValue) {
            const index = data.value?.indexOf(oldValue);
            if (index !== undefined && index !== -1) {
                data.value?.splice(index, 1);
                data.value?.unshift(oldValue);
            }
        }
    }
);

watch(
    () => store.nextDebtorSearch,
    newValue => {
        if (!newValue) return;
        mode.value = 'search';
        searchMode.value = 'smart';
        search.value = newValue;
        doSearch();
    },
    { immediate: true }
);

onMounted(load);
onActivated(() => {
    if (store.debtorUpdatesMade) {
        store.debtorUpdatesMade = false;
        load();
    }
});
</script>

<style lang="scss" scoped>
#debtor-search {
    @apply flex-1 flex flex-col;
}

.filters {
    @apply p-2 flex gap-1 h-[50px];

    input {
        @apply flex-1;
    }
}

@media screen and (max-width: 1080px) {
    .filters {
        @apply h-[44px];
    }
}

.results {
    @apply flex-1 bg-white overflow-y-auto;
}

.loader {
    @apply mt-4 flex justify-center;
}

tr {
    @apply align-top;
}

.phase {
    @apply text-center;
}

th,
td:not(.name) {
    @apply whitespace-nowrap;
}

.past {
    @apply bg-orange-100 hover:bg-orange-200;
}
</style>
