/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UploadedFile } from '../models/UploadedFile';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CampaignLoaderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postCampaignLoaderLoadCampaign({
        requestBody,
    }: {
        requestBody: {
            file: UploadedFile;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/campaigns/load',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
