/* eslint-disable @typescript-eslint/no-explicit-any */
import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/vue';
import { isOpenApiError } from '@signal24/openapi-client-codegen/browser';
import { installApiClientInterceptors } from '@signal24/vue-foundation';

import { tracer } from '@/otel';
import { Auth } from '@/shared/services/auth';
import { OpenReplay } from '@/shared/services/openreplay';

import { ApiClient, CancelablePromise } from './generated/openapi-client';
import { logger } from './shared/services/logger';

export const apiClient = new ApiClient({
    BASE: import.meta.env.VITE_APP_API_URL
});
installApiClientInterceptors({
    apiClient,
    CancelablePromise,

    wrapper(options, fn) {
        return new CancelablePromise((resolve: (value: any) => void, reject: (err: any) => void, onCancel: (handler: () => void) => void) => {
            return tracer.startActiveSpan('api', span => {
                const promise = fn(options);
                onCancel(promise.cancel);
                promise
                    .then(resolve)
                    .catch(reject)
                    .finally(() => span.end());
            });
        }) as any;
    },

    onRequest(options) {
        return {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${Auth.activeJwt}`,
                'x-openreplay-sessionid': OpenReplay.sessionId ?? undefined
            }
        };
    },

    onError(err) {
        if (isOpenApiError(err)) {
            if (err.status === 401) {
                Auth.clearSession();
                return err;
            }

            // handle validation errors
            if (
                err.status === 400 &&
                typeof err.body === 'object' &&
                'message' in err.body &&
                typeof err.body.message === 'string' &&
                err.body.message.startsWith('Validation error:')
            ) {
                err.message = err.body.message;
            }

            // we don't need to know about UserErrors
            if (err.status === 422) {
                return err;
            }
        }

        Sentry.withScope(scope => {
            const spanCtx = trace.getActiveSpan()?.spanContext();
            if (spanCtx) {
                scope.setContext('trace', {
                    trace_id: spanCtx.traceId,
                    span_id: spanCtx.spanId
                });
            }
            Sentry.captureException(err);
        });
    }
});

const originalFetch = window.fetch;
window.fetch = async (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response> => {
    const url = input.toString();
    if (url === import.meta.env.VITE_APP_SCRIBE_URL) {
        return originalFetch(input, init);
    }

    try {
        const url = input.toString();
        logger.info('[API]', 'Request', {
            method: init?.method ?? 'GET',
            url
        });
        const result = await originalFetch(input, init);
        logger.info('[API]', 'Response', {
            method: init?.method,
            url,
            status: result.status
        });
        return result;
    } catch (err) {
        logger.error('[API]', 'Error', err);
        throw err;
    }
};
