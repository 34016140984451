<template>
    <div id="phone">
        <Connection v-if="!store.isSrpcConnected || doesSipNeedConnecting" />
        <ActiveCall v-else-if="store.activeCall" />
        <Queue v-else-if="store.activeCampaignId" />
        <Outbound v-else />

        <div class="extra-buttons">
            <button v-if="isCallConnected" v-tooltip="'Keypad'" class="dtmf-pad" @click="showKeypad">
                <i class="fa fa-grid-round"></i>
            </button>
            <button v-else-if="store.activeCampaignId" v-tooltip="'Recent Calls'" class="recents" @click="showRecents">
                <i class="fa fa-phone-missed"></i>
            </button>

            <button v-tooltip="'Microphone/Speaker Selection'" @click="showAudioSelection">
                <i class="fa fa-speaker"></i>
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { presentOverlay } from '@signal24/vue-foundation';
import { computed, watch } from 'vue';

import { c2c } from '@/generated/proto/c2cagent';
import { SipProvider } from '@/shared/helpers/di.helpers';
import { useGenerateTone } from '@/shared/helpers/tone.helper';
import { usePlaceCall } from '@/shared/services/sip.setup';
import { type ICall, useStore } from '@/store';

import ActiveCall from './phone/active-call.vue';
import MAudioSelection from './phone/active-call/m-audio-selection.vue';
import Connection from './phone/connection.vue';
import Outbound from './phone/outbound.vue';
import Queue from './phone/queue.vue';
import PKeypad from './phone/shared/p-keypad.vue';
import PRecents from './phone/shared/p-recents.vue';

const store = useStore();
const sipClient = SipProvider.inject();
const placeCall = usePlaceCall();
const generateTone = useGenerateTone();

const isCallConnected = computed(() => !!store.activeCall?.rpc?.startTime);
const doesSipNeedConnecting = computed(() => {
    if (store.isSipConnected) return false;
    if (store.activeCampaignId) return true;
    if (store.activeCall && !store.activeCall?.hangupCause) return true;
    if (store.sipStatus === 'connecting') return true;
    return false;
});

watch(doesSipNeedConnecting, () => {
    if (doesSipNeedConnecting.value) {
        sipClient.connect();
    }
});

watch(() => store.activeCall, playToneOnConnect);
watch(() => store.activeCall, disconnectSipIfNotNeeded);
watch(() => store.activeCampaignId, disconnectSipIfNotNeeded);

function playToneOnConnect(newVal: ICall | null, oldVal: ICall | null) {
    if (!oldVal && newVal?.rpc?.source === c2c.agentServer.CallSource.inbound) {
        generateTone();
    }
}

function disconnectSipIfNotNeeded() {
    if (!store.activeCall && !store.activeCampaignId) {
        sipClient.disconnect();
    }
}

function showAudioSelection() {
    presentOverlay(MAudioSelection, {});
}

async function showRecents(e: MouseEvent) {
    const result = await presentOverlay(PRecents, {}, { anchor: e.target as HTMLElement });
    if (result) {
        placeCall({
            remoteNumber: result.remoteNumber,
            remoteName: result.remoteName ?? undefined,
            fileNumber: result.autoFileNumber ?? undefined
        });
    }
}

function showKeypad(e: MouseEvent) {
    presentOverlay(
        PKeypad,
        {
            onDigit(digit) {
                sipClient.sendDigit(digit);
            }
        },
        { anchor: e.target as HTMLElement }
    );
}
</script>

<style lang="scss" scoped>
#phone {
    @apply flex-1 flex flex-col relative z-10;
}

.fa {
    @apply w-5;
}

.extra-buttons {
    @apply absolute top-2 right-2 flex gap-1;

    button {
        @apply bg-gray-400 px-2 py-1;
    }
}
</style>
