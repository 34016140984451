/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IRecentCallResponse } from '../models/IRecentCallResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CallsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IRecentCallResponse
     * @throws ApiError
     */
    public getCallsGetRecentCalls({
        beforeId,
    }: {
        beforeId?: number,
    }): CancelablePromise<Array<IRecentCallResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calls/recent',
            query: {
                'beforeId': beforeId,
            },
        });
    }

}
