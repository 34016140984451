<template>
    <div class="user-menu" @click="showMenu">{{ store.user?.email }} <i class="fa fa-caret-down" /></div>
</template>

<script lang="ts" setup>
import { showContextMenu } from '@signal24/vue-foundation';

import { Auth } from '@/shared/services/auth';
import { useStore } from '@/store';

const store = useStore();

function showMenu(e: MouseEvent) {
    showContextMenu(e, {
        items: [{ title: 'Logout', handler: logout }]
    });
}

function logout() {
    Auth.clearSession();
}
</script>

<style lang="scss" scoped>
.user-menu {
    @apply cursor-pointer hover:text-gray-500;
}
</style>
