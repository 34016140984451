import * as Sentry from '@sentry/vue';
import { isOpenApiError } from '@signal24/openapi-client-codegen/browser';
import { configureVf, createFilters, installVf } from '@signal24/vue-foundation';
import currency from 'currency.js';
import { format } from 'date-fns';
import type { App } from 'vue';

import { NonReportedError } from './shared/helpers/error.helpers';
import { logger } from './shared/services/logger';

configureVf({
    defaultDateTimeFormat: 'yyyy-MM-dd h:mm aaa',

    errorHandler(err) {
        logger.error(err);

        if (isOpenApiError(err) && [401, 422].includes(err.status)) {
            return;
        }

        if (err instanceof NonReportedError) {
            return;
        }

        Sentry.captureException(err);
    }
});

export const filters = createFilters(() => ({
    usCurrency: (value: number) => {
        return currency(value).format();
    },

    fileNumber: (value: string) => {
        if (!/^\d{7}$/.test(value)) {
            return value;
        }
        return `${value.slice(0, 6)}-${value.slice(6)}`;
    },

    date: (value: string | Date) => {
        const str = typeof value === 'string' ? value : value.toISOString();
        return str.substring(0, 10);
    },

    time: (value: string | Date) => {
        return format(new Date(value), 'h:mm aaa');
    },

    dateTime: (value: string | Date) => {
        return format(new Date(value), 'M/d/yy h:mm aaa');
    }
}));

declare module 'vue' {
    export interface ComponentCustomProperties {
        $filters: typeof filters;
    }
}

export function setupVf(app: App) {
    installVf(app);
    app.config.globalProperties.$filters = filters;
}
