<template>
    <div id="agent">
        <div class="top">
            <div class="nav">
                <button v-tooltip="'Quick Links'" @click="showLinks"><i class="fa-solid fa-link" /></button>
                <CampaignSelector class="campaign-selector" />
            </div>

            <div class="user"><UserMenu /></div>
        </div>
        <div class="bottom">
            <div class="main">
                <Main />
            </div>
            <div class="phone">
                <Phone />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { showContextMenu } from '@signal24/vue-foundation';
import { compact } from 'lodash';

import { setupSipClient } from '@/shared/services/sip.setup';
import { setupSrpcClient } from '@/shared/services/srpc.setup';
import { useStore } from '@/store';

import CampaignSelector from './agent/campaign-selector.vue';
import Main from './agent/main.vue';
import Phone from './agent/phone.vue';
import UserMenu from './agent/user-menu.vue';

const store = useStore();

function showLinks(e: MouseEvent) {
    showContextMenu(e, {
        items: compact([
            {
                title: 'Time Clock',
                handler: () => window.open('https://www.spectrumresearch.org/tcprod/CoastToCoastCA/TimeClockFace.asp', 'timeclock')
            },
            { title: 'Outlook', handler: () => window.open('https://owa.c2cfsi.com', 'outlook') },
            { title: 'My OneDrive', handler: () => window.open('https://c2cfsi-my.sharepoint.com/', 'onedrive') },
            {
                title: 'Collector Shared OneDrive',
                handler: () => window.open('https://c2cfsi.sharepoint.com/sites/collectors/Shared%20Documents/Forms/AllItems.aspx', 'sharepoint')
            },
            { title: 'Reports Home', handler: () => window.open('https://internal-msreports.c2cfsi.com/CtoCSales/default_total.html', 'c2creports') },
            {
                title: 'Agent Payment Contest',
                handler: () => window.open('https://internal-msreports.c2cfsi.com/CtoCSales/AgentReports/payments.asp', 'c2creports')
            },
            store.user?.isManager && {
                title: 'Manager View',
                handler: () => {
                    window.open('/manager');
                }
            }
        ])
    });
}

const srpcClient = setupSrpcClient();
setupSipClient(srpcClient);
</script>

<style lang="scss" scoped>
#agent {
    @apply h-[725px] max-h-[90%] w-full max-w-screen-xl bg-gray-100 rounded-md flex flex-col overflow-hidden;
}

.top {
    @apply p-2 flex justify-between items-center border-b border-gray-300;
}

.nav {
    @apply flex gap-1;
}

.campaign-selector {
    @apply ml-1 border-l pl-2;
}

.bottom {
    @apply flex-1 flex min-h-0;
}

.main {
    @apply flex-1 flex min-h-0 min-w-0;
}

.phone {
    @apply w-96 border-l border-gray-300 flex-shrink-0 flex;
}

@media screen and (max-width: 1024px) {
    .phone {
        @apply w-72;
    }
}
</style>
