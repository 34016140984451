import { inject, provide } from 'vue';

import type { c2c } from '@/generated/proto/c2cagent';

import type { SipClient } from '../services/sip';
import { SrpcClient } from '../services/srpc';

export type ISrpcClient = SrpcClient<c2c.agentServer.CMessageStream, c2c.agentServer.SMessageStream>;
const SrpcClientSymbol = 'srpcClient';
export const SrpcProvider = {
    provide: (instance: ISrpcClient) => provide(SrpcClientSymbol, instance),
    inject: () => inject<ISrpcClient>(SrpcClientSymbol)!
};

const SipClientSymbol = 'sipClient';
export const SipProvider = {
    provide: (instance: SipClient) => provide(SipClientSymbol, instance),
    inject: () => inject<SipClient>(SipClientSymbol)!
};
