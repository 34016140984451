import { jwtDecode } from 'jwt-decode';

import type { IIdentity } from '@/generated/openapi-client';
import { apiClient } from '@/openapi-client';

class AuthService extends EventTarget {
    activeJwt: string | null = null;
    activeJwtExp: number | null = null;
    activeUser?: IIdentity;

    constructor() {
        super();
        this.loadStoredJwt();
        // setInterval(() => this.checkJwtTimeout(), 30_000);
    }

    private loadStoredJwt() {
        const ssJwt = localStorage.getItem('c2c:auth:sessionJwt');
        if (!ssJwt) return;

        const ssJwtExp = parseInt(localStorage.getItem('c2c:auth:sessionJwtExp')!);
        if (ssJwtExp <= Date.now()) {
            return this.clearSession();
        }

        this.activeJwt = ssJwt;
        this.activeJwtExp = ssJwtExp;
    }

    async checkSession() {
        if (!this.activeJwt) return;
        this.activeUser = await apiClient.identity.getIdentityGetIdentity();
    }

    // private async checkJwtTimeout() {
    //     if (!this.activeJwtExp) return;
    //     if (this.activeJwtExp > Date.now() + 120_000) return;
    //     await this.renewJwt();
    //     if (this.activeJwtExp < Date.now()) this.clearSession();
    // }

    // private async renewJwt() {
    //     const renewResult = await fetch('https://auth.zynoapi.net/proxy-oidc/renew', {
    //         method: 'POST',
    //         headers: {
    //             Authorization: `Bearer ${this.activeJwt}`
    //         }
    //     });
    //     if (!renewResult.ok) return console.warn('Failed to renew JWT:', renewResult.status, renewResult.statusText);

    //     const renewData = await renewResult.json();
    //     if (!renewData.jwt) return console.warn('Failed to renew JWT:', renewData);

    //     this.setJwt(renewData.jwt);
    // }

    setJwt(jwt: string) {
        try {
            const decoded = jwtDecode(jwt);
            this.activeJwt = jwt;
            this.activeJwtExp = decoded.exp! * 1000;
            localStorage.setItem('c2c:auth:sessionJwt', jwt);
            localStorage.setItem('c2c:auth:sessionJwtExp', this.activeJwtExp.toString());
        } catch {
            this.clearSession();
        }
    }

    clearSession() {
        localStorage.removeItem('c2c:auth:sessionJwt');
        localStorage.removeItem('c2c:auth:sessionJwtExp');
        this.activeJwt = null;
        this.activeJwtExp = null;
        this.dispatchEvent(new Event('auth:cleared'));
    }

    // getLoginUrl(email: string) {
    //     return `https://auth.zynoapi.net/proxy-oidc/start?email=${encodeURIComponent(email)}&returnUrl=${encodeURIComponent(location.href)}`;
    // }
}

export const Auth = new AuthService();
