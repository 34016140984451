import { onMounted, onUnmounted } from 'vue';

export function useRequestNotificationPermission() {
    if (!('Notification' in window)) {
        return;
    }

    const requestPermission = () => {
        if (Notification.permission === 'default') {
            Notification.requestPermission();
        }
        window.removeEventListener('click', requestPermission);
    };

    onMounted(() => {
        window.addEventListener('click', requestPermission);
    });

    onUnmounted(() => {
        window.removeEventListener('click', requestPermission);
    });
}

export function useCreateNotification() {
    return (title: string) => {
        if (!('Notification' in window)) {
            return;
        }

        if (Notification.permission !== 'granted') {
            return;
        }

        if (document.visibilityState === 'visible') {
            return;
        }

        const notif = new Notification(title);
        const remove = () => notif.close();

        const visListener = () => {
            if (document.visibilityState === 'visible') {
                remove();
                document.removeEventListener('visibilitychange', visListener);
            }
        };
        document.addEventListener('visibilitychange', visListener);

        return remove;
    };
}
