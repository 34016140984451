/* eslint-disable @typescript-eslint/no-explicit-any */
import JsSIP from 'jssip';
import type { EndEvent, IceCandidateEvent, PeerConnectionEvent, RTCSession } from 'jssip/lib/RTCSession';

import { logger } from './logger';

interface ISipWindow {
    $sipCounter?: number;
}

interface ISipOptions {
    debug: boolean;
    tokenGenerator: () => Promise<string>;
    speakerDeviceId?: string | null;
    microphoneDeviceId?: string | null;
}

export class SipClient extends EventTarget {
    private instanceId = ((window as ISipWindow).$sipCounter = ((window as ISipWindow).$sipCounter ?? 0) + 1);
    private shouldConnect = false;
    private audioElement?: HTMLAudioElement;
    private ua?: JsSIP.UA;
    private session?: RTCSession;

    constructor(private options: ISipOptions) {
        super();
    }

    log(...args: any[]) {
        if (this.options.debug) {
            logger.debug(`[SIP:${this.instanceId}]`, ...args);
        }
    }

    logError(...args: any[]) {
        if (this.options.debug) {
            logger.error(`[SIP:${this.instanceId}]`, ...args);
        }
    }

    setSpeakerDeviceId(deviceId: string | null) {
        this.log('Changing speaker device', deviceId);

        this.options.speakerDeviceId = deviceId;
        this.audioElement?.setSinkId(this.options.speakerDeviceId ?? '');
    }

    async setMicrophoneDeviceId(deviceId: string | null) {
        this.log('Changing microphone device', deviceId);
        this.options.microphoneDeviceId = deviceId;

        if (deviceId && this.session) {
            navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: deviceId } } }).then(stream => {
                this.session?.connection.getSenders()[0].replaceTrack(stream.getAudioTracks()[0]);
            });
        }
    }

    async connect() {
        if (this.shouldConnect) return;
        this.shouldConnect = true;
        this.reconnect();

        return new Promise(resolve => {
            this.addEventListener('connected', resolve, { once: true });
        });
    }

    async reconnect() {
        if (!this.shouldConnect) return;
        if (this.ua) return;

        this.audioElement = document.createElement('audio');
        this.audioElement.autoplay = true;
        this.audioElement.controls = false;
        this.audioElement.muted = false;
        this.audioElement.volume = 1;
        if (this.options.speakerDeviceId) this.audioElement.setSinkId(this.options.speakerDeviceId);
        document.body.appendChild(this.audioElement);

        const callToken = await this.options.tokenGenerator();

        const uri = import.meta.env.VITE_APP_SIP_WSS_URI;
        const socket = new JsSIP.WebSocketInterface(uri);
        this.ua = new JsSIP.UA({
            sockets: [socket],
            uri: `sip:${import.meta.env.VITE_APP_SIP_AOR}@zynotalk`,
            session_timers: true
        });
        this.ua.on('connecting', () => this.log('Transport connecting...'));
        this.ua.on('connected', () => this.log('Transport connected'));
        this.ua.on('disconnected', this.handleDisconnect);
        this.ua.start();

        this.session = this.ua.call(`sip:${import.meta.env.VITE_APP_SIP_DESTINATION}@zynotalk`, {
            extraHeaders: [`X-ZynoTalk-Token: ${callToken}`],
            sessionTimersExpires: 90,
            mediaConstraints: {
                audio: this.options.microphoneDeviceId ? { deviceId: { exact: this.options.microphoneDeviceId } } : true,
                video: false
            },
            pcConfig: {
                iceServers: [
                    {
                        urls: ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
                    }
                ]
            },
            eventHandlers: {
                connecting: () => this.log('Call connecting...'),
                progress: () => this.log('Call setting up...'),
                accepted: this.handleCallAnswered,
                ended: e => this.handleHangup('ended', e),
                failed: e => this.handleHangup('failed', e),
                icecandidate: this.handleIceCandidate,
                getusermediafailed: data => this.log('Get user media failed', data),
                peerconnection: this.handlePeerConnection,
                'peerconnection:createofferfailed': data => this.log('Peer connection create offer failed', data),
                'peerconnection:createanswerfailed': data => this.log('Peer connection create answer failed', data),
                'peerconnection:setlocaldescriptionfailed': data => this.log('Peer connection set local description failed', data),
                'peerconnection:setremotedescriptionfailed': data => this.log('Peer connection set remote description failed', data)
            }
        });

        window.addEventListener('beforeunload', this.disconnect);
        this.dispatchEvent(new Event('connecting'));
    }

    mute() {
        this.session?.mute();
    }

    unmute() {
        this.session?.unmute();
    }

    isMuted() {
        return this.session?.isMuted().audio ?? false;
    }

    sendDigit(digit: string) {
        this.session?.sendDTMF(digit);
    }

    disconnect = () => {
        if (!this.shouldConnect) return;
        this.shouldConnect = false;
        this.session?.terminate();
        this.ua?.stop();
    };

    private handleIceCandidate = (data: IceCandidateEvent) => {
        this.log('ICE candidate', data.candidate);
        if (data.candidate.type === 'srflx') {
            this.log('Got reflexive candidate. Proceeding.');
            if (this.ua?.isConnected()) {
                data.ready();
            } else {
                this.ua?.once('connected', () => data.ready());
            }
        }
    };

    private handleCallAnswered = () => {
        this.log('Call answered');
        this.dispatchEvent(new Event('connected'));
    };

    private handlePeerConnection = (event: PeerConnectionEvent) => {
        this.log('Got peer connection', event.peerconnection);
        event.peerconnection.addEventListener('track', trackEvent => {
            this.log('Got track', trackEvent.track);
            if (trackEvent.track.kind === 'audio') {
                this.audioElement!.srcObject = trackEvent.streams[0];
            }
        });
    };

    private handleHangup = (action: string, data: EndEvent) => {
        this.log(`Call ${action}. Disconnecting...`, { cause: data.cause });
        this.ua?.stop();
    };

    private handleDisconnect = () => {
        this.log('Transport disconnected');

        if (this.session) {
            this.session.removeAllListeners();
            if (this.session.isEstablished()) this.session.terminate();
            this.session = undefined;
        }

        if (this.ua) {
            this.ua.removeAllListeners();
            this.ua = undefined;
        }

        if (this.audioElement) {
            document.body.removeChild(this.audioElement);
            this.audioElement = undefined;
        }

        if (this.shouldConnect) window.setTimeout(() => this.reconnect(), 3_000);

        window.removeEventListener('beforeunload', this.disconnect);
        this.dispatchEvent(new Event('disconnected'));
    };
}

export const SipTerminationMessages: Record<string, { title: string; summary?: string }> = {
    UNALLOCATED_NUMBER: { title: 'Number Not In Service', summary: 'The number is either not valid, is not assigned to anyone, or is disconnected.' },
    NO_USER_RESPONSE: {
        title: 'No Acknowledgement',
        summary:
            'The call was transmitted by the carrier but the call was not acknowledged or answered before the time limit (determined by their carrier), and the carrier did not forward the call to voicemail.'
    },
    NO_ANSWER: {
        title: 'No Answer',
        summary:
            'The carrier acknowledges a device received the call, but it was not answered before the time limit (determined by their carrier), and the carrier did not forward the call to voicemail.'
    },
    USER_BUSY: { title: 'Line Busy', summary: 'The called party is busy on another call, and the carrier did not forward the call to voicemail.' },
    CALL_REJECTED: {
        title: 'Call Rejected',
        summary: 'The call was declined by the called party, and the carrier did not forward the call to voicemail.'
    }
};
