<template>
    <div id="debtor-view">
        <div class="header">
            <button @click="back"><i class="fa fa-arrow-left" /></button>
            <div class="info">
                <span v-tooltip="displayName" class="debtor">{{ $filters.fileNumber(debtor.fileNumber) }} &bull; {{ displayName }}</span>
                <span v-tooltip="debtor.clientName" class="client" @click="showClientInfo"
                    >{{ debtor.clientCode }} &bull; {{ debtor.clientName }}</span
                >
            </div>

            <Switcher
                v-if="detail"
                v-model="view"
                class="switcher"
                :options="{
                    overview: 'Overview',
                    history: 'History',
                    addresses: 'Addresses'
                }"
            />
        </div>
        <div class="content">
            <Loader v-if="!detail" />
            <keep-alive v-else>
                <Overview v-if="view === 'overview'" :debtor="debtor" :detail="detail" @reload="load" />
                <History v-else-if="view === 'history'" :debtor="debtor" :detail="detail" />
                <Addresses v-else-if="view === 'addresses'" :debtor="debtor" :detail="detail" />
            </keep-alive>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { showAlert } from '@signal24/vue-foundation';
import { compact, uniq } from 'lodash';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import type { IDebtorDetailExtended } from '@/generated/openapi-client';
import { c2c } from '@/generated/proto/c2cagent';
import { apiClient } from '@/openapi-client';
import Loader from '@/shared/components/loader.vue';
import Switcher from '@/shared/components/switcher.vue';
import { withWait } from '@/shared/helpers/request.helpers';
import { useStore } from '@/store';

import Addresses from './debtor/addresses.vue';
import History from './debtor/history.vue';
import Overview from './debtor/overview.vue';

const store = useStore();
const debtor = computed(() => store.activeDebtor!);
const loadedDebtorNumber = ref<string | null>(null);

const detail = ref<IDebtorDetailExtended>();
const view = ref<'overview' | 'payments' | 'history' | 'addresses'>('overview');

const displayName = computed(() => {
    if (!detail.value) return debtor.value.debtorName;
    return uniq(compact([debtor.value.debtorName, ...detail.value.addresses.map(a => a.name)])).join(' / ');
});

async function load() {
    const fileNumber = debtor.value.fileNumber;
    const existingAccessLogId = detail.value?.accessLogId;

    detail.value = undefined;
    detail.value = await apiClient.debtors.getDebtorsGetDebtor({
        fileNumber,
        dialed:
            store.activeCall?.rpc?.source === c2c.agentServer.CallSource.campaign && store.activeCall?.rpc?.fileNumber === debtor.value.fileNumber,
        existingAccessLogId
    });

    // sometimes the user has gone back before this API call is finished
    if (!debtor.value || debtor.value.fileNumber !== fileNumber) return;

    loadedDebtorNumber.value = fileNumber;
}

async function closeFileLog() {
    if (!loadedDebtorNumber.value || !detail.value) return;
    await apiClient.debtors.postDebtorsMarkFileClosed({
        fileNumber: loadedDebtorNumber.value!,
        requestBody: { accessLogId: detail.value.accessLogId }
    });
}

async function showClientInfo() {
    withWait('Loading client info...', async () => {
        const { info } = await apiClient.meta.getMetaGetClientInfo({ clientNumber: debtor.value.clientCode });
        showAlert(debtor.value.clientCode, info);
    });
}

function back() {
    store.activeDebtor = null;
}

onMounted(load);

onMounted(() => {
    window.addEventListener('beforeunload', closeFileLog);
});
onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', closeFileLog);
    closeFileLog();
});
</script>

<style lang="scss" scoped>
#debtor-view {
    @apply flex-1 flex flex-col min-w-0;
}

.header {
    @apply p-2 flex gap-2 h-[50px] items-center;

    button {
        @apply px-4;
    }

    .info {
        @apply flex flex-col min-w-0;

        span {
            @apply font-semibold text-lg whitespace-nowrap overflow-hidden overflow-ellipsis;
        }
    }

    span.client {
        @apply cursor-pointer text-gray-400;
    }

    .switcher {
        @apply ml-auto;
    }
}

.content {
    @apply flex-1 border-t overflow-y-auto;
}
</style>
