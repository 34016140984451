import Tracker from '@openreplay/tracker';
import { uuid } from '@signal24/vue-foundation';

import type { IIdentity } from '@/generated/openapi-client';
import { OtelState } from '@/otel';

export class OpenReplay {
    static tracker?: Tracker;
    static sessionId?: string;

    static async start(user: IIdentity) {
        if (!this.tracker) return;
        await this.tracker.start({
            userID: user.email,
            forceNew: true,
            sessionHash: uuid()
        });
        this.sessionId = this.tracker.getSessionID() ?? undefined;
        OtelState.openReplaySessionId = this.sessionId;
    }

    static stop() {
        this.tracker?.stop();
        this.sessionId = undefined;
    }

    static get sessionUrl() {
        return this.tracker?.getSessionURL();
    }

    static getSessionUrl(withTs = false) {
        return this.tracker?.getSessionURL({ withCurrentTime: withTs });
    }
}
