/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IIdentity } from '../models/IIdentity';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IdentityService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IIdentity
     * @throws ApiError
     */
    public getIdentityGetIdentity(): CancelablePromise<IIdentity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/identity',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getIdentityGetSrpcToken({
        override,
        manager,
    }: {
        override?: boolean,
        manager?: boolean,
    }): CancelablePromise<{
        token: (string | null);
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/identity/srpc-token',
            query: {
                'override': override,
                'manager': manager,
            },
        });
    }

}
