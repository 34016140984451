import * as Sentry from '@sentry/browser';
import { defineStore } from 'pinia';
import { watch } from 'vue';

import type { IDebtorInfo, IIdentity } from './generated/openapi-client';
import type { c2c } from './generated/proto/c2cagent';
import { OtelState } from './otel';
import { Auth } from './shared/services/auth';
import { setupLogger } from './shared/services/logger';
import { OpenReplay } from './shared/services/openreplay';

export interface ICall {
    provisional?: {
        remoteNumber: string;
        remoteName?: string;
        fileNumber?: string;
        isRescheduled?: boolean;
    };
    rpc?: c2c.agentServer.ICallInfo;
    hangupCause?: string;
    held?: boolean;
}

export const useStore = defineStore('root', {
    state: () => ({
        globalError: null as string | null,
        user: null as IIdentity | null,
        srpcConflict: false,
        srpcOverride: false,
        srpcStatus: 'disconnected' as 'disconnected' | 'connecting' | 'connected',
        sipStatus: 'disconnected' as 'disconnected' | 'connecting' | 'connected',
        activeCampaignId: null as number | null,
        activeCall: null as ICall | null,
        activeDebtor: null as IDebtorInfo | null,
        nextDebtorSearch: null as string | null,
        debtorUpdatesMade: false,
        sipSpeakerDeviceId: localStorage.getItem('c2c:sipSpeakerDeviceId'),
        sipMicrophoneDeviceId: localStorage.getItem('c2c:sipMicrophoneDeviceId'),
        excludeCallbacks: false,
        excludeInbound: false
    }),

    getters: {
        isSrpcConnected(state) {
            return state.srpcStatus === 'connected';
        },

        isSipConnected(state) {
            return state.sipStatus === 'connected';
        }
    }
});

export function initStore() {
    const store = useStore();

    watch(
        () => store.user,
        async () => {
            if (!store.user) return;

            setupLogger(store.user.email);

            OpenReplay.start(store.user);

            Sentry.setUser({
                id: store.user.id,
                email: store.user.email
            });

            Sentry.addEventProcessor(event => {
                event.contexts = {
                    ...event.contexts,
                    openreplay: {
                        sessionId: OpenReplay.sessionId,
                        sessionUrl: OpenReplay.getSessionUrl(true)
                    }
                };
                return event;
            });

            OtelState.user = store.user.email;
        }
    );

    watch(
        () => store.sipSpeakerDeviceId,
        () => {
            localStorage['c2c:sipSpeakerDeviceId'] = store.sipSpeakerDeviceId;
        }
    );
    watch(
        () => store.sipMicrophoneDeviceId,
        () => {
            localStorage['c2c:sipMicrophoneDeviceId'] = store.sipMicrophoneDeviceId;
        }
    );

    Auth.addEventListener('auth:cleared', () => {
        location.href = `https://c2cfsi.cloudflareaccess.com/cdn-cgi/access/logout`;
    });
}
