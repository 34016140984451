import { useStore } from '@/store';

import { SrpcProvider } from '../helpers/di.helpers';
import { withWait } from '../helpers/request.helpers';

export const useEnterCampaign = () => {
    const store = useStore();
    const srpcClient = SrpcProvider.inject();
    return (campaignId: number) => {
        withWait('Joining campaign..', async () => {
            await srpcClient.invoke('cJoinCampaignRequest', 'cJoinCampaignResponse', { campaignId });
            store.activeCampaignId = campaignId;
        });
    };
};

export const useLeaveCampaign = () => {
    const store = useStore();
    const srpcClient = SrpcProvider.inject();
    return () =>
        withWait('Leaving campaign..', async () => {
            await srpcClient.invoke('cJoinCampaignRequest', 'cJoinCampaignResponse', {
                campaignId: null
            });
            store.activeCampaignId = null;

            if (!store.activeCall?.rpc) {
                store.activeCall = null;
            }
        });
};
