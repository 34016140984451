/* eslint-disable @typescript-eslint/no-explicit-any */
import { uuid } from '@signal24/vue-foundation';

import { c2c } from '@/generated/proto/c2cagent';

import { NonReportedError } from '../helpers/error.helpers';
import { logger } from './logger';

type RequestKeys<T> = {
    [K in keyof T]: K extends `${infer _}Request` ? K : never;
}[keyof T];

type ResponseKeys<T> = {
    [K in keyof T]: K extends `${infer _}Response` ? K : never;
}[keyof T];

type ClassType<T> = new (...args: any[]) => T;

interface IQueuedRequest {
    exp: number;
    resolve: (value: any) => void;
    reject: (err: any) => void;
}

interface BaseMessage {
    requestId?: string | null;
    reply?: boolean | null;
    error?: string | null;
    userError?: boolean | null;
    trace?: c2c.agentServer.ITraceContext | null;
    pingPong?: c2c.agentServer.IPingPong | null;
    byteStreamOperation?: c2c.agentServer.IByteStreamOperation | null;
}
type BaseMessageClass<T extends BaseMessage> = ClassType<T> & {
    encode(message: any): { finish(): Uint8Array };
    decode(reader: Uint8Array, length?: number): any;
};

interface ISrpcOptions<O extends BaseMessage, I extends BaseMessage> {
    uri: string;
    clientOutput: BaseMessageClass<O>;
    serverInput: BaseMessageClass<I>;
    debug?: boolean;
}

interface IMetadata {
    [key: string]: string;
    clientId: string;
    appVersion: string;
}

interface ISrpcWindow {
    $srpcCounter?: number;
}

export class SrpcClient<TClientInput extends BaseMessage, TServerOutput extends BaseMessage> extends EventTarget {
    private instanceId = ((window as ISrpcWindow).$srpcCounter = ((window as ISrpcWindow).$srpcCounter ?? 0) + 1);

    constructor(private options: ISrpcOptions<TClientInput, TServerOutput>) {
        super();
    }

    private callConnectionHandlers = new Set<() => void>();
    private callMessageHandlers = new Map<RequestKeys<TServerOutput>, { resultType: string; handler: (data: any) => Promise<any> }>();
    private callDisconnectionHandlers = new Set<() => void>();
    private ws?: WebSocket;
    private pingInterval?: number;
    private lastPongMs?: number;
    private requestQueue = new Map<string, IQueuedRequest>();
    public shouldConnect = false;
    public isConnected = false;
    public metadata?: IMetadata | (() => Promise<IMetadata | null>);

    log(...args: any[]) {
        if (this.options.debug) {
            logger.debug(`[sRPC:${this.instanceId}]`, ...args);
        }
    }

    logError(...args: any[]) {
        if (this.options.debug) {
            logger.error(`[sRPC:${this.instanceId}]`, ...args);
        }
    }

    connect() {
        if (this.shouldConnect) return;
        this.shouldConnect = true;
        this.reconnect();
    }

    private async reconnect() {
        if (!this.shouldConnect) return;

        const metadata =
            typeof this.metadata === 'function' ? await this.metadata().catch(err => this.logError('Error retrieving metadata', err)) : this.metadata;

        if (!metadata) {
            this.queueReconnect();
            return this[metadata === null ? 'log' : 'logError']('Metadata not set');
        }

        this.log('Connecting...');
        this.dispatchEvent(new Event('connecting'));

        const callId = uuid();
        const { clientId, appVersion, ...rest } = metadata;
        const qs = new URLSearchParams({ ...rest, cid: clientId, appv: appVersion, id: callId }).toString();

        this.ws = new WebSocket(`${this.options.uri}?${qs}`);
        this.ws.binaryType = 'arraybuffer';
        this.ws.addEventListener('open', this.handleWsConnected);
        this.ws.addEventListener('message', this.handleWsMessage);
        this.ws.addEventListener('close', this.handleWsClose);
        this.ws.addEventListener('error', this.handleWsError);
    }

    disconnect() {
        this.log('Disconnect requested.');
        this.shouldConnect = false;
        this.ws?.close();
    }

    private handleWsConnected = (event: Event) => {
        if (event.target !== this.ws) return;
        this.log('sRPC WS connected');
    };

    private handleWsMessage = (event: MessageEvent) => {
        if (event.target !== this.ws) return;

        const bytes = new Uint8Array(event.data);
        const decoded = this.options.serverInput.decode(bytes);

        if (!this.isConnected) {
            return this.handleWsInitialMessage(decoded);
        }

        if (decoded.pingPong) {
            this.lastPongMs = Date.now();
            return;
        }

        const { requestId, reply } = decoded;

        this.log('Server message received', decoded);

        if (!requestId) {
            this.log('Invalid request ID', { requestId });
            return this.ws?.close(4000, 'Invalid request ID');
        }

        if (reply) {
            const queueItem = this.requestQueue.get(requestId);
            if (!queueItem) {
                this.log('Unknown request ID for reply', { requestId });
                return this.ws?.close(4000, 'Unknown request ID');
            }

            this.requestQueue.delete(requestId);
            if (decoded.error) {
                return queueItem.reject(decoded.error);
            }
            return queueItem.resolve(decoded);
        }

        this.handleServerRequest(requestId, decoded).then(
            response => {
                this.write({ requestId, reply: true, ...response } as TClientInput);
            },
            err => {
                this.write({ requestId, reply: true, error: String(err) } as TClientInput);
            }
        );
    };

    private handleWsInitialMessage(message: TServerOutput) {
        if (!message.pingPong) {
            this.log('sRPC WS initial message is not a pingPong');
            return this.ws?.close(4000, 'Invalid first message');
        }

        this.isConnected = true;
        this.lastPongMs = Date.now();
        this.pingInterval = window.setInterval(() => this.doPingPong(), 35_000);
        this.callConnectionHandlers.forEach(handler => handler());

        this.log('sRPC connection established');
        this.dispatchEvent(new Event('connected'));
    }

    private handleWsClose = (event: CloseEvent) => {
        if (event.target !== this.ws) return;
        this.log('sRPC WS closed', { code: event.code, reason: event.reason });
        this.processDisconnect();
    };

    private handleWsError = (event: Event) => {
        if (event.target !== this.ws) return;
        this.log('sRPC WS error', event);
        this.processDisconnect();
    };

    private processDisconnect() {
        this.dispatchEvent(new Event('disconnected'));
        this.queueReconnect();

        if (this.pingInterval) window.clearInterval(this.pingInterval);
        this.pingInterval = undefined;

        const wasConnected = this.isConnected;
        this.isConnected = false;
        this.ws = undefined;

        if (wasConnected) this.callDisconnectionHandlers.forEach(handler => handler());

        for (const queueItem of this.requestQueue.values()) {
            queueItem.reject(new SrpcDisconnectedError());
        }
        this.requestQueue.clear();
    }

    private queueReconnect() {
        window.setTimeout(() => this.reconnect(), 3_000);
    }

    private doPingPong() {
        if ((this.lastPongMs ?? 0) < Date.now() - 75_000) {
            this.log('Pong timeout');
            return this.ws?.close(4000, 'Pong timeout');
        }

        this.write({ pingPong: {} } as TClientInput);
    }

    private write(message: TClientInput) {
        if (!this.ws) {
            this.log('Attempted to write to closed WS');
            return;
        }

        const bytes = this.options.clientOutput.encode(message).finish();
        this.ws.send(bytes);
    }

    private async handleServerRequest(requestId: string, message: TServerOutput & BaseMessage): Promise<Partial<TClientInput>> {
        for (const key of this.callMessageHandlers.keys()) {
            if (message[key]) {
                try {
                    this.log('Server request received', { requestId, requestType: key, traceId: message.trace?.traceId });
                    const handlerMeta = this.callMessageHandlers.get(key)!;
                    const result = await handlerMeta.handler(message[key]);
                    this.log('Server request processed', { requestId, requestType: key, traceId: message.trace?.traceId });
                    return {
                        [handlerMeta.resultType]: result
                    } as any;
                } catch (err) {
                    this.logError('Server request failed', err, { requestId, requestType: key, traceId: message.trace?.traceId });
                    throw err;
                }
            }
        }

        this.logError('Unhandled message type', { requestId });
        throw new Error('Unhandled message type');
    }

    registerConnectionHandler(handler: () => void) {
        this.callConnectionHandlers.add(handler);
    }

    registerMessageHandler<T extends RequestKeys<TServerOutput>, R extends ResponseKeys<TClientInput>>(
        actionType: T,
        resultType: R,
        handler: (data: NonNullable<TServerOutput[T]>) => Promise<TClientInput[R]>
    ) {
        this.callMessageHandlers.set(actionType, {
            resultType,
            handler
        });
    }

    registerDisconnectHandler(handler: () => void) {
        this.callDisconnectionHandlers.add(handler);
    }

    invoke<T extends RequestKeys<TClientInput>, R extends ResponseKeys<TServerOutput>>(
        requestType: T,
        resultType: R,
        data: TClientInput[T],
        timeoutMs = 30_000
    ): Promise<NonNullable<TServerOutput[R]>> {
        const requestId = uuid();

        return new Promise<NonNullable<TServerOutput[R]>>((resolve, reject) => {
            const loggedReject = (err: any) => {
                this.logError('Server invocation failed', { err, requestId, requestType });
                reject(err);
            };

            this.requestQueue.set(requestId, {
                exp: Date.now() + timeoutMs,
                resolve: (data: any) => {
                    if (typeof data !== 'object' || !data[resultType]) {
                        loggedReject(new Error('Invalid response from server'));
                    } else {
                        this.log('Server invocation completed', { requestId, requestType });
                        resolve(data[resultType]);
                    }
                },
                reject: loggedReject
            });

            const output = { requestId, [requestType]: data } as unknown as TClientInput;
            this.log('Requesting server invocation', output);
            this.write(output);
        });
    }
}

class SrpcDisconnectedError extends NonReportedError {
    constructor() {
        super('sRPC disconnected');
    }
}
