<template>
    <div id="phone-queue">
        <div class="wait">
            Waiting for next call...
            <span v-if="inQueue" class="campaign-empty">No numbers available in campaign.</span>
        </div>
        <button @click="leaveCampaign">Leave Campaign</button>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

import { SrpcProvider } from '@/shared/helpers/di.helpers';
import { useLeaveCampaign } from '@/shared/services/campaign';
import { useStore } from '@/store';

const store = useStore();
const srpcClient = SrpcProvider.inject();
const leaveCampaign = useLeaveCampaign();
const inQueue = ref(false);

async function joinQueue() {
    const result = await srpcClient.invoke('cEnterCallQueueRequest', 'cEnterCallQueueResponse', {
        excludeCallbacks: store.excludeCallbacks,
        excludeInbound: store.excludeInbound
    });
    inQueue.value = result.inQueue ?? false;
}

watch(() => store.excludeCallbacks, joinQueue);
watch(() => store.excludeInbound, joinQueue);

onMounted(joinQueue);
</script>

<style lang="scss" scoped>
#phone-queue {
    @apply flex-1 p-4 flex flex-col justify-center;
}

.wait {
    @apply flex-1 flex flex-col items-center justify-center text-xl text-center;
}

.campaign-empty {
    @apply mt-2 text-gray-400;
}
</style>
