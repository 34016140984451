import { formatError } from '@signal24/vue-foundation';
import { type Ref, ref } from 'vue';

import { apiClient } from '@/openapi-client';

const CacheLoaders = {
    campaigns: () => apiClient.campaigns.getCampaignsListCampaigns(),
    actionCodes: () => apiClient.meta.getMetaGetActionCodes(),
    statusCodes: () => apiClient.meta.getMetaGetStatuses(),
    trustCodes: () => apiClient.meta.getMetaGetTrustCodes(),
    agents: () => apiClient.meta.getMetaGetAgents()
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export type ICacheKey = keyof typeof CacheLoaders;

type ICacheLoaderReturnTypes = {
    [K in ICacheKey]: ReturnType<(typeof CacheLoaders)[K]>;
};
export type CacheDataType<T extends ICacheKey> = Awaited<ICacheLoaderReturnTypes[T]>;

export interface AsyncRef<T> extends Ref<T> {
    loadError?: string;
    loadPromise?: Promise<void>;
}
export function asyncRef<T>(value: T): AsyncRef<T>;
export function asyncRef<T>(): AsyncRef<T | undefined>;
export function asyncRef<T>(value?: T): AsyncRef<T | undefined> {
    return ref(value) as AsyncRef<T>;
}

export class MetaCache {
    static refCache: { [K in ICacheKey]?: AsyncRef<CacheDataType<K> | undefined> } = {};

    static getRef<T extends ICacheKey>(key: T): AsyncRef<CacheDataType<T> | undefined> {
        if (!this.refCache[key]) {
            const newRef = ref<CacheDataType<T> | undefined>() as AsyncRef<CacheDataType<T> | undefined>;
            newRef.loadPromise = CacheLoaders[key]().then(
                data => {
                    newRef.value = data as CacheDataType<T>;
                },
                err => {
                    newRef.loadError = formatError(err);
                }
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.refCache[key] = newRef as any; // ts issue
        }
        return this.refCache[key]!;
    }

    static reloadCaches() {
        for (const [key, ref] of Object.entries(this.refCache)) {
            ref.value = undefined;
            ref.loadError = undefined;
            ref.loadPromise = CacheLoaders[key as ICacheKey]().then(
                data => {
                    ref.value = data as CacheDataType<ICacheKey>;
                },
                err => {
                    ref.loadError = formatError(err);
                }
            );
        }
    }
}
