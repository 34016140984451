<template>
    <div id="phone-call">
        <div v-if="callStatus === 'rescheduled'" class="rescheduled">
            <h2>Rescheduled Call</h2>
            <div class="file-number">File #{{ $filters.fileNumber(callInfo.fileNumber!) }}</div>
            <div class="rescheduled-for">Rescheduled for<br />{{ rescheduledDt }}</div>
            <div v-if="rpcInfo?.transferNote" class="rescheduled-note">{{ rpcInfo.transferNote }}</div>
        </div>

        <div v-else-if="callStatus === 'connecting'" class="connecting">
            {{ callInfo.source === 'inbound' ? 'Incoming call from\n' : 'Calling\n' }} {{ $filters.phone(callInfo.remoteNumber) }}
            <div v-if="callInfo.fileNumber" class="file-number">File #{{ $filters.fileNumber(callInfo.fileNumber) }}</div>
        </div>

        <div v-else class="connected">
            <div v-if="callStatus === 'connected'" ref="durationRef" class="duration"></div>
            <div v-else-if="callStatus === 'hungup'" class="duration">Hung Up</div>

            <div class="number">{{ $filters.phone(callInfo.remoteNumber) }}</div>
            <div v-if="callInfo.name" class="name">{{ callInfo.name }}</div>
            <div v-if="callInfo.fileNumber" class="file-number">File #{{ $filters.fileNumber(callInfo.fileNumber) }}</div>

            <div v-if="callInfo.provisional || callInfo.source === 'outbound'" class="source">Outbound Call</div>
            <div v-else-if="callInfo.source === 'campaign'" class="source">Campaign Call</div>
            <div v-else-if="callInfo.source === 'inbound'" class="source inbound">Inbound Caller</div>

            <div v-if="rpcInfo?.transferById" class="transfer-info">
                Transferred by<br />{{ transferringAgentName }}
                <template v-if="rpcInfo?.transferNote"
                    ><br />with note:
                    <div class="transfer-note">{{ rpcInfo.transferNote }}</div>
                </template>
            </div>

            <div v-if="callStatus === 'transferred'" class="transferred">Transferred</div>
            <div v-else-if="callStatus === 'failed'" class="failed">
                Call Failed
                <div v-tooltip="terminationMessageDetail?.summary" class="cause">{{ friendlyHangupCause }}</div>
            </div>
        </div>

        <div class="actions">
            <template v-if="callStatus === 'connecting'">
                <button @click="hangup">Cancel</button>
            </template>

            <template v-else-if="callStatus === 'connected'">
                <button @click="transfer">Transfer</button>
                <button :class="{ active: activeCall.held }" @click="toggleHold">{{ activeCall.held ? 'Unhold' : 'Hold' }}</button>
                <button :class="{ active: isMuted }" :disabled="activeCall.held" @click="toggleMute">{{ isMuted ? 'Unmute' : 'Mute' }}</button>
                <button v-if="rpcInfo?.canLeaveMessage" v-hotkey="'F5'" @click="leaveMessage">Leave Message</button>
                <button v-hotkey="'F4'" :disabled="!activeCall.rpc?.callId" @click="hangup">End Call</button>
            </template>

            <template v-else-if="store.activeCampaignId">
                <!-- <button>Mark "Do Not Call"</button>
                <button>Mark "Bad Number"</button> -->
                <button v-hotkey="'F12'" @click="nextCall">Next Call</button>
                <button @click="leaveCampaign">Leave Campaign</button>
            </template>

            <template v-else>
                <button @click="nextCall">Back</button>
            </template>
        </div>

        <Loader v-if="isWorking" class="loader" />
    </div>
</template>

<script lang="ts" setup>
import { handleErrorAndAlert, presentOverlay } from '@signal24/vue-foundation';
import { format } from 'date-fns';
import { compact } from 'lodash';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import { c2c } from '@/generated/proto/c2cagent';
import Loader from '@/shared/components/loader.vue';
import { SipProvider, SrpcProvider } from '@/shared/helpers/di.helpers';
import { withWait } from '@/shared/helpers/request.helpers';
import { useLeaveCampaign } from '@/shared/services/campaign';
import { MetaCache } from '@/shared/services/meta';
import { useCreateNotification } from '@/shared/services/notifications';
import { SipTerminationMessages } from '@/shared/services/sip';
import { useStore } from '@/store';

import MTransfer from './active-call/m-transfer.vue';

const store = useStore();
const durationRef = ref<HTMLElement>();
const srpcClient = SrpcProvider.inject();
const sipClient = SipProvider.inject();
const leaveCampaign = useLeaveCampaign();
const agents = MetaCache.getRef('agents');

const activeCall = computed(() => store.activeCall!);
const isWorking = ref(false);
const isMuted = ref(sipClient.isMuted());
const previousRpc = ref<c2c.agentServer.ICallInfo | undefined>(store.activeCall?.rpc);
const transferringAgentName = computed(() => rpcInfo.value?.transferById && agents.value?.find(a => a.id === rpcInfo.value!.transferById)?.name);

watch(
    () => store.activeCall?.rpc,
    v => v && (previousRpc.value = v)
);

type TCallSource = 'inbound' | 'outbound' | 'campaign' | 'rescheduled' | 'unknown';
const CallSourceEnum = c2c.agentServer.CallSource;
const CallSourceEnumMap: Record<number, TCallSource> = {
    [CallSourceEnum.inbound]: 'inbound',
    [CallSourceEnum.outbound]: 'outbound',
    [CallSourceEnum.campaign]: 'campaign',
    [CallSourceEnum.rescheduled]: 'rescheduled'
};

const callStatus = computed(() => {
    if (activeCall.value.hangupCause) {
        return activeCall.value.hangupCause === 'NORMAL_CLEARING' || activeCall.value.hangupCause === 'ORIGINATOR_CANCEL'
            ? 'hungup'
            : activeCall.value.hangupCause === 'TRANSFERRED'
              ? 'transferred'
              : 'failed';
    }
    if (activeCall.value.rpc) {
        if (activeCall.value.rpc.startTime) {
            return 'connected';
        }
        if (activeCall.value.rpc.source === CallSourceEnum.rescheduled && !activeCall.value.rpc.callId) {
            return 'rescheduled';
        }
        return 'connecting';
    }
    if (activeCall.value.provisional) {
        return 'connecting';
    }
    return 'failed';
});
const rpcInfo = computed(() => activeCall.value.rpc ?? previousRpc.value);
const callInfo = computed(() => ({
    ...activeCall.value,
    ...(rpcInfo.value
        ? {
              source: CallSourceEnumMap[rpcInfo.value.source ?? CallSourceEnum.unknown] ?? ('unknown' as TCallSource),
              remoteNumber: rpcInfo.value.remoteNumber!,
              name: rpcInfo.value.remoteName,
              fileNumber: rpcInfo.value.fileNumber
          }
        : {
              source: 'outbound' as TCallSource,
              remoteNumber: activeCall.value.provisional?.remoteNumber ?? '',
              name: activeCall.value.provisional?.remoteName,
              fileNumber: activeCall.value.provisional?.fileNumber
          })
}));

const terminationMessageDetail = computed(() => (activeCall.value.hangupCause ? SipTerminationMessages[activeCall.value.hangupCause] : null));
const friendlyHangupCause = computed(() => terminationMessageDetail.value?.title ?? activeCall.value.hangupCause);

const rescheduledDt = computed(() => {
    if (!rpcInfo.value?.rescheduledTs) return null;
    const dt = new Date(rpcInfo.value.rescheduledTs * 1000);
    return format(dt, 'M/d/yy h:mm a');
});

///
/// BUTON HANDLERS
///

async function transfer() {
    const onlineAgentIds = await withWait('Fetching online agents...', async () => {
        const result = await srpcClient.invoke('cFetchAgentStatusRequest', 'cFetchAgentStatusResponse', {});
        return compact(result.agents?.filter(a => a.campaignId).map(a => a.agentId) ?? []);
    });
    if (!onlineAgentIds) return;
    presentOverlay(MTransfer, {
        callId: activeCall.value.rpc!.callId!,
        onlineAgentIds,
        performTransfer: (request: c2c.agentServer.CTransferCallRequest) =>
            srpcClient.invoke('cTransferCallRequest', 'cTransferCallResponse', request)
    });
}

async function toggleHold() {
    withWait(activeCall.value.held ? 'Unholding...' : 'Holding...', async () => {
        await srpcClient.invoke('cHoldCallRequest', 'cHoldCallResponse', { callId: activeCall.value.rpc!.callId, hold: !activeCall.value.held });
        activeCall.value.held = !activeCall.value.held;
    });
}

function toggleMute() {
    if (isMuted.value) sipClient.unmute();
    else sipClient.mute();
    isMuted.value = sipClient.isMuted();
}

async function leaveMessage() {
    await withLocalWait(() =>
        srpcClient.invoke('cTransferCallRequest', 'cTransferCallResponse', {
            callId: activeCall.value.rpc!.callId,
            extension: 'message'
        })
    );
}

async function hangup() {
    if (callStatus.value === 'connecting' || callStatus.value === 'connected') {
        await withLocalWait(() =>
            srpcClient.invoke('cEndCallRequest', 'cEndCallResponse', {
                callId: activeCall.value.rpc!.callId
            })
        );
    }
}

async function nextCall() {
    store.activeDebtor = null;
    store.activeCall = null;
}

///
/// DURATION COUNTER
///

let updateInterval: number | undefined;
function setupDuration() {
    nextTick(() => {
        const startTs = activeCall.value.rpc!.startTime! * 1000;
        const updateDuration = () => {
            const ms = Date.now() - startTs;
            let secs = Math.floor(ms / 1000);
            const mins = Math.floor(secs / 60);
            secs %= 60;
            durationRef.value!.textContent = `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
        };
        updateInterval = window.setInterval(updateDuration, 1000);
        updateDuration();
    });
}
onBeforeUnmount(() => updateInterval && window.clearInterval(updateInterval));

watch(
    durationRef,
    () => {
        if (updateInterval) clearInterval(updateInterval);
        if (durationRef.value) setupDuration();
    },
    { immediate: true }
);

/// AUTO DISCONNECT SIP

watch(callStatus, () => {
    if (callStatus.value === 'hungup' && !store.activeCampaignId) {
        sipClient.disconnect();
    }
});

///
/// NOTIFICATIONS
///

const createNotification = useCreateNotification();
onMounted(() => {
    if (callInfo.value.source !== 'outbound') {
        createNotification(`Call connected: ${callInfo.value.name || callInfo.value.remoteNumber}`);
    }
});
watch(callStatus, () => {
    if (callStatus.value === 'hungup' || callStatus.value === 'failed') {
        createNotification(`Call ended: ${callInfo.value.name || callInfo.value.remoteNumber}`);
    }
});

///
/// HELPERS
///

async function withLocalWait<T>(task: () => Promise<T>): Promise<T | undefined> {
    isWorking.value = true;
    try {
        return await task();
    } catch (err) {
        handleErrorAndAlert(err);
    } finally {
        isWorking.value = false;
    }
}
</script>

<style lang="scss" scoped>
#phone-call {
    @apply flex-1 p-4 flex flex-col justify-center gap-2 relative;
}

.rescheduled,
.connecting,
.connected {
    @apply flex-1 flex flex-col items-center justify-center text-2xl whitespace-pre-line text-center;
}

.rescheduled-for {
    @apply text-gray-400 mt-4;
}

.rescheduled-note {
    @apply mt-4 text-gray-600 whitespace-pre-line;
}

.duration {
    @apply text-xl mb-8;
}

.number {
    @apply text-xl;
}

.name {
    @apply text-2xl;
}

.file-number,
.source {
    @apply text-lg text-gray-400;
}

.transferred,
.failed {
    @apply mt-8 text-center text-xl;
}
.transferred {
    @apply text-green-600;
}
.failed {
    @apply text-red-800;
}

.transfer-info {
    @apply mt-6 text-gray-600;

    .transfer-note {
        @apply mt-4 p-2 bg-white rounded-md;
    }
}

.inbound {
    @apply text-blue-400 font-semibold;
}

.actions {
    @apply flex flex-col gap-1;
}

button.active {
    @apply bg-red-700 hover:bg-red-600 disabled:opacity-50;
}

.cluster {
    @apply mb-4 grid grid-cols-2 gap-1;
}

.loader {
    @apply absolute top-0 left-0 w-full h-full flex items-center justify-center;
    background: rgba(0, 0, 0, 0.2);
}
</style>
