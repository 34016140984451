/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from 'lodash';

export function setupLogger(user: string) {
    interface ILoggerWindow {
        $scribeLoggerInstalled?: boolean;
    }
    const loggerWindow = window as ILoggerWindow;
    if (loggerWindow.$scribeLoggerInstalled) return;
    loggerWindow.$scribeLoggerInstalled = true;

    const LogLevelMap = {
        debug: 0,
        info: 1,
        warn: 2,
        error: 3
    } as const;

    interface LogEntry {
        aid: number;
        ts: number;
        level: number;
        scope?: string;
        msg: string;
        data?: Record<string, any>;
    }

    const LogState = {
        queue: [] as LogEntry[],
        isPushing: false
    };

    const realConsoleDebug = console.debug;
    const realConsoleLog = console.log;
    const realConsoleWarn = console.warn;
    const realConsoleError = console.error;

    const pushLogQueue = async () => {
        if (LogState.isPushing) {
            return;
        }

        while (LogState.queue.length > 1000) {
            LogState.queue.shift();
        }

        LogState.isPushing = true;

        // allow a little time for more logs to pour in
        await new Promise(resolve => setTimeout(resolve, 1_000));

        const entries = LogState.queue.slice(0, 100);
        const entriesOut = entries.map(entry => ({
            a: entry.aid,
            t: entry.ts,
            l: entry.level,
            s: entry.scope,
            m: entry.msg,
            x: entry.data
        }));

        try {
            if (import.meta.env.VITE_APP_SCRIBE_URL) {
                const response = await fetch(import.meta.env.VITE_APP_SCRIBE_URL, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        i: 'com.c2cfsi.agentapp',
                        v: import.meta.env.VITE_APP_VERSION,
                        d: user,
                        l: Date.now(),
                        e: entriesOut
                    })
                });

                if (response.status !== 201) {
                    throw new Error(`Unexpected HTTP response code ${response.status}`);
                }
            }

            LogState.queue.splice(0, entries.length);
        } catch (err) {
            realConsoleError('[Scribe] Failed to push logs', err);
        }

        LogState.isPushing = false;
        if (LogState.queue.length) setTimeout(pushLogQueue, 1_000);
    };

    const pushLogQueueDebounced = debounce(pushLogQueue, 5_000, { trailing: true });
    window.addEventListener('beforeunload', pushLogQueueDebounced);

    const setupConsoleLog = (originalFn: typeof console.log, level: keyof typeof LogLevelMap) => {
        return (...args: any[]) => {
            const argsCopy = [...args];
            const scope =
                typeof argsCopy[0] === 'string' && argsCopy[0].startsWith('[') && argsCopy[0].endsWith(']')
                    ? String(argsCopy.shift()).slice(1, -1)
                    : undefined;
            const msg = typeof argsCopy[0] === 'string' ? argsCopy.shift() : '';

            LogState.queue.push({
                aid: 0,
                ts: Date.now(),
                level: LogLevelMap[level],
                msg,
                scope,
                data: argsCopy.length === 1 ? argsCopy[0] : argsCopy.length ? argsCopy : undefined
            });

            pushLogQueueDebounced();

            originalFn.apply(console, args);
        };
    };

    console.debug = setupConsoleLog(realConsoleDebug, 'debug');
    console.log = setupConsoleLog(realConsoleLog, 'info');
    console.warn = setupConsoleLog(realConsoleWarn, 'warn');
    console.error = setupConsoleLog(realConsoleError, 'error');
}

export const logger = {
    debug: (...args: any[]) => console.debug(...args),
    info: (...args: any[]) => console.log(...args),
    warn: (...args: any[]) => console.warn(...args),
    error: (...args: any[]) => console.error(...args)
};
