<template>
    <VfModal id="m-transfer-call" ref="modal" @form-submit="transfer">
        <template #header> Transfer Call </template>

        <VfSmartSelect v-model="selectedDestination" :options="destinations" :formatter="o => o.label" />

        <div v-if="selectedDestination.type === 'agent'" class="message">
            <label>Message:</label>
            <textarea v-model="message" placeholder="optional" />
        </div>

        <template #footer>
            <button type="button" @click="callback()">Cancel</button>
            <button v-disabled="!selectedDestination.isOnline" type="submit" class="primary">Save</button>
        </template>
    </VfModal>
</template>

<script lang="ts" setup>
import { handleErrorAndAlert, VfModal, VfSmartSelect } from '@signal24/vue-foundation';
import { computed, ref } from 'vue';

import type { c2c } from '@/generated/proto/c2cagent';
import { MetaCache } from '@/shared/services/meta';
import { useStore } from '@/store';

const store = useStore();

const modal = ref<InstanceType<typeof VfModal>>();
const props = defineProps<{
    callId: number;
    onlineAgentIds: number[];
    performTransfer: (request: c2c.agentServer.CTransferCallRequest) => Promise<c2c.agentServer.CTransferCallResponse>;
    callback: () => void;
}>();

const agents = MetaCache.getRef('agents');

interface IDestination {
    type: 'agent' | 'extension';
    target: string | number;
    label: string;
    isOnline: boolean;
}
const defaultDestinations: IDestination[] = [
    { type: 'extension', target: import.meta.env.VITE_APP_SIP_CS_EXTENSION, label: 'Client Services', isOnline: true }
];
const destinations = computed(() => {
    return [
        ...defaultDestinations,
        ...(agents.value
            ?.filter(a => a.id !== store.user?.id)
            .map(a => {
                const isOnline = props.onlineAgentIds.includes(a.id);
                return {
                    type: 'agent',
                    target: a.id,
                    label: isOnline ? a.name : `${a.name} (Offline)`,
                    isOnline
                };
            })
            .sort((a, b) => {
                const onlineCmp = a.isOnline === b.isOnline ? 0 : a.isOnline ? -1 : 1;
                return onlineCmp || a.label.localeCompare(b.label);
            }) ?? [])
    ];
});

const selectedDestination = ref<IDestination>(defaultDestinations[0]);
const message = ref('');

async function transfer() {
    modal.value!.mask();

    try {
        await props.performTransfer(
            selectedDestination.value.type === 'agent'
                ? {
                      callId: props.callId,
                      agentId: selectedDestination.value.target as number,
                      message: message.value.trim() || undefined
                  }
                : {
                      callId: props.callId,
                      extension: selectedDestination.value.target as string
                  }
        );
        props.callback();
    } catch (err) {
        handleErrorAndAlert(err);
        modal.value!.unmask();
    }
}
</script>

<style lang="scss">
#m-transfer-call {
    .message {
        @apply mt-4 flex flex-col gap-2;

        textarea {
            @apply resize-none;
        }
    }
}
</style>
