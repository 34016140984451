/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IActionCode } from '../models/IActionCode';
import type { IAgentInfo } from '../models/IAgentInfo';
import type { IStatusCode } from '../models/IStatusCode';
import type { ITrustCode } from '../models/ITrustCode';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MetaService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IActionCode
     * @throws ApiError
     */
    public getMetaGetActionCodes(): CancelablePromise<Array<IActionCode>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meta/action-codes',
        });
    }

    /**
     * @returns IStatusCode
     * @throws ApiError
     */
    public getMetaGetStatuses(): CancelablePromise<Array<IStatusCode>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meta/statuses',
        });
    }

    /**
     * @returns ITrustCode
     * @throws ApiError
     */
    public getMetaGetTrustCodes(): CancelablePromise<Array<ITrustCode>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meta/trust-codes',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getMetaGetCryptoKey(): CancelablePromise<{
        key: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meta/crypto-key',
        });
    }

    /**
     * @returns IAgentInfo
     * @throws ApiError
     */
    public getMetaGetAgents(): CancelablePromise<Array<IAgentInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meta/agents',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getMetaValidateRoutingNumber({
        routingNumber,
    }: {
        routingNumber: string,
    }): CancelablePromise<{
        valid: boolean;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meta/routing-numbers/validate',
            query: {
                'routingNumber': routingNumber,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getMetaGetClientInfo({
        clientNumber,
    }: {
        clientNumber: string,
    }): CancelablePromise<{
        info: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/meta/client-info/{clientNumber}',
            path: {
                'clientNumber': clientNumber,
            },
        });
    }

}
