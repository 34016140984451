<template>
    <div ref="parent" :class="['switcher button-group', { dark }]">
        <button
            v-for="(opt, idx) in resolvedOptions"
            :key="idx"
            v-tooltip="opt.tip"
            type="button"
            :class="[{ active: modelValue == opt.value, dark }]"
            :disabled="disabled"
            @click="emit('update:modelValue', opt.value)"
        >
            <i v-if="opt.icon" :class="opt.icon" />

            <template v-else-if="opt.label">
                {{ opt.label }}
            </template>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { filters } from '@/vf.setup';

interface IOptionConfig {
    label?: string;
    icon?: string;
    tip?: string;
    hidden?: boolean;
}

interface IResolvedOptionConfig extends IOptionConfig {
    value: string;
}

const props = defineProps<{
    modelValue: string | number;
    disabled?: boolean;
    options: string[] | Record<string | number, string | IOptionConfig> | IResolvedOptionConfig[];
    icons?: string[];
    dark?: boolean;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', val: string): void;
}>();

const resolvedOptions = computed<IResolvedOptionConfig[]>(() => {
    const options = Array.isArray(props.options)
        ? props.options.map((o, idx) => (typeof o === 'string' ? { value: o, label: filters.upperFirst(o), icon: props.icons?.[idx] } : o))
        : Object.entries(props.options).map(([value, config]) => ({ value, ...(typeof config === 'string' ? { label: config } : config) }));
    return options.filter(o => !o.hidden);
});
</script>

<style lang="scss" scoped>
.switcher {
    @apply flex flex-shrink-0 overflow-hidden;

    > button {
        @apply flex items-center justify-center border border-gray-400 bg-gray-400 py-1.5 px-3;

        &:not(:first-child) {
            @apply rounded-l-none;
        }

        &:not(:last-child) {
            @apply rounded-r-none;
        }

        &.active {
            @apply bg-gray-800 text-white border-gray-800;
        }
    }
}
</style>
