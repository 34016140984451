<template>
    <div id="debtor-addresses">
        <div v-for="(address, idx) in addresses" :key="idx" class="address" :class="{ empty: address === null }" @click="editAddress(address)">
            <template v-if="address">
                <p>{{ address.name }}</p>
                <p>{{ address.street1 }}</p>
                <p v-if="address.street2">{{ address.street2 }}</p>
                <p>{{ address.city }}, {{ address.state }} {{ address.zip }}</p>
                <p v-if="address.phone1 && address.phone1 !== '0000000000'">
                    <label>Ph1:</label>
                    <a href="#" @click.prevent.stop="dial(address.phone1)"
                        >{{ $filters.phone(address.phone1) }} {{ address.phone1Ext ? `ext ${address.phone1Ext}` : '' }}</a
                    >
                </p>
                <p v-if="address.phone2 && address.phone2 !== '0000000000'">
                    <label>Ph2:</label>
                    <a href="#" @click.prevent.stop="dial(address.phone2)"
                        >{{ $filters.phone(address.phone2) }} {{ address.phone2Ext ? `ext ${address.phone2Ext}` : '' }}</a
                    >
                </p>
                <p v-if="address.email"><label>Email:</label> {{ address.email }}</p>
                <p v-if="address.note"><label>Note:</label> {{ address.note }}</p>
            </template>
            <i v-else class="fa fa-plus" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { presentOverlay } from '@signal24/vue-foundation';
import { computed } from 'vue';

import { type IDebtorAddress, type IDebtorDetailExtended, type IDebtorInfo } from '@/generated/openapi-client';
import { apiClient } from '@/openapi-client';
import { withWait } from '@/shared/helpers/request.helpers';
import { usePlaceCall } from '@/shared/services/sip.setup';
import { useStore } from '@/store';

import MEditAddress from './addresses/m-edit-address.vue';

const store = useStore();
const props = defineProps<{
    debtor: IDebtorInfo;
    detail: IDebtorDetailExtended;
}>();

const placeCall = usePlaceCall();

const addresses = computed<(IDebtorAddress | null)[]>(() => {
    const result: (IDebtorAddress | null)[] = [];
    for (let i = 0; i <= props.detail.addresses.length; i++) {
        result.push(props.detail.addresses[i] ?? null);
    }
    return result;
});

async function editAddress(address: IDebtorAddress | null) {
    const result = await presentOverlay(MEditAddress, {
        debtor: props.debtor,
        address
    });
    if (!result) return;
    withWait('Reloading file...', async () => {
        const updatedFile = await apiClient.debtors.getDebtorsGetDebtor({
            fileNumber: props.debtor.fileNumber,
            existingAccessLogId: props.detail.accessLogId
        });
        Object.assign(props.detail, updatedFile);
    });
}

function dial(number: string) {
    if (/^0+$/.test(number)) return;
    placeCall({
        remoteNumber: number,
        fileNumber: props.debtor.fileNumber,
        remoteName: props.debtor.debtorName,
        isRescheduled: !!store.activeCall?.rpc?.rescheduledTs && props.debtor.fileNumber === store.activeCall.rpc.fileNumber
    });
}
</script>

<style lang="scss" scoped>
#debtor-addresses {
    @apply p-4 grid grid-cols-2 gap-4;
}

.address {
    @apply border rounded-md p-4 bg-white cursor-pointer hover:bg-gray-50;
}

.empty {
    @apply flex items-center justify-center;

    i {
        @apply text-2xl text-gray-400;
    }
}

label {
    @apply text-gray-400;
}

a {
    @apply ml-1 text-blue-700 hover:text-blue-400;
}
</style>
