import { apiClient } from '@/openapi-client';

export class BrowserCrypto {
    private static publicKey?: CryptoKey;

    static async encrypt(data: string): Promise<string> {
        const key = await this.getPublicKey();
        const encrypted = await window.crypto.subtle.encrypt(
            {
                name: 'RSA-OAEP'
            },
            key,
            new TextEncoder().encode(data)
        );
        return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
    }

    private static async getPublicKey(): Promise<CryptoKey> {
        if (!this.publicKey) {
            const { key } = await apiClient.meta.getMetaGetCryptoKey();
            const keyArrBuf = pemToArrayBuffer(key);
            this.publicKey = await window.crypto.subtle.importKey(
                'spki',
                keyArrBuf,
                {
                    name: 'RSA-OAEP',
                    hash: 'SHA-256'
                },
                true,
                ['encrypt']
            );
        }
        return this.publicKey;
    }
}

function pemToArrayBuffer(pem: string) {
    const b64 = pem.replace(/-----\w+ PUBLIC KEY-----/g, '').replace(/\s+/g, '');
    const binaryDerString = window.atob(b64);
    const len = binaryDerString.length;
    const binaryArray = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        binaryArray[i] = binaryDerString.charCodeAt(i);
    }

    return binaryArray.buffer;
}
